import React from "react";
import {formatDate} from "../utils";
import Modal from "./Modal";

import { useTranslation } from 'react-i18next';

export default function PropertyChangesModal({activity, onClose}) {
    const { t } = useTranslation('changes');
    return (
        <Modal title={`Änderungen von ${activity.userName} am ${formatDate(activity.createTime)}`}
               closeLabel="Schliessen"
               onClose={onClose}
               data={activity}
               classNames="modal-lg">
            <table className="table table-responsive">
                <thead>
                <tr>
                    <th>Feld</th>
                    <th>Alter Wert</th>
                    <th>Neuer Wert</th>
                </tr>
                </thead>
                <tbody>
                { activity.changes.map(change =>
                    <tr key={`${activity.id}-${change.name}`}>
                        <th>{t(`${activity.type}.${change.name}`)}</th>
                        <td>{change.oldValue}</td>
                        <td>{change.newValue}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </Modal>
    );
}