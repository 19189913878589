import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {formatDate} from "../../common/utils";
import {groupBy} from "underscore";
import ThumbnailGallery from "../../common/components/ThumbnailGallery";
import {fetchImageCollections, fetchImageDescriptors, updatePropertyFields} from "../actions";
import withUser from "../../user/components/withUser";

class PropertyImageContainer extends Component {

    static propTypes = {
        property: PropTypes.object.isRequired
    };

    state = {
        collections: [],
        descriptors: []
    };

    componentDidMount() {
        this.fetchCollections();
        this.fetchDescriptors();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.property !== this.props.property) {
            this.componentDidMount();
        }

        const newState = {};

        if (prevProps.collections !== this.props.collections) {
            newState['collections'] = this.props.collections;
        }

        if (prevProps.descriptors !== this.props.descriptors) {
            newState['descriptors'] = this.props.descriptors;
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    fetchCollections = () => {
        this.props.fetchImageCollections(this.props.property.id);
    };

    fetchDescriptors = () => {
        this.props.fetchImageDescriptors(this.props.property.id);
    };

    onUpdateCoverCollectionId = (event, propertyId, collectionId) => {
        event.preventDefault();
        this.props.updatePropertyFields(propertyId, { imageCollectionId: collectionId });
    };

    render() {

        const { property, permissions } = this.props;

        const { collections, descriptors } = this.state;

        const descriptorsByCollectionId = groupBy(descriptors, 'collectionId');

        const coverCollectionId = property.fields.imageCollectionId;

        return (
            <div className="panel panel-default">
                <div className="panel-body">
                    {descriptors.length > 0 && collections.map((col, idx) => {
                        const descriptors = descriptorsByCollectionId[col.id];
                        const size = descriptors ? descriptors.length : 0;
                        return (
                            <div key={`image-collection-${col.id}`} className="row">
                                <div className="col-xs-12">
                                    {idx > 0 &&
                                    <hr style={{margin: "10px 0"}}/>
                                    }
                                    <div className="pull-left" style={{margin: "2px"}}>
                                        <strong>{col.id === coverCollectionId ? 'Cover-Album' : 'Album'}:</strong>&nbsp;<a
                                        href={`/images/${col.id}`}>{col.name}</a>
                                    </div>
                                    {descriptors &&
                                    <ThumbnailGallery descriptors={descriptorsByCollectionId[col.id]}
                                                      editable={false}
                                                      downloadEnabled={false}
                                                      scalingFactor={0.5}/>
                                    }
                                    <div className="imgcol-footer">
                                        <small className="text-muted">
                                            {size} {size === 1 ? 'Bild' : 'Bilder'} -
                                            Erstellt am {formatDate(col.createTime)} von {col.createUser}
                                        </small>
                                        {permissions.editor && col.id !== coverCollectionId &&
                                        <small className="text-muted">
                                            - <a href=""
                                                 onClick={e => this.onUpdateCoverCollectionId(e, property.id, col.id)}>Als
                                            Cover verwenden</a>
                                        </small>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    collections: state.propertyReducer.collections,
    descriptors: state.propertyReducer.imageDescriptors
});

const mapDispatchToProps = {
    fetchImageCollections: fetchImageCollections,
    fetchImageDescriptors: fetchImageDescriptors,
    updatePropertyFields: updatePropertyFields,
};

export default withUser(connect(mapStateToProps, mapDispatchToProps)(PropertyImageContainer));