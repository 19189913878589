import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router";
import withUser from "../../user/components/withUser";
import {fetchPropertyGroups} from "../actions";

const ContextMenu = () => (
    <div className="context-menu hidden-print">
        <ol className="breadcrumb display-inline">
            <li>Objektgruppen</li>
        </ol>
    </div>
);

class PropertyGroupListContainer extends Component {

    state = {
        propertyGroups: null
    };

    componentDidMount() {
        this.props.fetchPropertyGroups();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.propertyGroups !== this.props.propertyGroups) {
            this.setState({propertyGroups: this.props.propertyGroups})
        }
    }

    onGroupSelected = (group) => {
        this.props.history.push(`/property-groups/${group.id}`)
    };

    renderPropertyGroup = (group) => {
        return (
            <li key={`group-${group.id}`} className="property-group" onClick={e => this.onGroupSelected(group)}>
                <div className="property-group-label">
                    <h1>{group.name}</h1>
                    <span className="text-muted">{group.size} Objekte</span>
                </div>
            </li>
        )
    };

    render() {

        const { propertyGroups } = this.state;

        return (
            <Fragment>

                <ContextMenu/>

                <div className="row">
                    <div className="col-md-12">
                        <ul className="property-group-list">
                            {propertyGroups && propertyGroups.map(this.renderPropertyGroup)}
                        </ul>
                    </div>
                </div>

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    propertyGroups: state.propertyGroupReducer.propertyGroups,
});

const mapDispatchToProps = {
    fetchPropertyGroups: fetchPropertyGroups,
};


export default withRouter(withUser(connect(mapStateToProps, mapDispatchToProps)(PropertyGroupListContainer)));

