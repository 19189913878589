export const SORT_ORDER_UNDEFINED = 0;
export const SORT_ORDER_AZ = 1;
export const SORT_ORDER_ZA = 2;

function calculateCutOff(totalWidth, delta, descriptors) {

    let cutoff = [];
    let cutSum = 0;

    // distribute the delta based on the proportion of
    // thumbnail size to totalWidth of all thumbnails.
    for (let i in descriptors) {
        let descriptor = descriptors[i];
        let fractOfLen = descriptor.width / totalWidth;
        cutoff[i] = Math.floor(fractOfLen * delta);
        cutSum += cutoff[i];
    }

    // still more pixel to distribute because of decimal
    // fractions that were omitted.
    let stillToCutOff = delta - cutSum;
    while (stillToCutOff > 0) {
        for (let i in cutoff) {
            // distribute pixels evenly until done
            cutoff[i]++;
            stillToCutOff--;
            if (stillToCutOff === 0) {
                break;
            }
        }
    }
    return cutoff;
}

function imageRow(maxWidth, descriptors, scalingFactor, rowNum) {

    let row = [], totalWidth = 0;

    // each image a has a 2px margin, i.e. it takes 4px additional space
    const margins = 4;

    let descriptor;

    // Build a row of images until longer than maxWidth
    while (descriptors.length > 0 && totalWidth < maxWidth) {
        descriptor = descriptors.shift();
        descriptor.horizontalOffset = 0;
        descriptor.width = descriptor.thumbnailWidth * scalingFactor;
        descriptor.height = descriptor.thumbnailHeight * scalingFactor;
        descriptor.twidth = descriptor.width;
        descriptor.theight = descriptor.height;
        descriptor.rowNum = rowNum;
        row.push(descriptor);
        totalWidth += (descriptor.width + margins);
    }

    // calculate by how many pixels too long?
    const delta = totalWidth - maxWidth;

    // if the line is too long, make images smaller
    if (row.length > 0 && delta > 0) {
        // calculate the distribution to each image in the row
        let cutoff = calculateCutOff(totalWidth, delta, row);
        for (let i in row) {
            let pixelsToRemove = cutoff[i];
            descriptor = row[i];
            // move the left border inwards by half the pixels
            descriptor.horizontalOffset = Math.floor(pixelsToRemove / 2);
            // shrink the width of the image by pixelsToRemove
            descriptor.width -= pixelsToRemove;
        }
    }

    return row;
}

export function imageRows(containerWidth, descriptors, scalingFactor) {
    const items = descriptors.slice();
    const rows = [];
    let rowNum = 0;
    while (items.length > 0) {
        const row = imageRow(containerWidth, items, scalingFactor, rowNum++);
        rows.push(row);

    }
    // console.log(rows);
    return rows; //[].concat.apply([], rows);
}

export function sortAndFilterImageCollections(collections, sortOrder, filterText) {
    return sortImageCollections(filterImageCollections(collections, filterText), sortOrder);
}

export function filterImageCollections(collections, filterText) {
    const filter = col => {
        return (col.name && col.name.includes(filterText))
            || (col.description && col.description.includes(filterText))
    };
    return collections.filter(filter);
}

export function sortImageCollections (collections, sortOrder) {
    if (sortOrder === SORT_ORDER_AZ) {
        return collections.sort((c1, c2) => c1.name.localeCompare(c2.name));
    } else if (sortOrder === SORT_ORDER_ZA) {
        return collections.sort((c1, c2) => c2.name.localeCompare(c1.name));
    } else {
        return collections;
    }
}
export function propertyName(property) {
    if (!property) {
        return 'null';
    }
    return `#${property.id} - ${property.fields.city} - ${property.fields.streetAndNumber}`;
}