import {REPORT_RECEIVED, REPORTING,} from "./actions";

const initialState = {
    rows: [],
};

function reportingReducer(state = initialState, action) {

    if (action.scope !== REPORTING) {
        return state;
    }

    switch (action.type) {

        case REPORT_RECEIVED:
            return Object.assign({}, state, {
                rows: action.rows
            });

        default:
            return state;
    }
}

export default reportingReducer;