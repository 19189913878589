import React, {Component, Fragment} from "react";
import {formatDate} from "../../common/utils";
import PropTypes from "prop-types";
import RentalAgreementEditModal from "./RentalAgreementEditModal";
import Modal from "../../common/components/Modal";
import withUser from "../../user/components/withUser";

class RentalAgreementPanel extends Component {

    static propTypes = {
        propertyId: PropTypes.number.isRequired,
        rentalAgreements: PropTypes.array.isRequired,
        onCreateRentalAgreement: PropTypes.func.isRequired,
        onUpdateRentalAgreement: PropTypes.func.isRequired,
        onDeleteRentalAgreement: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            rentalAgreements: props.rentalAgreements,
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: false,
            selectedRentalAgreement: null
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rentalAgreements !== this.props.rentalAgreements) {
            this.setState({ rentalAgreements: this.props.rentalAgreements });
        }
    }

    onShowCreateModal = (event) => {
        event.preventDefault();
        this.setState({
            showCreateModal: true,
            selectedRentalAgreement: { propertyId: this.props.propertyId }
        })
    };

    onShowEditModal = (event, rentalAgreement) => {
        event.preventDefault();
        this.setState({
            showEditModal: true,
            selectedRentalAgreement: rentalAgreement
        })
    };

    onShowDeleteModal = (event, rentalAgreement) => {
        event.preventDefault();
        this.setState({
            showDeleteModal: true,
            selectedRentalAgreement: rentalAgreement
        })
    };

    onCloseModals = () => {
        this.setState({
            showCreateModal: false,
            showEditModal: false,
            showDeleteModal: false,
            selectedRentalAgreement: null
        });
    };

    renderRow = (rentalAgreement) => {
        const { permissions } = this.props;
        return (
            <Fragment key={`ra-${rentalAgreement.id}`}>
                <tr>
                    <td className="text-left" style={{paddingBottom: "0px"}}>
                        {rentalAgreement.id}
                    </td>
                    <td className="text-left" style={{paddingBottom: "0px"}}>
                        {formatDate(rentalAgreement.startDate, 'DD.MM.YY')} - {rentalAgreement.endDate ? formatDate(rentalAgreement.endDate, 'DD.MM.YY') : ''}
                    </td>
                    <td className="text-right" style={{paddingBottom: "0px"}}>
                        {rentalAgreement.rent}
                    </td>
                    <td className="text-right" style={{paddingBottom: "0px"}}>
                        {rentalAgreement.utilities}
                    </td>
                    <td className="text-right" style={{paddingBottom: "0px"}}>
                        {rentalAgreement.rentDeposit}
                    </td>
                    {permissions.editor &&
                    <td className="text-center" style={{paddingBottom: "1px"}} width="15%">
                        <a href="" onClick={e => this.onShowEditModal(e, rentalAgreement)}>
                            <i className="glyphicon glyphicon-edit mr-5"/>
                        </a>
                        <a href="" onClick={e => this.onShowDeleteModal(e, rentalAgreement)}>
                            <i className="glyphicon glyphicon-trash ml-5"/>
                        </a>
                    </td>
                    }
                </tr>
                <tr>
                    <td className="text-left" style={{border: "0", paddingTop: "0px"}} colSpan="4">
                        <span className="text-muted small">{rentalAgreement.counterParty}</span>
                    </td>
                    <td style={{border: "0", paddingTop: "0px"}}/>
                </tr>
            </Fragment>
        );
    };

    render() {

        const { permissions } = this.props;

        const {
            rentalAgreements,
            showCreateModal,
            showEditModal,
            showDeleteModal,
            selectedRentalAgreement
        } = this.state;

        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <span className="glyphicon glyphicon-briefcase"/> Mietverträge
                    <div className="pull-right">
                        {permissions.editor &&
                        <a href="" onClick={this.onShowCreateModal}>
                            <i className="glyphicon glyphicon-plus"/> Mietvertrag hinzufügen
                        </a>
                        }
                    </div>
                </div>
                <div className="panel-body">
                    <table className="table table-condensed" style={{marginBottom: "5px"}}>

                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Laufzeit</th>
                            <th className="text-right">Miete</th>
                            <th className="text-right">NK</th>
                            <th className="text-right">Kaution</th>
                            <th/>
                        </tr>
                        </thead>

                        <tbody>

                        {rentalAgreements.map(this.renderRow)}

                        </tbody>

                    </table>
                </div>

                {showCreateModal && selectedRentalAgreement &&
                    <RentalAgreementEditModal rentalAgreement={selectedRentalAgreement}
                                              onSubmit={this.props.onCreateRentalAgreement}
                                              onClose={this.onCloseModals}/>
                }

                {showEditModal && selectedRentalAgreement &&
                    <RentalAgreementEditModal rentalAgreement={selectedRentalAgreement}
                                              onSubmit={this.props.onUpdateRentalAgreement}
                                              onClose={this.onCloseModals}/>
                }

                {showDeleteModal && selectedRentalAgreement &&
                    <Modal title="Mietvertrag löschen"
                           closeLabel="Abbrechen"
                           onClose={this.onCloseModals}
                           submitLabel="Löschen"
                           onSubmit={this.props.onDeleteRentalAgreement}
                           data={selectedRentalAgreement}
                           classNames="modal-md">
                        Mietvertrag #{selectedRentalAgreement.id} wirklich löschen?
                    </Modal>
                }
            </div>
        );
    }
}

export default withUser(RentalAgreementPanel)