import * as API from "../common/api";
import {action} from "../common/utils";

export const PROPERTY_GROUP = "PROPERTY_GROUP";

export const PROPERTY_GROUPS_RECEIVED = 'PROPERTY_GROUPS_RECEIVED';
export const PROPERTY_GROUP_UPDATED = 'PROPERTY_GROUP_UPDATED';

export const PROPERTIES_RECEIVED = 'PROPERTIES_RECEIVED';

export function fetchPropertyGroups() {
    return function(dispatch) {
        API.fetchPropertyGroups()
            .then(response => dispatch(action({ scope: PROPERTY_GROUP, type: PROPERTY_GROUPS_RECEIVED, groups: response.data })));
    }
}

export function createPropertyGroup(group) {
    return function(dispatch) {
        API.createPropertyGroup(group)
            .then(() => {
                dispatch(action({ scope: PROPERTY_GROUP, type: PROPERTY_GROUP_UPDATED }));
            })
            .catch(console.log);
    }
}

export function updatePropertyGroup(group) {
    return function(dispatch) {
        API.updatePropertyGroup(group)
            .then(() => {
                dispatch(action({ scope: PROPERTY_GROUP, type: PROPERTY_GROUP_UPDATED }));
            })
            .catch(console.log);
    }
}

export function deletePropertyGroup(group) {
    return function(dispatch) {
        API.deletePropertyGroup(group.id)
            .then(() => {
                dispatch(action({ scope: PROPERTY_GROUP, type: PROPERTY_GROUP_UPDATED }));
            })
            .catch(console.log);
    }
}

export function fetchProperties(groupId) {
    return function(dispatch) {
        API.fetchProperties(groupId)
            .then(response => dispatch(action({ scope: PROPERTY_GROUP, type: PROPERTIES_RECEIVED, properties: response.data })));
    }
}

export function exportProperties(accessToken, groupId) {
    return function(dispatch) {
        API.exportProperties(accessToken, groupId)
            .then(response => window.open(response.data, '_blank'))
    }
}

export function createProperty(property, onSuccess) {
    return function(dispatch) {
        API.createProperty(property)
            .then(response => onSuccess(response.data));
    }
}