import React, {Component} from "react";
import PropTypes from "prop-types";
import Modal from "../../common/components/Modal";
import {Menu, MenuItem, Typeahead} from 'react-bootstrap-typeahead';
import {propertyName} from "../../common/image";

export default class CollectionEditModal extends Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        collection: PropTypes.object.isRequired,
        propertiesById: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            collection:  Object.assign({}, props.collection),
            propertiesById: props.propertiesById,
            selectedProperties: [],
        };
    }

    updateState(field, update) {
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            newState.collection[field] = update;
            return newState;
        });
    };

    onSelectProperty = (properties) => {
        this.setState({ selectedProperties: properties });
    };

    onAddProperty = (event) => {
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            prevState.selectedProperties.forEach(prop => {
                newState.collection.propertyIds.push(prop.id);
            });
            newState.selectedProperties = [];
            return newState;
        });
    };

    onRemoveProperty = (event, id) => {
        event.preventDefault();
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            newState.collection.propertyIds = prevState.collection.propertyIds.filter(propertyId => propertyId !== id);
            return newState;
        });
    };


    propertyExists = (id) => {
        return this.state.propertiesById[id];
    };

    propertyName = (id) => {
        return propertyName(this.state.propertiesById[id]);
    };

    renderTypeaheadMenu = (results, menuProps) => {
        return (
            <Menu {...menuProps} className="typeahead-menu">
                {results.map((result, index) =>
                    <MenuItem option={result} position={index}>{result.label}</MenuItem>
                )}
            </Menu>
        );
    };

    render() {

        const { title, collection, propertiesById, selectedProperties } = this.state;

        const propertyNames = Object.keys(propertiesById).map(id => { return {id: id, label: propertyName(propertiesById[id])} });

        return (
            <Modal title={title}
                   closeLabel="Schliessen"
                   onClose={this.props.onClose}
                   submitLabel="Speichern"
                   onSubmit={this.props.onSubmit}
                   data={collection}
                   classNames="modal-lg">

                <form className="form">

                    <div className="form-group">
                        <label>Name</label>
                        <input type="text"
                               className="form-control"
                               value={collection.name || ''}
                               onChange={e => this.updateState('name', e.target.value)}/>
                    </div>

                    <div className="form-group">
                        <label>Objekte</label>
                        <div className="input-group">
                            <Typeahead
                                multiple={false}
                                selected={selectedProperties}
                                options={propertyNames}
                                placeholder="+ Objekt hinzufügen"
                                onChange={this.onSelectProperty}
                                renderMenu={this.renderTypeaheadMenu}/>
                            <span className="input-group-btn">
                                <button className="btn btn-default" type="button" onClick={this.onAddProperty}>+</button>
      						</span>
                        </div>

                        <div className="spacer spacer5"/>

                        <ul className="list-inline unstyled">
                            {collection.propertyIds.filter(this.propertyExists).map(id => (
                                <li>
								    <span className="tag label label-primary">
                                        {this.propertyName(id)}
                                        <a href="" style={{color: "white"}} onClick={e => this.onRemoveProperty(e, id)}>
                                            <span style={{fontSize: "15px", marginLeft: "8px"}}>&times;</span>
                                        </a>
								    </span>
                                </li>
                            ))}
                        </ul>

                    </div>

                    <div className="form-group">
                        <label htmlFor="description">Beschreibung</label>
                        <textarea className="form-control"
                                  rows="5"
                                  value={collection.description || ''}
                                  onChange={e => this.updateState('description', e.target.value)}/>
                    </div>

                </form>

            </Modal>
        );
    }
}
