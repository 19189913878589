import React, {Component} from "react";
import PropTypes from "prop-types";

export default class ImageFilter extends Component {

    static propTypes = {
        value: PropTypes.string.isRequired,
        onFilterChanged: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    onValueChanged = (event) => {
        this.updateValue(event.target.value);
    };

    clearValue = (event) => {
        this.updateValue('');
    };

    updateValue = (value) => {
        this.setState({ value: value });
        this.props.onFilterChanged(value)
    };

    render() {

        const { value } = this.state;

        return (

            <div className="row" style={{marginBottom: "20px", padding: "0 10px"}}>
                <div className="col-xs-4">
                    <input type="text"
                           className="form-control input-sm"
                           placeholder="Alben filtern"
                           value={value}
                           onChange={this.onValueChanged}/>
                    <span id="clearImgColFilter"
                          className="glyphicon glyphicon-remove-circle"
                          onClick={this.clearValue}/>
                </div>
            </div>
        );
    }
}
