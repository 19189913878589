import * as API from "../common/api";
import { action } from "../common/utils";

export const ACTIVITY = "ACTIVITY";

export const ACTIVITIES_RECEIVED = 'ACTIVITIES_RECEIVED';

export function fetchActivities(pageIndex, pageSize) {
    return function (dispatch) {
        API.fetchActivities(pageIndex, pageSize)
            .then(response => dispatch(action({scope: ACTIVITY, type: ACTIVITIES_RECEIVED, page: response.data})));
    }
}
