import React, {Component} from "react";
import {formatAmount} from "../../common/utils";
import PropTypes from "prop-types";

export default class AnnualsReport extends Component {

    static propTypes = {
        rows: PropTypes.array.isRequired,
    };

    renderRow = (row) => {
        return (
            <tr key={`ar-${row.year}`}>
                <td className="text-center">{row.year}</td>
                <td className="text-right">{row.totalUnits}</td>
                <td className="text-right">{formatAmount(row.totalArea, '0,0')}</td>
                <td className="text-right">{formatAmount(row.vacancyAtEndOfTerm * 100, '0,0')}</td>
                <td className="text-right">{formatAmount(row.vacancy * 100, '0,0')}</td>
                <td className="text-right">{formatAmount(row.revenue, '0,0')}</td>
                <td className="text-right">{formatAmount(row.annuallyAccruedInterest, '0,0')}</td>
                <td className="text-right">{formatAmount(row.loanedAmount, '0,0')}</td>
                <td className="text-right">{formatAmount(row.netRent, '0,0')}</td>
                <td className="text-right">{formatAmount(row.netOperatingCosts, '0,0')}</td>
                <td className="text-right">{formatAmount(row.purchasePrices, '0,0')}</td>
                <td className="text-right">{formatAmount(row.purchaseExtraCharges, '0,0')}</td>
                <td className="text-right">{formatAmount(row.investments, '0,0')}</td>
                <td className="text-right">{formatAmount(row.investmentsPerSqm, '0,0.00')}</td>

            </tr>
        );
    };

    render() {
        return (
            <table className="table table-condensed table-hover annuals-report">
                <thead>
                <tr>
                    <th className="text-center">Jahr</th>
                    <th className="text-right">Einheiten</th>
                    <th className="text-right">Fläche (m²)</th>
                    <th className="text-right">Leerstand (%)</th>
                    <th className="text-right">LKZ (%)</th>
                    <th className="text-right">Ergebnis</th>
                    <th className="text-right">Zinsen</th>
                    <th className="text-right">Darlehen</th>
                    <th className="text-right">Mieteinnahmen</th>
                    <th className="text-right">Betriebskosten</th>
                    <th className="text-right">Kaufpreise</th>
                    <th className="text-right">Kauf-NK</th>
                    <th className="text-right">Investitionen</th>
                    <th className="text-right">Investitionen/m²</th>
                </tr>
                </thead>
                <tbody>
                    {this.props.rows.map(this.renderRow)}
                </tbody>
            </table>
        );
    }
}
