import React, {Component} from "react";
import PropTypes from "prop-types";
import {formatDate} from "../../common/utils";
import withUser from "../../user/components/withUser";

const HeaderRow = ({label}) => (
    <div className="row">
        <div className="col-sm-12">
            <div className="property-group-header"><strong>{label}</strong></div>
        </div>
    </div>
);

const SelectRow = (props) => (
    <div className="row">
        <div className="col-lg-5"><span className="text-muted">{props.label}</span></div>
        <div className="col-lg-7">
            <select className={props.propertyDirty ? 'inline-input inline-input-dirty' : 'inline-input'}
                    style={{marginLeft: "0px"}}
                    disabled={!props.editable}
                    value={props.value}
                    onChange={props.onChange}>
                {props.children}
            </select>
        </div>
    </div>
);

const InputRow = ({label, value, type, dirty, editable, onChange}) => (
    <div className="row">
        <div className="col-lg-5">
            <span className="text-muted"><nobr>{label}</nobr></span>
        </div>
        <div className="col-lg-7">
            <input type={type}
                   style={type === 'date' ? {marginLeft: "4px"} : {marginLeft: "5px"}}
                   spellCheck="false"
                   disabled={!editable}
                   className={dirty ? 'inline-input inline-input-dirty' : 'inline-input'}
                   value={value}
                   onChange={onChange}/>
        </div>
    </div>
);

const CheckboxRow = ({label, value, dirty, editable, onChange}) => (
    <div className="row">
        <div className="col-lg-5">
            <span className="text-muted"><nobr>{label}</nobr></span>
        </div>
        <div className="col-lg-7">
            <input type="checkbox"
                   style={{marginLeft: "6px"}}
                   spellCheck="false"
                   disabled={!editable}
                   className={dirty ? 'inline-input inline-input-dirty' : 'inline-input'}
                   checked={value}
                   onChange={onChange}/>
        </div>
    </div>
);

const TextAreaRow = ({label, value, dirty, editable, onChange}) => (
    <div className="row">
        <div className="col-sm-12">
            <div className="spacer5"/>
            <textarea className={dirty ? 'form-control inline-input-dirty' : 'form-control'}
                      disabled={!editable}
                      rows="7"
                      spellCheck="false"
                      placeholder={label}
                      value={value}
                      onChange={onChange}/>
        </div>
    </div>
);

const LikeRow = ({label, likes, dislikes, onUpdate}) => (
    <div className="row">
        <div className="col-lg-5"><span className="text-muted">{label}</span></div>
        <div className="col-lg-7">
            <div className="like">
                <i className="glyphicon glyphicon-thumbs-up like-thumb" onClick={e => onUpdate(true)}/><span className="like-counter">{likes}</span>
                <span style={{paddingLeft: "10px"}}/>
                <i className="glyphicon glyphicon-thumbs-down like-thumb" onClick={e => onUpdate(false)}/><span className="like-counter">{dislikes}</span>
            </div>
        </div>
    </div>
);

const TextRow = ({label, value}) => (
    <div className="row">
        <div className="col-lg-5"><span className="text-muted"><nobr>{label}</nobr></span></div>
        <div className="col-lg-7"><span className="inline-input">{value}</span></div>
    </div>
);

class FieldPanel extends Component {

    static propTypes = {
        property: PropTypes.object.isRequired,
        propertyGroups: PropTypes.array.isRequired,
        likes: PropTypes.array.isRequired,
        onUpdateField: PropTypes.func.isRequired,
        onUpdateLike: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            property: props.property,
            fields: Object.assign({}, props.property.fields),
            likes: props.likes
        };
    }

    componentDidUpdate(prevProps) {

        const newState = {};

        if (prevProps.property !== this.props.property) {
            newState['property'] = this.props.property;
            newState['fields'] = Object.assign({}, this.props.property.fields);
        }

        if (prevProps.likes !== this.props.likes) {
            newState['likes'] = this.props.likes;
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    updateField(field, update) {
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            newState.fields[field] = update;
            return newState;
        });
        this.props.onUpdateField(field, update);
    }

    onUpdateLike = (value) => {
        const permissions = this.props.permissions;
        if (permissions.voter) {
            this.props.onUpdateLike(value);
        }
    };

    renderIntInput(label, field) {
        return this.renderInput(label, field, "number", 0, e => this.updateField(field, parseInt(e.target.value)))
    }

    renderFloatInput(label, field) {
        return this.renderInput(label, field, "number", 0, e => this.updateField(field, parseFloat(e.target.value)))
    }

    renderDateInput(label, field) {
        return this.renderInput(label, field, "date", "", e => this.updateField(field, e.target.value))
    }

    renderStringInput(label, field) {
        return this.renderInput(label, field, "text", "", e => this.updateField(field, e.target.value))
    }

    renderInput(label, field, type, defaultValue, onChange) {
        const value = this.state.fields[field];
        const permissions = this.props.permissions;
        return (
            <InputRow label={label}
                      value={value || defaultValue}
                      type={type}
                      dirty={value !== this.props.property.fields[field]}
                      editable={permissions.editor}
                      onChange={onChange}/>
        );
    }

    renderBoolInput(label, field) {
        const value = this.state.fields[field];
        const permissions = this.props.permissions;
        return (
            <CheckboxRow label={label}
                         value={value}
                         dirty={value !== this.props.property.fields[field]}
                         editable={permissions.editor}
                         onChange={e => this.updateField(field, e.target.checked)}/>
        );
    }

    renderTextInput(label, field) {
        const value = this.state.fields[field];
        const permissions = this.props.permissions;
        return (
            <TextAreaRow label={label}
                         value={value || ''}
                         dirty={value !== this.props.property.fields[field]}
                         editable={permissions.editor}
                         onChange={e => this.updateField(field, e.target.value)}/>
        );
    }

    renderText(label, field) {
        return (
            <TextRow label={label} value={this.state.fields[field]}/>
        )
    }

    render() {

        const { permissions, propertyGroups } = this.props;

        const { property, fields, likes } = this.state;

        return (

            <div className="panel panel-default" style={{padding: "15px"}}>

                <h4 style={{margin: "10px 3px 0px 0px"}}>{fields.zipCode }&nbsp;{fields.city},&nbsp;{fields.streetAndNumber}</h4>

                <span>{fields.indoorLocation}</span>

                <div className="property-columns">

                    <HeaderRow label="Status"/>

                    <SelectRow label="Ordner:"
                               value={fields.groupId}
                               dirty={fields.groupId !== this.props.property.fields.groupId}
                               editable={permissions.editor}
                               onChange={e => this.updateField("groupId", parseInt(e.target.value))}>
                        {propertyGroups.map(group =>
                            <option key={`fppg-${group.id}`} value={group.id}>{group.name}</option>
                        )}
                    </SelectRow>

                    {this.renderBoolInput("Veröffentlicht:", "published")}

                    <TextRow label="Eingestellt am:" value={formatDate(property.createTime)}/>
                    <TextRow label="Eingestellt von:" value={property.createUserFullName}/>

                    <LikeRow label="Abstimmung:"
                             likes={likes.filter(like => like.value).length}
                             dislikes={likes.filter(like => !like.value).length}
                             onUpdate={this.onUpdateLike}/>

                    <HeaderRow label="Adresse"/>

                    {this.renderStringInput("Stadt:", "city")}
                    {this.renderStringInput("PlZ:", "zipCode")}
                    {this.renderStringInput("Straße/Nr.:", "streetAndNumber")}
                    {this.renderStringInput("Lage im Haus:", "indoorLocation")}
                    {this.renderStringInput("SE:", "seId")}

                    {/*<TextRow label="Längengrad" value={fields.longitude}/>*/}
                    {/*<TextRow label="Breitengrad" value={fields.latitude}/>*/}

                    <HeaderRow label="Kauf"/>

                    {this.renderFloatInput("Kaufpreis:", "offerPrice")}
                    {this.renderFloatInput("Kaufnebenkosten:", "offerExtraCharges")}
                    {this.renderBoolInput("Vermietet bei Kauf:", "offerWithTenant")}
                    {this.renderDateInput("Nutzen/Lasten seit:", "usageRightsSinceDate")}
                    {this.renderDateInput("Nutzen/Lasten bis:", "usageRightsUntilDate")}

                    <HeaderRow label="Vermietung (Indikativ)"/>

                    {this.renderFloatInput("Kaltmiete:", "indicativeBaseRent")}
                    {this.renderFloatInput("Nebenkosten:", "indicativeUtilities")}
                    {this.renderFloatInput("Kaution:", "indicativeRentDeposit")}

                    <HeaderRow label="Hausgeld"/>

                    {this.renderFloatInput("Hausgeld:", "commonCharge")}
                    {this.renderFloatInput("Instandhaltungsrücklage:", "maintenanceReserve")}

                    <HeaderRow label="Objekt"/>

                    <SelectRow label="Typ:"
                               value={fields.type}
                               dirty={fields.type !== this.props.property.fields.type}
                               editable={permissions.editor}
                               onChange={e => this.updateField("type", e.target.value)}>
                        <option value="APARTMENT">Wohnung</option>
                        <option value="FAMILY_HOME">Einfamilienhaus</option>
                        <option value="APARTMENT_BUILDING">Mehrfamilienhaus</option>
                    </SelectRow>

                    {this.renderIntInput("Etage:", "floor")}
                    {this.renderIntInput("Anzahl Zimmer:", "bedroomCount")}
                    {this.renderIntInput("Anzahl Balkone:", "balconyCount")}
                    {this.renderIntInput("Anzahl Garagen:", "garageCount")}
                    {this.renderStringInput("Ausstattung:", "equipment")}
                    {this.renderFloatInput("Wohnfläche (m²):", "areaSqm")}
                    {this.renderFloatInput("Grundstücksfläche (m²):", "landAreaSqm")}
                    {this.renderFloatInput("Energieverbrauch (kWh/m²a):", "energyConsumption")}
                    {this.renderIntInput("Baujahr:", "constructionYear")}
                    {this.renderIntInput("Sanierungsjahr:", "renovationYear")}
                    {this.renderTextInput("Objektbeschreibung", "unitDescription")}

                    <HeaderRow label="Lage"/>

                    {this.renderText("Entfernung Chemnitz (km):", "chemnitzDistanceKm")}
                    {this.renderText("Entfernung Dresden (km):", "dresdenDistanceKm")}
                    {this.renderText("Entfernung Leipzig (km):", "leipzigDistanceKm")}
                    {this.renderText("Entfernung Autobahn (km):", "autobahnDistanceKm")}

                    {this.renderTextInput("Lagebeschreibung", "locationDescription")}

                    <HeaderRow label="Score"/>

                    {this.renderText("Gesamt:", "totalScore")}
                    {this.renderText("Preis:", "priceScore")}
                    {this.renderText("C/DD/L:", "cityDistanceScore")}
                    {this.renderText("Autobahn:", "autobahnDistanceScore")}
                    {this.renderText("Vermietet:", "tenantScore")}
                    {this.renderText("Zustand:", "conditionScore")}
                    {this.renderText("Gemeinschaftsanteil:", "commonPropertyPercentageScore")}

                    <HeaderRow label="Weitere"/>

                    {this.renderFloatInput("MEA (%):", "commonPropertyPercentage")}
                    {this.renderFloatInput("Verkehrswert:", "marketValue")}
                    {this.renderDateInput("Verkehrswertsdatum:", "marketValueFixingDate")}
                    {this.renderStringInput("GBA:", "gbaId")}
                    {this.renderStringInput("Gebäudeversicherungs-Nr.:", "buildingInsuranceNumber")}
                    {this.renderStringInput("Kauf-URNr.:", "buyUrn")}
                    {this.renderStringInput("Nachgenehmigung-URNr.:", "retroactiveApprovalUrn")}
                    {this.renderStringInput("Grundschuldbrief-URNr.:", "landChargeCertificateUrn")}
                    {this.renderStringInput("Verkauf-URNr.:", "sellUrn")}

                    <HeaderRow label="Kontakt/Makler"/>

                    {this.renderStringInput("Name:", "contactName")}
                    {this.renderStringInput("Adresse:", "contactAddress")}
                    {this.renderStringInput("Telefon:", "contactPhone")}
                    {this.renderStringInput("E-Mail:", "contactMail")}
                    {this.renderStringInput("Web:", "contactWeb")}

                    <HeaderRow label="Verwalter"/>

                    {this.renderStringInput("Name:", "administratorName")}
                    {this.renderStringInput("Adresse:", "administratorAddress")}
                    {this.renderStringInput("Telefon:", "administratorPhone")}
                    {this.renderStringInput("E-Mail:", "administratorMail")}
                    {this.renderStringInput("Web:", "administratorWeb")}

                    <HeaderRow label="Verkäufer"/>

                    {this.renderStringInput("Name:", "sellerName")}
                    {this.renderStringInput("Adresse:", "sellerAddress")}
                    {this.renderStringInput("Telefon:", "sellerPhone")}
                    {this.renderStringInput("E-Mail:", "sellerMail")}

                </div>

            </div>
        );
    }
}

export default withUser(FieldPanel);


