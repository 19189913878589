import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {
    createTransaction,
    fetchAccounts,
    fetchFlows,
    fetchTransaction,
    fetchTransactionTemplate,
    revertTransaction,
    updateTransaction
} from "../actions";
import {default as _} from "underscore";
import {flowTypeLabel, roundAmount} from "../../common/utils";
import SelectBox from "../../common/components/SelectBox";
import EditTransactionTable from "../components/EditTransactionTable";
import NavLink from "../../common/components/NavLink";

class TransactionContainer extends Component {

    static propTypes = {
        transactionId: PropTypes.string.isRequired,
        parent: PropTypes.string.isRequired,
        edit: PropTypes.bool.isRequired,
    };

    templates = [
        { name: 'rent_and_utilities',    label: 'Miete/Nebenkosten', flowType: 'PROPERTY', subFlowType: 'RENTAL_AGREEMENT' },
        { name: 'rent',                  label: 'Miete',             flowType: 'PROPERTY', subFlowType: 'RENTAL_AGREEMENT' },
        { name: 'utilities',             label: 'Nebenkosten',       flowType: 'PROPERTY', subFlowType: 'RENTAL_AGREEMENT' },
        { name: 'common_charge',         label: 'Hausgeld',          flowType: 'PROPERTY', subFlowType: 'PROPERTY' },
        { name: 'administrator_charge',  label: 'Verwaltungskosten', flowType: 'PROPERTY', subFlowType: 'PROPERTY' },
        { name: 'insurance',             label: 'Versicherung',      flowType: 'PROPERTY', subFlowType: 'PROPERTY' },
        { name: 'property_tax',          label: 'Grundsteuer',       flowType: 'PROPERTY', subFlowType: 'PROPERTY' },
        { name: 'investment',            label: 'Investition',       flowType: 'PROPERTY', subFlowType: 'PROPERTY' },
        { name: 'purchase_price',        label: 'Kaufpreis',         flowType: 'PROPERTY', subFlowType: 'PROPERTY' },
        { name: 'purchase_extra_charge', label: 'Kaufnebenkosten',   flowType: 'PROPERTY', subFlowType: 'PROPERTY' },
        { name: 'loan',                  label: 'Darlehen',          flowType: 'LOAN',     subFlowType: 'LOAN' },
        { name: 'other',                 label: 'Andere',            flowType: 'NONE',     subFlowType: 'NONE' },
        { name: 'suspense',              label: 'Durchgang',         flowType: 'NONE',     subFlowType: 'NONE' },
    ];

    state = {

        accounts: [],
        flowsByType: {},
        bankTransactionId: 0,

        existingEntries: [],

        selectedTemplate: null,
        selectedFlow: null,
        selectedSubFlow: null,

        description: null,
        valueDate: new Date(),
        legs: [],

        edit: false
    };

    componentDidMount() {
        this.setState({edit: this.props.edit});
        this.props.fetchAccounts();
        this.props.fetchFlows();
        this.props.fetchTransaction(this.props.transactionId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.transactionTemplate !== this.props.transactionTemplate) {
            const template = this.props.transactionTemplate;
            this.setState({
                description: template.description,
                valueDate: new Date(template.valueDate),
                legs: template.legs
            });
        }
        if (prevProps.accounts !== this.props.accounts) {
            this.setState({accounts: this.props.accounts});
        }
        if (prevProps.flowsByType !== this.props.flowsByType) {
            this.setState({flowsByType: this.props.flowsByType});
        }
        if (prevProps.transactionEntries !== this.props.transactionEntries) {
            this.setState({existingEntries: this.props.transactionEntries});
            if (this.props.transactionEntries && this.props.transactionEntries.length > 0) {
                const entry = this.props.transactionEntries[0];
                this.setState({
                    description: entry.description,
                    valueDate: new Date(entry.valueDate),
                });
            }
        }
        if (prevProps.edit !== this.props.edit) {
            this.setState({edit: this.props.edit});
        }
    }

    onSelectTemplate = (template) => {
        if (template !== this.state.selectedTemplate) {
            this.setState({
                selectedTemplate: template,
                selectedFlow: null,
                selectedSubFlow: null,
            });
        }
    };

    onSaveTransaction = () => {
        const { history, transactionId, parent } = this.props;
        const { description, valueDate, legs } = this.state;
        const transaction = {
            description: description,
            valueDate: valueDate,
            legs: legs
        };
        if (transactionId === 'new') {
            this.props.createTransaction(transaction, () => {
                history.push(`/accounting/${parent}`)
            }, console.log);
        } else {
            this.props.updateTransaction(transactionId, transaction, () => {
                history.push(`/accounting/${parent}`)
            }, console.log);
        }
    };

    onSelectFlow = (flow) => {
        if (flow !== this.state.selectedFlow) {
            this.setState({
                selectedFlow: flow,
                selectedSubFlow: null
            })
        }
    };

    onSelectSubFlow = (flow) => {
        if (flow !== this.state.selectedSubFlow) {
            let parentFlow = null;
            if (flow && flow.parentFlowType) {
                parentFlow = _.find(this.props.flowsByType[flow.parentFlowType], f => f.id === flow.parentFlowId);
            }
            this.setState({
                selectedFlow: parentFlow,
                selectedSubFlow: flow
            });
        }
    };

    onFetchTransactionTemplate = () => {
        const { transactionId } = this.props;
        const { selectedTemplate,  selectedFlow, selectedSubFlow } = this.state;
        const flowId = selectedFlow ? selectedFlow.id : null;
        const subFlowId = selectedSubFlow ? selectedSubFlow.id : null;
        this.props.fetchTransactionTemplate(
            selectedTemplate.name,
            transactionId,
            selectedTemplate.flowType,
            flowId,
            selectedTemplate.subFlowType,
            selectedTemplate.flowType === selectedTemplate.subFlowType ? flowId : subFlowId);
    };

    onDescriptionUpdated = (description) => {
        this.setState({description: description});
    };

    onValueDateUpdated = (valueDate) => {
        this.setState({valueDate: valueDate});
    };

    onLegsUpdated = (legs) => {
        this.setState({legs: legs});
    };

    cashClearingBalance = (accounts, existingEntries, legs) => {
        const accountsById = _.object(_.map(accounts, a => [a.id, a]));
        const sumEntryAmounts = (accumulator, entry) => {
            let amount = 0;
            const account = accountsById[entry.accountId];
            if (account &&  account.routingKey === 'CASH_CLEARING') {
                amount += entry.amount
            }
            return accumulator + amount;
        };
        const oldAmount = existingEntries ? _.reduce(existingEntries, sumEntryAmounts, 0) : 0;
        const sumLegAmounts = (accumulator, leg) => {
            let amount = 0;
            const sourceAccount = accountsById[leg.sourceAccountId];
            const targetAccount = accountsById[leg.targetAccountId];
            if (sourceAccount && sourceAccount.routingKey === 'CASH_CLEARING') {
                amount += leg.amount
            }
            if (targetAccount && targetAccount.routingKey === 'CASH_CLEARING') {
                amount -= leg.amount
            }
            return accumulator + amount;
        };
        const newAmount = legs ? _.reduce(legs, sumLegAmounts, 0) : 0;
        return roundAmount(oldAmount + newAmount);
    };

    extractAllSubFlowsByType = (flowType, flows) => {
        const subFlowsByType = {};
        flows.forEach(flow => {
            const subFlows = flow.subFlows || [];
            _.keys(subFlows).forEach(subFlowType => {
                let arr = subFlowsByType[subFlowType];
                if (!arr) {
                    arr = [];
                    subFlowsByType[subFlowType] = arr
                }
                (subFlows[subFlowType] || []).forEach(f => arr.push(f));
            });
        });
        return subFlowsByType;
    };

    mapFlow = (flow) => {
        return {
            value: flow,
            label: `${flow.id} - ${flow.label}`
        };
    };

    getParentTitle = (parent) => {
        return parent === 'ledger-entries' ? 'Buchungskonten' : 'Neue Umsätze';
    };

    render() {

        const { transactionId, parent } = this.props;

        const {
            accounts,
            flowsByType,
            existingEntries,

            selectedTemplate,
            selectedFlow,
            selectedSubFlow,

            description,
            valueDate,
            legs,

        } = this.state;

        let cashClearingBalance =  this.cashClearingBalance(accounts, existingEntries, legs);

        const cashClearingAlert = legs && legs.length > 0 ? cashClearingBalance !== 0 : false;

        const flows = (selectedTemplate && flowsByType && flowsByType[selectedTemplate.flowType]) || [];

        let subFlowsByType = {};
        if (selectedFlow) {
            subFlowsByType = selectedFlow.subFlows || {};
        } else if (selectedTemplate) {
            subFlowsByType = this.extractAllSubFlowsByType(selectedTemplate.flowType, flows);
        }

        const isNew = transactionId === 'new';

        const edit = this.state.edit || isNew

        const subFlows = _.sortBy((selectedTemplate && subFlowsByType[selectedTemplate.subFlowType]) || [], 'id');

        return (

            <div>

                <div className="context-menu hidden-print">
                    <ol className="breadcrumb display-inline">
                        <li><NavLink path={`/accounting`}>Buchhaltung</NavLink></li>
                        <li><NavLink path={`/accounting/${parent}`}>{this.getParentTitle(parent)}</NavLink></li>
                        <li>{transactionId}</li>
                    </ol>
                    <ul className="nav nav-pills pull-right">
                        <li>
                            <NavLink path={`/accounting/${parent}`}>
                                <i className="glyphicon glyphicon-backward"/><label>Zurück</label>
                            </NavLink>
                        </li>
                        {!edit &&
                        <li>
                            <a onClick={() => this.setState({edit: !edit})}>
                                <i className="glyphicon glyphicon-edit"/><label>Bearbeiten</label>
                            </a>
                        </li>
                        }
                        {edit && !isNew &&
                        <li>
                            <a onClick={() => this.setState({edit: !edit})}>
                                <i className="glyphicon glyphicon-edit"/><label>Abbrechen</label>
                            </a>
                        </li>
                        }
                        {edit && legs && !cashClearingAlert && description && valueDate &&
                            <li>
                                <a onClick={this.onSaveTransaction}>
                                    <i className="glyphicon glyphicon-ok"/><label>Speichern</label>
                                </a>
                            </li>
                        }

                    </ul>
                </div>

                <div className="transaction-container">

                    {edit && !isNew &&
                        <div className="transaction-criteria template-selector">

                            <SelectBox placeholder="Vorlage..."
                                       clearable={true}
                                       values={this.templates}
                                       selectedValue={selectedTemplate}
                                       valueMapper={t => ({value: t, label: t.label})}
                                       onChange={this.onSelectTemplate}/>

                            {flows.length > 0 &&
                            <SelectBox placeholder={flowTypeLabel(selectedTemplate.flowType) + "..."}
                                       clearable={true}
                                       values={flows}
                                       selectedValue={selectedFlow}
                                       valueMapper={this.mapFlow}
                                       onChange={this.onSelectFlow}/>
                            }

                            {subFlows.length > 0 && selectedTemplate.subFlowType !== selectedTemplate.flowType &&
                            <SelectBox placeholder={flowTypeLabel(selectedTemplate.subFlowType) + "..."}
                                       clearable={true}
                                       values={subFlows}
                                       selectedValue={selectedSubFlow}
                                       valueMapper={this.mapFlow}
                                       onChange={this.onSelectSubFlow}/>
                            }


                            <div className="btn-group">
                                <button type="button" className="btn btn-default" onClick={this.onFetchTransactionTemplate}>
                                    <span className="glyphicon glyphicon-refresh"/>
                                </button>
                            </div>

                        </div>
                    }

                    {edit && !isNew &&
                        <div style={{height: "20px"}}/>
                    }

                    {cashClearingAlert &&
                    <div className="alert alert-danger" role="alert">Rot markierte Bilanzen müssen 0 sein</div>
                    }

                    {!description &&
                    <div className="alert alert-danger" role="alert">Ungültige Beschreibung</div>
                    }

                    {!valueDate &&
                    <div className="alert alert-danger" role="alert">Ungültiges Werstellungsdatum</div>
                    }

                    {accounts.length > 0 && flowsByType &&
                    <EditTransactionTable accounts={accounts}
                                          flowsByType={flowsByType}
                                          existingEntries={existingEntries}
                                          description={description}
                                          valueDate={valueDate}
                                          legs={legs}
                                          edit={edit}
                                          onDescriptionUpdated={this.onDescriptionUpdated}
                                          onValueDateUpdated={this.onValueDateUpdated}
                                          onLegsUpdated={this.onLegsUpdated}/>
                    }
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    transactionTemplate: state.accountingReducer.transactionTemplate,
    accounts: state.accountingReducer.accounts,
    flowsByType: state.accountingReducer.flowsByType,
    transactionEntries: state.accountingReducer.transactionEntries,
});

const mapDispatchToProps = {
    fetchTransactionTemplate: fetchTransactionTemplate,
    fetchAccounts: fetchAccounts,
    fetchFlows: fetchFlows,
    fetchTransaction: fetchTransaction,
    createTransaction: createTransaction,
    updateTransaction: updateTransaction,
    revertTransaction: revertTransaction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransactionContainer));
