import React, {Component} from 'react';
import PropTypes from "prop-types";

export default class NavLink extends Component {

    static propTypes = {
        path: PropTypes.string.isRequired,
    };

    render() {
        return (
            <a href={this.props.path} style={this.props.style}>{this.props.children}</a>
        );
    }
}
