import React, {Component} from "react";
import PropTypes from "prop-types";
import {formatAmount, formatDate} from "../../common/utils";
import NavLink from "../../common/components/NavLink";

export default class RentingReport extends Component {

    static propTypes = {
        rows: PropTypes.array.isRequired,
    };

    state = {
        showDetails: false
    };

    toggleShowDetails = () => {
        this.setState({showDetails: !this.state.showDetails})
    };

    renderRow = (prevRow, row) => {
        const { showDetails } = this.state;
        const renderProperty = !prevRow || prevRow.propertyId !== row.propertyId;
        return (
            <tr key={`rr-${row.id}`} className={!renderProperty ? 'elapsed-rental-agreement' : ''}>
                <td className="text-center">
                    {renderProperty &&
                    <NavLink path={`/properties/${row.propertyId}`}>{row.propertyId}</NavLink>
                    }
                </td>
                <td className="text-left">{renderProperty && row.city}</td>
                <td className="text-left">{renderProperty && row.address}</td>
                <td className="text-left">{row.counterParty || 'Unbekannt'}</td>
                {showDetails && <td className="text-center border-left">{row.startDate ? formatDate(row.startDate, 'DD.MM.YYYY') : '-'}</td>}
                {showDetails && <td className="text-center">{row.endDate ? formatDate(row.endDate, 'DD.MM.YYYY') : '∞'}</td>}
                {showDetails && <td className="text-right border-left">{formatAmount(row.netRent, '0,0.00')}</td>}
                {showDetails && <td className="text-right">{formatAmount(row.netRentDue, '0,0.00')}</td>}
                {showDetails && <td className="text-right">{formatAmount(row.netRentMonthly, '0,0.00')}</td>}
                <td className={`text-right ${Math.round(row.netRentDiff) < 0 && 'text-red'}`}>{formatAmount(row.netRentDiff, '0,0.00')}</td>
                {showDetails && <td className="text-right border-left">{formatAmount(row.utilities, '0,0.00')}</td>}
                {showDetails && <td className="text-right">{formatAmount(row.utilitiesDue, '0,0.00')}</td>}
                {showDetails && <td className="text-right">{formatAmount(row.utilitiesMonthly, '0,0.00')}</td>}
                <td className={`text-right ${Math.round(row.utilitiesDiff) < 0 && 'text-red'}`}>{formatAmount(row.utilitiesDiff, '0,0.00')}</td>
                {showDetails && <td className="text-right border-left">{formatAmount(row.rentDeposit, '0,0.00')}</td>}
                {showDetails && <td className="text-right">{formatAmount(row.rentDepositDue, '0,0.00')}</td>}
                <td className={`text-right ${Math.round(row.rentDepositDiff) < 0 && 'text-red'}`}>{formatAmount(row.rentDepositDiff, '0,0.00')}</td>
            </tr>
        );
    };


    render() {

        const { showDetails } = this.state;

        let rows = this.props.rows.sort((r1, r2) => {
            let i = r1.propertyId - r2.propertyId;
            if (i === 0) {
                i = new Date(r2.startDate) - new Date(r1.startDate);
            }
            return i;
        });

        rows = rows.filter(row => showDetails || !row.expired);

        return (
            <div>

                <button className="btn btn-xs btn-link pull-right" style={{padding: "10px 0"}} onClick={this.toggleShowDetails} onMouseDown={e => e.preventDefault()}>
                    {showDetails ? 'Details ausblenden' : 'Details anzeigen'}
                </button>

                <table className="table table-hover renting-report">
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-left">Stadt</th>
                        <th className="text-left">Adresse</th>
                        <th className="text-left">Mieter</th>
                        {showDetails && <th className="text-center">Von</th>}
                        {showDetails && <th className="text-center">Bis</th>}
                        {showDetails && <th className="text-right">Kaltmiete<br/>Haben</th>}
                        {showDetails && <th className="text-right">Kaltmiete<br/>Soll</th>}
                        {showDetails && <th className="text-right">Kaltmiete<br/>Monat</th>}
                        <th className="text-right">Kaltmiete<br/>Bilanz</th>
                        {showDetails && <th className="text-right">Nebenkosten<br/>Haben</th>}
                        {showDetails && <th className="text-right">Nebenkosten<br/>Soll</th>}
                        {showDetails && <th className="text-right">Nebenkosten<br/>Monat</th>}
                        <th className="text-right">Nebenkosten<br/>Bilanz</th>
                        {showDetails && <th className="text-right">Kaution<br/>Haben</th>}
                        {showDetails && <th className="text-right">Kaution<br/>Soll</th>}
                        <th className="text-right">Kaution<br/>Bilanz</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows.map((row, idx) => this.renderRow(idx > 0 ? rows[idx - 1] : null, row))}
                    </tbody>
                </table>
            </div>
        );
    }
}
