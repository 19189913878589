import React, {Component} from "react";
import PropTypes from "prop-types";
import Modal from "../../common/components/Modal";

export default class RentalAgreementEditModal extends Component {

    static propTypes = {
        rentalAgreement: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            rentalAgreement: Object.assign({}, props.rentalAgreement),
        };
    }

    updateState(field, update) {
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            newState.rentalAgreement[field] = update;
            return newState;
        });
    }

    render() {

        const { rentalAgreement} = this.state;

        return (
            <Modal title="Mietvertrag bearbeiten"
                   closeLabel="Schliessen"
                   onClose={this.props.onClose}
                   submitLabel="Speichern"
                   onSubmit={this.props.onSubmit}
                   data={rentalAgreement}
                   classNames="modal-lg">

                <form className="form-horizontal">

                    <FormGroup label="Mieter">
                        <input type="text"
                               className="form-control"
                               value={rentalAgreement.counterParty || ''}
                               onChange={e => this.updateState('counterParty', e.target.value)}/>
                    </FormGroup>

                    <FormGroup label="Adresse">
                        <input type="text"
                               className="form-control"
                               value={rentalAgreement.counterPartyAddress || ''}
                               onChange={e => this.updateState('counterPartyAddress', e.target.value)}/>
                    </FormGroup>

                    <FormGroup label="Von">
                        <input type="date"
                               className="form-control"
                               value={rentalAgreement.startDate || ''}
                               onChange={e => this.updateState('startDate', e.target.value)}/>
                    </FormGroup>

                    <FormGroup label="Bis">
                        <input type="date"
                               className="form-control"
                               value={rentalAgreement.endDate || ''}
                               onChange={e => this.updateState('endDate', e.target.value)}/>
                    </FormGroup>

                    <FormGroup label="Kaltmiete">
                        <div className="input-group">
                            <input type="number"
                                   className="form-control"
                                   value={rentalAgreement.rent || 0}
                                   onChange={e => this.updateState('rent', e.target.value)}/>
                            <span className="input-group-addon">€</span>
                        </div>
                    </FormGroup>

                    <FormGroup label="Nebenkosten">
                        <div className="input-group">
                            <input type="number"
                                   className="form-control"
                                   value={rentalAgreement.utilities || 0}
                                   onChange={e => this.updateState('utilities', e.target.value)}/>
                            <span className="input-group-addon">€</span>
                        </div>
                    </FormGroup>

                    <FormGroup label="Kaution">
                        <div className="input-group">
                            <input type="number"
                                   className="form-control"
                                   value={rentalAgreement.rentDeposit || 0}
                                   onChange={e => this.updateState('rentDeposit', e.target.value)}/>
                            <span className="input-group-addon">€</span>
                        </div>
                    </FormGroup>

                </form>

            </Modal>
        );
    }
}

const FormGroup = ({label, children}) => (
    <div className="form-group">
        <label className="col-md-2 control-label">{label}</label>
        <div className="col-sm-10">
            {children}
        </div>
    </div>
);