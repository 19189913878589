import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import InfiniteScroll from 'react-infinite-scroller';
import LedgerEntryList from "../components/LedgerEntryList";
import AccountFlowAndDateRangeSelector from "../components/AccountFlowAndDateRangeSelector";
import {formatDateAsIso} from "../../common/utils";
import withUser from "../../user/components/withUser";
import {fetchAccounts, fetchFlows, fetchLedgerEntries} from "../actions";
import {withRouter} from "react-router";

class LedgerEntryContainer extends Component {

    state = {
        ledgerEntries: {
            entriesByDate: [],
            loadedSize: 0,
            totalSize: 0,
            debitAmount: 0,
            creditAmount: 0,
        },
        filter: {
            account: null,
            flowType: 'NONE',
            flowId: 0
        },
        version: 0
    };

    componentDidMount() {
        this.props.fetchAccounts();
        this.props.fetchFlows();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.accounts !== this.props.accounts) {
            this.setState({accounts: this.props.accounts});
        }
        if (prevProps.flowsByType !== this.props.flowsByType) {
            this.setState({flowsByType: this.props.flowsByType});
        }
        if (prevProps.ledgerEntries !== this.props.ledgerEntries) {
            this.setState({ledgerEntries: this.props.ledgerEntries});
        }
        if (prevProps.transactionEntries !== this.props.transactionEntries) {
            this.setState({transactionEntries: this.props.transactionEntries});
        }
    }

    onNextPage = (pageIndex) => {
        const { ledgerEntries, filter } = this.state;
        const { account, flowType, flowId, subFlowType, subFlowId, fromDate, toDate } = filter;
        const accountId = account ? account.id : 0;
        const pageSize = 50;
        if (accountId > 0 && (pageIndex === 0 || (pageIndex * pageSize) < ledgerEntries.totalSize)) {
            this.props.fetchLedgerEntries(
                accountId,
                flowId ? flowType : null,
                flowId,
                subFlowId ? subFlowType : null,
                subFlowId,
                fromDate ? formatDateAsIso(fromDate) : null,
                toDate ? formatDateAsIso(toDate) : null,
                pageIndex,
                pageSize
            );
        }
    };

    onFilterChanged = (filter) => {
        this.setState(prevState => {
            return Object.assign({}, prevState, {
                filter: filter,
                version: prevState.version + 1
            })
        }, () => this.onNextPage(0));
    };

    onEntrySelected = (entry) => {
        this.props.history.push(`/accounting/ledger-entries/${entry.transactionId}`);
    };

    render() {

        const { accounts, flowsByType, ledgerEntries, filter, version } = this.state;
        
        const { entriesByDate, loadedSize, totalSize, debitAmount, creditAmount } = ledgerEntries;

        if (!accounts || !flowsByType) {
            return null;
        }

        return (
            <div>
                <AccountFlowAndDateRangeSelector accounts={accounts}
                                                 flowsByType={flowsByType}
                                                 onFilterChanged={this.onFilterChanged}/>
                {filter.account &&
                    <InfiniteScroll
                        key={`bt-scroller-${version}`}
                        pageStart={0}
                        loadMore={this.onNextPage}
                        hasMore={loadedSize < totalSize}
                        loader={<div className="loader" key={0}>Loading ...</div>}>
                        <LedgerEntryList account={filter.account}
                                         entriesByDate={entriesByDate}
                                         debitAmount={debitAmount}
                                         creditAmount={creditAmount}
                                         onEntrySelected={this.onEntrySelected}/>
                    </InfiniteScroll>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ledgerEntries: state.accountingReducer.ledgerEntries,
    accounts: state.accountingReducer.accounts,
    flowsByType: state.accountingReducer.flowsByType,
});

const mapDispatchToProps = {
    fetchAccounts: fetchAccounts,
    fetchFlows: fetchFlows,
    fetchLedgerEntries: fetchLedgerEntries,
};

export default withRouter(withUser(connect(mapStateToProps, mapDispatchToProps)(LedgerEntryContainer)));

