import {
    IMAGE,
    IMAGE_COLLECTION_UPDATED,
    IMAGE_COLLECTIONS_RECEIVED,
    IMAGE_DESCRIPTORS_RECEIVED,
    PROPERTIES_RECEIVED,
} from "./actions";
import {groupBy} from "underscore";

const initialState = {
    collections: [],
    collectionsDirty: false,
    descriptorsByCollectionId: {},
    propertiesById: {}
};

function imageReducer(state = initialState, action) {

    if (action.scope !== IMAGE) {
        return state;
    }

    switch (action.type) {

        case PROPERTIES_RECEIVED:
            return Object.assign({}, state, {
                propertiesById: action.properties.reduce((acc, prop) => {
                    acc[prop.id] = prop;
                    return acc;
                }, {})
            });

        case IMAGE_DESCRIPTORS_RECEIVED:
            return Object.assign({}, state, {
                descriptorsByCollectionId: groupBy(action.imageDescriptors, 'collectionId')
            });

        case IMAGE_COLLECTIONS_RECEIVED:
            return Object.assign({}, state, {
                collections: action.collections,
                collectionsDirty: false
            });

        case IMAGE_COLLECTION_UPDATED:
            return Object.assign({}, state, {
                collectionsDirty: true
            });

        default:
            return state;
    }
}

export default imageReducer;
