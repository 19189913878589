import moment from "moment";
import * as numeral from "numeral";
import {find, groupBy, pairs} from "underscore";

export function googleUser() {
    return window.gapi.auth2.getAuthInstance().currentUser.get();
}

export function formatDate(date, format = 'DD.MM.YYYY HH:mm') {
    return moment(date).format(format)
}

export function formatDateAsIso(date) {
    return formatDate(date, 'YYYY-MM-DD')
}

export function formatAmount(amount, format = '0,0.00') {
    return numeral(amount).format(format)
}

export function noOp() {

}

export function parseFloatNumber(num) {
    if (!num) {
        return null;
    } else {
        return parseFloat(num.replace(',', '.'));
    }
}

export function roundAmount(num, decimals = 2){
    const mult = Math.pow(10, decimals);
    return Math.round(num * mult) / mult;
}

export function groupRowsByKey(rowsByKey, rows, groupByKey) {
    const last = rowsByKey.length > 0 ? rowsByKey[rowsByKey.length - 1] : null;
    pairs(groupBy(rows, groupByKey)).forEach(pair => {
        const date = pair[0];
        const rows = pair[1];
        if (last && last[0] === date) {
            last[1].concat(rows);
        } else {
            rowsByKey.push(pair)
        }
    });
    return rowsByKey;
}

export function getUrlParam(url, name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(url);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function action(action) {
    return action;
}

export function flowTypeLabel(flowType) {
    switch (flowType) {
        case 'BANK_ACCOUNT':
            return 'Bankkonto';
        case 'BANK_TRANSACTION':
            return 'Banktransaktion';
        case 'PROPERTY':
            return 'Objekt';
        case 'LOAN':
            return 'Darlehen';
        case 'RENTAL_AGREEMENT':
            return 'Mietvertrag';
        default:
            return 'Ohne Bezug';
    }
}

export function renderFlow(flowsByType, obj) {
    if (obj) {
        const details = obj.linkedObject;
        if (obj.type === 'PROPERTY') {
            return `${obj.id} - ${details.city}, ${details.streetAndNumber}, ${details.indoorLocation || '-'}`;
        } else if (obj.type === 'RENTAL_AGREEMENT') {
            const properties = flowsByType['PROPERTY'];
            const property = properties ? find(properties, p => p.id === details.propertyId) : null;
            const propertyStr = property ? `#${details.propertyId}, ${property.linkedObject.city}, ${property.linkedObject.streetAndNumber}` : `#${details.propertyId}`;
            return `${obj.id} - ${details.counterParty || '...'} - ${propertyStr}`;
        } else if (obj.type === 'LOAN') {
            return `${obj.id} - ${details.name}`;
        }
    }
    return null;
}