import React, {Component} from "react";
import {formatAmount, formatDate} from "../../common/utils";
import NavLink from "../../common/components/NavLink";
import PropTypes from "prop-types";
import {sortBy} from "underscore";

export default class PropertyReport extends Component {

    static propTypes = {
        rows: PropTypes.array.isRequired,
    };

    state = {
        rows: [],
        sortKey: 'id',
        sortDirection: 1
    };

    componentDidMount() {
        this.sort(this.state.sortKey, this.state.sortDirection);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rows !== this.props.rows) {
            this.sort(this.state.sortKey, this.state.sortDirection);
        }
    }

    sort = (sortKey, sortDirection) => {
        let rows = sortBy(this.props.rows, sortKey);
        if (sortDirection < 0) {
            rows = rows.reverse()
        }
        this.setState({
            rows: rows,
            sortKey: sortKey,
            sortDirection: sortDirection
        })
    };

    renderSortIcon = (key) => {
        const { sortKey, sortDirection } = this.state;
        if (key !== sortKey) {
            return <i className="fa fa-unsorted ml-1" style={{cursor: "pointer", marginLeft: "2px"}} onClick={() => this.sort(key, 1)}/>
        } else if (sortDirection >= 0) {
            return <i className="fa fa-caret-up ml-1" style={{cursor: "pointer", marginLeft: "2px"}} onClick={() => this.sort(key, -1)}/>
        } else {
            return <i className="fa fa-caret-down ml-1" style={{cursor: "pointer", marginLeft: "2px"}} onClick={() => this.sort(key, 1)}/>
        }
    };

    renderRow = (row) => {
        return (
            <tr key={`pr-${row.id}`}>
                <td className="text-center"><NavLink path={`/properties/${row.id}`}>{row.id}</NavLink></td>
                <td className="text-left">{row.city}</td>
                <td className="text-left">{row.address}</td>
                <td className="text-center">{row.usageRightsSinceDate && formatDate(row.usageRightsSinceDate, 'DD.MM.YYYY')}</td>
                <td className="text-right">{formatAmount(row.area, '0,0')}</td>
                {row.rentedOut ? (
                    <td className="text-center">Ja</td>
                ) : (
                    <td className="text-center text-red">Nein</td>
                )}
                <td className="text-center">
                    <div className="amortisation">
                        <div className="progress">
                            <div className="progress-bar" style={{width: row.amortisationPercentage * 100 + '%'}}/>
                        </div>
                        <div className="percentage">{formatAmount(row.amortisationPercentage * 100, '0,0')}%</div>
                        <div className="remaining-months text-muted">{row.monthsUntilFullAmortisation > 0 && `(+ ${formatAmount(row.monthsUntilFullAmortisation, '0,0')} m)`}</div>
                    </div>
                </td>
                <td className={`text-right ${row.annualRoiPercentage < 6 && 'text-red'}`}>{formatAmount(row.annualRoiPercentage, '0,0.0')}</td>
                <td className="text-right">{formatAmount(row.revenue, '0,0')}</td>
                <td className="text-right">{formatAmount(row.totalInvestment, '0,0')}</td>
                <td className="text-right">{formatAmount(row.purchasePrice, '0,0')}</td>
                <td className="text-right">{formatAmount(row.purchasePricePerSqm, '0,0')}</td>
                <td className="text-right">{formatAmount(row.investments, '0,0')}</td>
            </tr>
        );
    };

    render() {

        const { rows } = this.state;

        return (
            <table className="table table-hover property-report">
                <thead>
                <tr>
                    <th className="text-center text-nowrap"># {this.renderSortIcon('id')}</th>
                    <th className="text-left text-nowrap">Stadt {this.renderSortIcon('city')}</th>
                    <th className="text-left text-nowrap">Adresse {this.renderSortIcon('address')}</th>
                    <th className="text-center text-nowrap">Nutzen / Lasten  {this.renderSortIcon('usageRightsSinceDate')}</th>
                    <th className="text-right text-nowrap">m² {this.renderSortIcon('area')}</th>
                    <th className="text-center text-nowrap">Vermietet {this.renderSortIcon('rentedOut')}</th>
                    <th className="text-center text-nowrap">Amortisationsstatus {this.renderSortIcon('amortisationPercentage')}</th>
                    <th className="text-right text-nowrap">Rendite {this.renderSortIcon('annualRoiPercentage')}</th>
                    <th className="text-right text-nowrap">Einnahmen {this.renderSortIcon('revenue')}</th>
                    <th className="text-right text-nowrap">Ausgaben {this.renderSortIcon('totalInvestment')}</th>
                    <th className="text-right text-nowrap">Kaufpreis {this.renderSortIcon('purchasePrice')}</th>
                    <th className="text-right text-nowrap">KP/m² {this.renderSortIcon('purchasePricePerSqm')}</th>
                    <th className="text-right text-nowrap">Investitionen {this.renderSortIcon('investments')}</th>
                </tr>
                </thead>
                <tbody>
                    {rows.map(this.renderRow)}
                </tbody>
            </table>
        );
    }
}
