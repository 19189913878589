import React, {Component} from "react";
import PropTypes from "prop-types";
import CommentForm from "../components/CommentForm";
import ActivityPanel from "../components/ActivityPanel";
import {createComment, deleteComment, fetchActivities, updateComment} from "../actions";
import connect from "react-redux/es/connect/connect";

class PropertyActivityContainer extends Component {

    static propTypes = {
        propertyId: PropTypes.number.isRequired,
    };

    componentDidMount() {
        this.fetchActivities();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.propertyId !== this.props.propertyId) {
            this.fetchActivities();
        } else if (!prevProps.activitiesDirty && this.props.activitiesDirty) {
            this.fetchActivities();
        }
    }

    fetchActivities = () => {
        this.props.fetchActivities(this.props.propertyId);
    };

    onSubmitComment = (comment) => {
        this.props.createComment(this.props.propertyId, comment);
    };

    onUpdateComment = (id, comment) => {
        this.props.updateComment(id, comment);
    };

    onDeleteComment = (id) => {
        this.props.deleteComment(id);
    };

    render() {
        return (
            <div>
                <CommentForm onSubmit={this.onSubmitComment}/>
                <ActivityPanel activities={this.props.activities}
                               onUpdateComment={this.onUpdateComment}
                               onDeleteComment={this.onDeleteComment}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    activities: state.propertyReducer.activities,
    activitiesDirty: state.propertyReducer.activitiesDirty,
});

const mapDispatchToProps = {
    fetchActivities: fetchActivities,
    createComment: createComment,
    updateComment: updateComment,
    deleteComment: deleteComment
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyActivityContainer);






