import React, {Component} from "react";
import 'bootstrap-social/bootstrap-social.css'
import PropTypes from "prop-types";
import Select from "react-select";
import * as _ from "underscore";

export default class SelectBox extends Component {

    static propTypes = {
        placeholder: PropTypes.string,
        clearable: PropTypes.bool,
        values: PropTypes.array.isRequired,
        valueMapper: PropTypes.func.isRequired,
        selectedValue: PropTypes.any,
        size: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool
    };

    styles = {
        md: {
            customStyles: {
                control: provided => ({...provided, minHeight: "34px", borderRadius: "2px"}),
                indicatorsContainer: provided => ({...provided, height: "32px"}),
                clearIndicator: provided => ({...provided, padding: "5px"}),
                dropdownIndicator: provided => ({...provided, padding: "5px"})
            },
            font: "14px sans-serif"
        },
        sm : {
            customStyles: {
                control: provided => ({...provided, minHeight: "28px", borderRadius: "2px"}),
                indicatorsContainer: provided => ({...provided, height: "26px"}),
                indicatorSeparator: provided => ({...provided, marginTop: "4px", marginBottom: "4px",}),
                clearIndicator: provided => ({...provided, padding: "2px"}),
                dropdownIndicator: provided => ({...provided, padding: "2px"}),
                input: provided => ({...provided, margin: "0"}),
                valueContainer: provided => ({...provided, paddingTop: "0", paddingBottom: "0"}),
            },
            font: "11px sans-serif"
        }
    };

    measureTextWidth = (text) => {
        if (!this.canvas) {
            this.canvas = document.createElement("canvas");
        }
        const context = this.canvas.getContext("2d");
        context.font = this.getStyles().font;
        const metrics = context.measureText(text);
        return Math.ceil(metrics.width);
    };

    selectWidth = (options) => {
        const textWidth = _.reduce(options.map(o => o.label), (memo, label) => Math.max(memo, this.measureTextWidth(label)), 0);
        return (textWidth + 90) + 'px';
    };

    findSelectedOption = (options) => {
        const { selectedValue } = this.props;
        if (selectedValue) {
            return _.find(options, opt => opt.value === selectedValue);
        } else {
            return null;
        }
    };

    onChange = (option) => {
        this.props.onChange(option ? option.value : null)
    };

    getStyles = () => {
        const { size } = this.props;
        return size ? this.styles[size] : this.styles.md;
    };

    render() {
        const { placeholder, clearable, values, valueMapper, disabled } = this.props;
        const options = values.map(valueMapper);
        const selectedOption = this.findSelectedOption(options);
        return (
            <div style={{width: this.selectWidth(options)}}>
                <Select placeholder={placeholder}
                        options={options}
                        value={selectedOption}
                        isDisabled={disabled}
                        isClearable={clearable}
                        styles={this.getStyles().customStyles}
                        onChange={this.onChange}/>
            </div>
        );
    }
}

