import {
    ACCOUNT_UPDATED,
    ACCOUNTING,
    ACCOUNTS_RECEIVED, BALANCES_RECEIVED,
    BANK_ACCOUNT_UPDATED,
    BANK_ACCOUNTS_RECEIVED,
    BANK_TRANSACTIONS_RECEIVED,
    BANK_TRANSACTIONS_UPDATED,
    FLOWS_RECEIVED,
    LEDGER_ENTRIES_RECEIVED, SHOW_BANK_TRANSACTION_UPLOAD_MODAL, TOGGLE_BANK_TRANSACTION_UPLOAD_MODAL,
    TRANSACTION_RECEIVED,
    TRANSACTION_TEMPLATE_RECEIVED, UNACCOUNTED_LEDGER_ENTRIES_RECEIVED, UNACCOUNTED_LEDGER_ENTRIES_UPDATED
} from "./actions";
import {groupRowsByKey} from "../common/utils";
import {_} from "underscore";

const initialState = {

    bankAccounts: [],
    bankAccountsDirty: false,

    bankTransactions: {
        transactionsByDate: [],
        loadedSize: 0,
        totalSize: 0,
        debitAmount: 0,
        creditAmount: 0,
    },
    bankTransactionsDirty: false,

    showBankTransactionUploadModal: false,

    accounts: [],
    accountsDirty: false,

    flowsByType: {},

    ledgerEntries: {
        entriesByDate: [],
        loadedSize: 0,
        totalSize: 0,
        debitAmount: 0,
        creditAmount: 0,
    },

    unaccountedLedgerEntries: {
        entriesByDate: [],
        loadedSize: 0,
        totalSize: 0,
        debitAmount: 0,
        creditAmount: 0,
    },
    unaccountedLedgerEntriesDirty: false,

    balances: [],

    transactionEntries: [],

    transactionTemplate: {}
};

function bankTransactionDate(bankTransaction) {
    return new Date(bankTransaction.valueDate).getTime();
}

function ledgerEntryDate(entry) {
    return new Date(entry.valueDate).getTime();
}

function accountingReducer(state = initialState, action) {

    if (action.scope !== ACCOUNTING) {
        return state;
    }

    switch (action.type) {

        case TOGGLE_BANK_TRANSACTION_UPLOAD_MODAL:
            return Object.assign({}, state, {
                showBankTransactionUploadModal: action.show
            });

        case BANK_ACCOUNTS_RECEIVED:
            return Object.assign({}, state, {
                bankAccounts: action.accounts,
                bankAccountsDirty: false
            });

        case BANK_ACCOUNT_UPDATED:
            return Object.assign({}, state, {
                bankAccountsDirty: true
            });

        case BANK_TRANSACTIONS_RECEIVED: {
            const page = action.page;
            if (page.rows.length === 0) {
                return Object.assign({}, state, {
                    bankTransactions: {
                        transactionsByDate: [],
                        loadedSize: 0,
                        totalSize: 0,
                        debitAmount: 0,
                        creditAmount: 0,
                    },
                    bankTransactionsDirty: false
                });
            }
            const rowsByDate = groupRowsByKey(page.index === 0 ? [] : [...state.bankTransactions.transactionsByDate], page.rows, bankTransactionDate);
            return Object.assign({}, state, {
                bankTransactions: {
                    transactionsByDate: rowsByDate,
                    loadedSize: (page.index * page.size) + page.rows.length,
                    totalSize: page.metadata.totalSize,
                    debitAmount: page.metadata.debitAmount,
                    creditAmount: page.metadata.creditAmount,
                },
                bankTransactionsDirty: false
            });
        }

        case BANK_TRANSACTIONS_UPDATED:
            return Object.assign({}, state, {
                bankTransactionsDirty: true
            });

        case ACCOUNTS_RECEIVED:
            return Object.assign({}, state, {
                accounts: action.accounts,
                accountsDirty: false
            });

        case ACCOUNT_UPDATED:
            return Object.assign({}, state, {
                accountsDirty: true
            });

        case FLOWS_RECEIVED:
            const flowsByType = action.flowsByType;
            _.keys(flowsByType).forEach(flowType => {
                 const flows = flowsByType[flowType] || [];
                 flows.forEach(flow => {
                     const subFlowsByType = flow.subFlows || {};
                     _.keys(subFlowsByType).forEach(subFlowType => {
                         const subFlows = subFlowsByType[subFlowType] || [];
                         subFlows.forEach(subFlow => {
                             subFlow.parentFlowId = flow.id;
                             subFlow.parentFlowType = flowType;
                         })
                     });
                 })
            });
            return Object.assign({}, state, {
                flowsByType: flowsByType
            });

        case LEDGER_ENTRIES_RECEIVED: {
            const page = action.page;
            if (page.rows.length === 0) {
                return Object.assign({}, state, {
                    ledgerEntries: {
                        entriesByDate: [],
                        loadedSize: 0,
                        totalSize: 0,
                        debitAmount: 0,
                        creditAmount: 0,
                    }
                });
            }
            const rowsByDate = groupRowsByKey(page.index === 0 ? [] : [...state.ledgerEntries.entriesByDate], page.rows, ledgerEntryDate);
            return Object.assign({}, state, {
                ledgerEntries: {
                    entriesByDate: rowsByDate,
                    loadedSize: (page.index * page.size) + page.rows.length,
                    totalSize: page.metadata.totalSize,
                    debitAmount: page.metadata.debitAmount,
                    creditAmount: page.metadata.creditAmount,
                }
            });
        }

        case UNACCOUNTED_LEDGER_ENTRIES_RECEIVED: {
            const page = action.page;
            if (page.rows.length === 0) {
                return Object.assign({}, state, {
                    unaccountedLedgerEntries: {
                        entriesByDate: [],
                        loadedSize: 0,
                        totalSize: 0,
                        debitAmount: 0,
                        creditAmount: 0,
                    },
                    unaccountedLedgerEntriesDirty: false
                });
            }
            const rowsByDate = groupRowsByKey(page.index === 0 ? [] : [...state.unaccountedLedgerEntries.entriesByDate], page.rows, ledgerEntryDate);
            return Object.assign({}, state, {
                unaccountedLedgerEntries: {
                    entriesByDate: rowsByDate,
                    loadedSize: (page.index * page.size) + page.rows.length,
                    totalSize: page.metadata.totalSize,
                    debitAmount: page.metadata.debitAmount,
                    creditAmount: page.metadata.creditAmount,
                },
                unaccountedLedgerEntriesDirty: false
            });
        }

        case UNACCOUNTED_LEDGER_ENTRIES_UPDATED:
            return Object.assign({}, state, {
                unaccountedLedgerEntriesDirty: true
            });

        case BALANCES_RECEIVED:
            return Object.assign({}, state, {
                balances: action.balances
            });

        case TRANSACTION_RECEIVED:
            return Object.assign({}, state, {
                transactionEntries: action.transactionEntries
            });

        case TRANSACTION_TEMPLATE_RECEIVED:
            return Object.assign({}, state, {
                transactionTemplate: action.template
            });

        default:
            return state;
    }
}

export default accountingReducer;