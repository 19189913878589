import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {
    createLoan,
    deleteLoan,
    fetchLoans,
    updateLoan
} from "../actions";
import CrudTable from "../../common/components/CrudTable";
import withUser from "../../user/components/withUser";

class LoanContainer extends Component {

    state = {
        rows: []
    };

    columns = [];

    componentDidMount() {

        const { permissions } = this.props;

        this.columns = [
            { key: 'id',                 label: '#',            type: 'number', editable: false },
            { key: 'name',               label: 'Name',         type: 'text',   editable: permissions.admin },
            { key: 'description',        label: 'Beschreibung', type: 'text',   editable: permissions.admin },
            { key: 'annualInterestRate', label: 'Zins',         type: 'number', editable: permissions.admin },
        ];

        this.props.fetchRows();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rows !== this.props.rows) {
            this.setState({ rows: this.props.rows });
        }
        if (!prevProps.dirty && this.props.dirty) {
            this.props.fetchRows();
        }
    }

    render() {
        return (
            <CrudTable rows={this.state.rows}
                       columns={this.columns}
                       onRowCreated={this.props.createRow}
                       onRowUpdated={this.props.updateRow}
                       onRowDeleted={this.props.deleteRow}/>
        );
    }
}

const mapStateToProps = state => ({
    rows: state.loanReducer.loans,
    dirty: state.loanReducer.loansDirty
});

const mapDispatchToProps = {
    fetchRows: fetchLoans,
    createRow: createLoan,
    updateRow: updateLoan,
    deleteRow: deleteLoan
};

export default withUser(connect(mapStateToProps, mapDispatchToProps)(LoanContainer));

