import * as API from "../common/api";
import { action } from "../common/utils";

export const IMAGE = "IMAGE";

export const PROPERTIES_RECEIVED = 'PROPERTIES_RECEIVED';

export const IMAGE_DESCRIPTORS_RECEIVED = 'IMAGE_DESCRIPTORS_RECEIVED';

export const IMAGE_COLLECTIONS_RECEIVED = 'IMAGE_COLLECTIONS_RECEIVED';

export const IMAGE_COLLECTION_UPDATED = 'IMAGE_COLLECTION_UPDATED';

export function fetchProperties() {
    return function(dispatch) {
        API.fetchProperties(null, null, 'city,streetAndNumber,indoorLocation')
            .then(response => dispatch(action({ scope: IMAGE, type: PROPERTIES_RECEIVED, properties: response.data })));
    }
}

export function fetchImageDescriptors() {
    return function(dispatch) {
        API.fetchImageDescriptors()
            .then(response => dispatch(action({ scope: IMAGE, type: IMAGE_DESCRIPTORS_RECEIVED, imageDescriptors: response.data })));
    }
}

export function fetchImageCollections() {
    return function(dispatch) {
        API.fetchImageCollections()
            .then(response => dispatch(action({ scope: IMAGE, type: IMAGE_COLLECTIONS_RECEIVED, collections: response.data })));
    }
}

export function createImageCollection(collection, onSuccess, onError) {
    return function (dispatch) {
        API.createImageCollection(collection)
            .then(() => {
                dispatch(action({ scope: IMAGE, type: IMAGE_COLLECTION_UPDATED }));
                onSuccess();
            })
            .catch(onError);
    }
}

export function updateImageCollection(collection, onSuccess, onError) {
    return function (dispatch) {
        API.updateImageCollection(collection)
            .then(() => {
                dispatch(action({ scope: IMAGE, type: IMAGE_COLLECTION_UPDATED }));
                onSuccess();
            })
            .catch(onError);
    }
}

export function deleteImageCollection(id, onSuccess, onError) {
    return function(dispatch) {
        API.deleteImageCollection(id)
            .then(() => {
                dispatch(action({ scope: IMAGE, type: IMAGE_COLLECTION_UPDATED }));
                onSuccess();
            })
            .catch(onError);
    }
}

export function uploadImage(collectionId, file, onUploadProgress, onSuccess, onError) {
    return function (dispatch) {
        API.uploadImage(collectionId, file, onUploadProgress)
            .then(() => {
                dispatch(action({ scope: IMAGE, type: IMAGE_COLLECTION_UPDATED }));
                onSuccess();
            })
            .catch(onError);
    }
}

export function deleteImage (collectionId, imageId, onSuccess, onError) {
    return function(dispatch) {
        API.deleteImage(collectionId, imageId)
            .then(() => {
                dispatch(action({ scope: IMAGE, type: IMAGE_COLLECTION_UPDATED }));
                onSuccess();
            })
            .catch(onError);
    }
}