import React, {Component} from "react";
import PropTypes from "prop-types";
import TransactionDateRangePicker from "../../common/components/TransactionDateRangePicker";
import SelectBox from "../../common/components/SelectBox";
import * as _ from "underscore";
import moment from "moment";

export default class BankAccountAndDateRangeSelector extends Component {

    static propTypes = {
        accounts: PropTypes.array.isRequired,
        onFilterChanged: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        const now = new Date();
        this.state = {
            selectedAccount: _.first(this.props.accounts),
            selectedDateRange: []
        };
    }

    componentDidMount() {
        this.onRefresh();
    }

    onSelectAccount = (account) => {
        if (account !== this.state.selectedAccount) {
            this.setState({selectedAccount: account})
        }
    };

    onRefresh = () => {
        this.props.onFilterChanged({
            account: this.state.selectedAccount,
            fromDate: this.state.selectedDateRange ? _.first(this.state.selectedDateRange) : null,
            toDate: this.state.selectedDateRange ? _.last(this.state.selectedDateRange) : null,
        })
    };

    onDateRangeChanged = (dateRange) => {
        if (dateRange !== this.state.selectedDateRange) {
            this.setState({selectedDateRange: dateRange});
        }
    };

    render() {

        const { accounts } = this.props;

        const { selectedAccount, selectedDateRange } = this.state;

        return (
            <div className="transaction-criteria bank-transaction-selector">

                {accounts &&
                    <SelectBox placeholder="Bankkonto..."
                               clearable={true}
                               values={accounts}
                               selectedValue={selectedAccount}
                               valueMapper={account => ({value: account, label: account.name})}
                               onChange={this.onSelectAccount}/>
                }

                <TransactionDateRangePicker value={selectedDateRange}
                                            onChange={this.onDateRangeChanged}/>

                <div className="btn-group">
                    <button type="button" className="btn btn-default" onClick={this.onRefresh}>
                        <span className="glyphicon glyphicon-refresh"/>
                    </button>
                </div>

            </div>
        );
    }

}