import {PROPERTIES_RECEIVED, PROPERTY_GROUP, PROPERTY_GROUP_UPDATED, PROPERTY_GROUPS_RECEIVED} from "./actions";
import * as _ from "underscore";

const initialState = {

    propertyGroups: [],
    propertyGroupsDirty: false,

    properties: [],
};

function propertyGroupReducer(state = initialState, action) {

    if (action.scope !== PROPERTY_GROUP) {
        return state;
    }

    switch (action.type) {

        case PROPERTY_GROUPS_RECEIVED:
            return Object.assign({}, state, {
                propertyGroups: action.groups,
                propertyGroupsDirty: false
            });

        case PROPERTY_GROUP_UPDATED:
            return Object.assign({}, state, {
                propertyGroupsDirty: true
            });

        case PROPERTIES_RECEIVED:
            return Object.assign({}, state, {
                properties: _.sortBy(action.properties, 'id')
            });

        default:
            return state;
    }
}

export default propertyGroupReducer;
