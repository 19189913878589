import {ACTIVITIES_RECEIVED, ACTIVITY} from "./actions";
import {groupRowsByKey} from "../common/utils";

const initialState = {
    activitiesByDate: [],
    loadedSize: 0,
    totalSize: 0
};

function activityDate(activity) {
    const date = new Date(activity.createTime);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
}

function activityReducer(state = initialState, action) {

    if (action.scope !== ACTIVITY) {
        return state;
    }

    switch (action.type) {

        case ACTIVITIES_RECEIVED:
            const page = action.page;
            if (page.rows.length === 0) {
                return state;
            }
            const rowsByDate = groupRowsByKey(page.index === 0 ? [] : [...state.activitiesByDate], page.rows, activityDate);
            return Object.assign({}, state, {
                activitiesByDate: rowsByDate,
                loadedSize: (page.index * page.size) + page.rows.length,
                totalSize: page.metadata.totalSize
            });

        default:
            return state;
    }
}

export default activityReducer;
