import {combineReducers} from 'redux';
import {routerReducer} from "react-router-redux";
import propertyReducer from "./modules/property/reducers";
import imageReducer from "./modules/image/reducers";
import searchReducer from "./modules/search/reducers";
import activityReducer from "./modules/activity/reducers";
import accountingReducer from "./modules/accounting/reducers";
import userReducer from "./modules/user/reducers";
import loanReducer from "./modules/loan/reducers";
import propertyGroupReducer from "./modules/property-group/reducers";
import reportingReducer from "./modules/reporting/reducers";

const rootReducer = combineReducers({
    routerReducer,
    propertyReducer,
    propertyGroupReducer,
    imageReducer,
    searchReducer,
    activityReducer,
    userReducer,
    loanReducer,
    accountingReducer,
    reportingReducer
});

export default rootReducer;
