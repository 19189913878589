import PropTypes from "prop-types";
import {formatDate} from "../../common/utils";
import React, {Component} from "react";
import CommentForm from "./CommentForm";

import {withTranslation} from 'react-i18next';

class Activity extends Component {

    static propTypes = {
        first: PropTypes.bool.isRequired,
        activity: PropTypes.object.isRequired,
        onUpdateComment: PropTypes.func.isRequired,
        onDeleteComment: PropTypes.func.isRequired,
        onShowChanges: PropTypes.func.isRequired,
    };

    state = {
        value: '',
        editable: false,
    };

    onToggleEditable = (event) => {
        event.preventDefault();
        this.setState({editable: !this.state.editable});
    };

    onShowChanges = (event) => {
        event.preventDefault();
        this.props.onShowChanges(this.props.activity)
    };

    onUpdateComment = (value) => {
        this.props.onUpdateComment(this.props.activity.commentId, value);
    };

    onDeleteComment = (event) => {
        event.preventDefault();
        this.props.onDeleteComment(this.props.activity.commentId);
    };

    renderChanges(activity) {
        const { t } = this.props;
        return (
            <div>
                <div className="spacer5"/>
                <ul>
                    {activity.changes.length > 0 && activity.changes.map(c =>
                        <li key={activity.id + '-' + c.name}><strong>{t(`changes:${activity.type}.${c.name}`)}</strong></li>
                    )}
                </ul>
                <a href="" onClick={this.onShowChanges}>Details</a>
            </div>
        );
    };

    render() {

        const { activity, first } = this.props;

        const isComment = activity.type === 'COMMENT_CREATED' || activity.type === 'COMMENT_UPDATED';

        return (

            <div className={first ? 'activity' : 'activity activity-n'}>

                <div className="activity-heading">
                    <span className="text-primary">{activity.userName}</span><span className="text-muted"> am {formatDate(activity.createTime)}</span>
                    {isComment &&
                        <div className="pull-right hidden-print">
                            <a href="" onClick={this.onToggleEditable} style={{marginRight: "5px"}}>
                                <i className={this.state.editable ? 'glyphicon glyphicon-remove' : 'glyphicon glyphicon-edit'}/>
                            </a>
                            <a href="" onClick={this.onDeleteComment}>
                                <i className="glyphicon glyphicon-trash"/>
                            </a>
                        </div>
                    }
                </div>

                {isComment && this.state.editable &&
                    <CommentForm style={{margin: "10px 10px"}} value={activity.text} onSubmit={this.onUpdateComment}/>
                }

                {isComment && !this.state.editable &&
                    <div className="activity-body">
                        {activity.text}
                    </div>
                }

                {activity.type === 'PROPERTY_UPDATED' &&
                    <div className="activity-body">
                        Objekt wurde bearbeitet
                        { this.renderChanges(activity) }
                    </div>
                }

                {activity.type === 'RENTAL_AGREEMENT_CREATED' &&
                    <div className="activity-body">
                        Mietvertrag <strong>#{activity.rentalAgreementId} ({activity.text})</strong> wurde angelegt
                    </div>
                }

                {activity.type === 'RENTAL_AGREEMENT_UPDATED' &&
                    <div className="activity-body">
                        Mietvertrag <strong>#{activity.rentalAgreementId} ({activity.text})</strong> wurde bearbeitet
                        { this.renderChanges(activity) }
                    </div>
                }

                {activity.type === 'RENTAL_AGREEMENT_DELETED' &&
                    <div className="activity-body">
                        Mietvertrag <strong>#{activity.rentalAgreementId} ({activity.text})</strong> wurde gelöscht
                    </div>
                }

                {activity.type === 'ATTACHMENT_CREATED' &&
                    <div className="activity-body">
                        Anhang <strong>{activity.text}</strong> wurde hinzugefügt
                    </div>
                }

                {activity.type === 'ATTACHMENT_UPDATED' &&
                    <div className="activity-body">
                        Anhang <strong>{activity.text}</strong> wurde bearbeitet
                        { this.renderChanges(activity) }
                    </div>
                }

                {activity.type === 'ATTACHMENT_DELETED' &&
                    <div className="activity-body">
                        Anhang <strong>{activity.text}</strong> wurde gelöscht
                    </div>
                }

                {activity.type === 'LIKE_UPDATED' &&
                    <div className="activity-body">
                        <i className={activity.text === 'true' ? 'glyphicon glyphicon-thumbs-up' : 'glyphicon glyphicon-thumbs-down'}/>
                    </div>
                }

            </div>
        );
    }
}

export default withTranslation('changes')(Activity);
