import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {Tab, Tabs} from "react-bootstrap";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {toggleBankTransactionUploadModal} from "../actions";
import LedgerEntryContainer from "./LedgerEntryContainer";
import BankTransactionContainer from "./BankTransactionContainer";
import UnaccountedLedgerEntryContainer from "./UnaccountedLedgerEntryContainer";
import BalanceContainer from "./BalanceContainer";

class AccountingContainer extends Component {

    static propTypes = {
        tab: PropTypes.string.isRequired,
    };

    onShowUploadModal = () => {
        this.props.toggleBankTransactionUploadModal(true);
    };

    onShowNewTransactionModal = () => {
        this.props.history.push(`/accounting/${this.props.tab}/new`);
    };

    onSelectTab = (tab) => {
        this.props.history.push(`/accounting/${tab}`)
    };

    getTabTitle = (tab) => {
        switch (tab) {
            case 'ledger-entries':
                return 'Buchungsumsätze';
            case 'bank-transactions':
                return 'Bankumsätze';
            case 'balances':
                return 'Kontostände'
            default:
                return 'Zu buchende Umsätze';
        }
    };

    render() {

        const { tab } = this.props;

        return (

            <div>

                <div className="context-menu hidden-print">
                    <ol className="breadcrumb display-inline">
                        <li>Buchhaltung</li>
                        <li>{this.getTabTitle(tab)}</li>
                    </ol>
                    <ul className="nav nav-pills pull-right">
                        {tab === 'bank-transactions' &&
                        <li>
                            <a onClick={this.onShowUploadModal}>
                                <i className="glyphicon glyphicon-cloud-upload"/><label>Kontauszug laden</label>
                            </a>
                        </li>
                        }
                        {tab !== 'bank-transactions' &&
                        <li>
                            <a onClick={this.onShowNewTransactionModal}>
                                <i className="glyphicon glyphicon-plus"/><label>Neue Buchung</label>
                            </a>
                        </li>
                        }
                    </ul>
                </div>

                <div className="report-container">
                    <Tabs id="reports" activeKey={tab} onSelect={this.onSelectTab}>
                        <Tab eventKey="unaccounted-entries" title={this.getTabTitle('unaccounted-ledger-entries')}>
                            <UnaccountedLedgerEntryContainer/>
                        </Tab>
                        <Tab eventKey="ledger-entries" title={this.getTabTitle('ledger-entries')}>
                            <LedgerEntryContainer/>
                        </Tab>
                        <Tab eventKey="balances" title={this.getTabTitle('balances')}>
                            <BalanceContainer/>
                        </Tab>
                        <Tab eventKey="bank-transactions" title={this.getTabTitle('bank-transactions')}>
                            <BankTransactionContainer/>
                        </Tab>
                    </Tabs>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
    toggleBankTransactionUploadModal: toggleBankTransactionUploadModal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountingContainer));
