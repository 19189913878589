import React, {Component} from "react";
import PropTypes from "prop-types";
import {flowTypeLabel} from "../../common/utils";
import * as _ from "underscore";
import TransactionDateRangePicker from "../../common/components/TransactionDateRangePicker";
import SelectBox from "../../common/components/SelectBox";

export default class AccountFlowAndDateRangeSelector extends Component {

    static propTypes = {
        accounts: PropTypes.array.isRequired,
        flowsByType: PropTypes.object.isRequired,
        onFilterChanged: PropTypes.func.isRequired,
    };

    state = {
        selectedAccount: null,
        selectedFlowType: null,
        selectedFlow: null,
        selectedSubFlowType: null,
        selectedSubFlow: null,
        selectedDateRange: []
    };

    onSelectAccount = (account) => {
        if (account !== this.state.selectedAccount) {
            this.setState({
                selectedAccount: account,
                selectedFlowType: null,
                selectedFlow: null,
                selectedSubFlowType: null,
                selectedSubFlow: null
            })
        }
    };

    onSelectFlowType = (flowType) => {
        if (flowType !== this.state.selectedFlowType) {
            this.setState({
                selectedFlowType: flowType,
                selectedFlow: null,
                selectedSubFlowType: flowType === 'PROPERTY' ? 'RENTAL_AGREEMENT' : null,
                selectedSubFlow: null
            })
        }
    };

    onSelectFlow = (flow) => {
        if (flow !== this.state.selectedFlow) {
            this.setState({
                selectedFlow: flow,
                selectedSubFlow: null
            })
        }
    };

    onSelectSubFlow = (flow) => {
        if (flow !== this.state.selectedSubFlow) {
            this.setState({
                selectedSubFlow: flow
            })
        }
    };

    onDateRangeChanged = (dateRange) => {
        if (dateRange !== this.state.selectedDateRange) {
            this.setState({
                selectedDateRange: dateRange
            })
        }
    };

    onRefresh = () => {
        this.props.onFilterChanged({
            account: this.state.selectedAccount,
            flowType: this.state.selectedFlowType,
            flowId: this.state.selectedFlow ? this.state.selectedFlow.id : null,
            subFlowType: this.state.selectedSubFlowType,
            subFlowId: this.state.selectedSubFlow ? this.state.selectedSubFlow.id : null,
            fromDate: this.state.selectedDateRange ? _.first(this.state.selectedDateRange) : null,
            toDate: this.state.selectedDateRange ? _.last(this.state.selectedDateRange) : null,
        })
    };

    mapFlowType = (flowType) => {
        return {
            value: flowType,
            label: flowTypeLabel(flowType)
        };
    };

    mapFlow = (flow) => {
        return {
            value: flow,
            label: `${flow.id} - ${flow.label}`
        };
    };

    render() {

        const { accounts, flowsByType } = this.props;

        const {
            selectedAccount,
            selectedDateRange,
            selectedFlowType,
            selectedFlow,
            selectedSubFlowType,
            selectedSubFlow
        } = this.state;

        const flows = (selectedFlowType && flowsByType[selectedFlowType]) || [];
        const subFlows = (selectedSubFlowType && selectedFlow && selectedFlow.subFlows && selectedFlow.subFlows[selectedSubFlowType]) || [];

        return (

            <div className="transaction-criteria ledger-entry-selector">

                <SelectBox placeholder="Konto..."
                           clearable={true}
                           values={accounts}
                           selectedValue={selectedAccount}
                           valueMapper={account => ({value: account, label: account.name})}
                           onChange={this.onSelectAccount}/>

                <TransactionDateRangePicker value={selectedDateRange}
                                            onChange={this.onDateRangeChanged}/>

                <SelectBox placeholder="Bezug..."
                           clearable={true}
                           values={_.keys(flowsByType)}
                           selectedValue={selectedFlowType}
                           valueMapper={this.mapFlowType}
                           onChange={this.onSelectFlowType}/>

                {flows.length > 0 &&
                    <SelectBox placeholder={flowTypeLabel(selectedFlowType) + "..."}
                               clearable={true}
                               values={flows}
                               selectedValue={selectedFlow}
                               valueMapper={this.mapFlow}
                               onChange={this.onSelectFlow}/>
                }

                {subFlows.length > 0 &&
                    <SelectBox placeholder={flowTypeLabel(selectedSubFlowType) + "..."}
                               clearable={true}
                               values={subFlows}
                               selectedValue={selectedSubFlow}
                               valueMapper={this.mapFlow}
                               onChange={this.onSelectSubFlow}/>
                }

                <div className="btn-group">
                    <button type="button" className="btn btn-default" onClick={this.onRefresh}>
                        <span className="glyphicon glyphicon-refresh"/>
                    </button>
                </div>

            </div>
        );
    }

}