import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {fetchCurrentUser} from "../actions";

export default function withUser(WrappedComponent, init = false) {

    const userToState = (user) => {
        return {
            currentUser: user,
            permissions: {
                admin: user && user.admin,
                editor: user && user.editor,
                voter: user && user.voter,
                accountant: user && user.accountant
            }
        }
    };

    class UserContainer extends Component {

        constructor(props) {
            super(props);
            this.state = userToState(this.props.currentUser);
        }

        componentDidMount() {
            if (init) {
                this.props.fetchCurrentUser();
            }
        }

        componentDidUpdate(prevProps) {
            if (prevProps.currentUser !== this.props.currentUser) {
                this.setState(userToState(this.props.currentUser));
            }
        }

        render() {

            const { currentUser, permissions } = this.state;

            return (
                <WrappedComponent {...this.props} currentUser={currentUser} permissions={permissions}/>
            );
        }
    }

    const mapStateToProps = state => ({
        currentUser: state.userReducer.currentUser
    });

    const mapDispatchToProps = {
        fetchCurrentUser: fetchCurrentUser
    };

    return connect(mapStateToProps, mapDispatchToProps)(UserContainer);
}
