import React, {Component} from "react";
import PropTypes from "prop-types";
import Modal from "../../common/components/Modal";

export default class AttachmentEditModal extends Component {

    static propTypes = {
        attachment: PropTypes.object.isRequired,
        labels: PropTypes.array.isRequired,
        onClose: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            attachment: Object.assign({}, props.attachment),
        };
    }

    updateState(field, update) {
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            newState.attachment[field] = update;
            return newState;
        });
    };

    render() {

        const { labels } = this.props;

        const { attachment } = this.state;

        return (
            <Modal title="Anhang bearbeiten"
                   closeLabel="Schliessen"
                   onClose={this.props.onClose}
                   submitLabel="Speichern"
                   onSubmit={this.props.onSubmit}
                   data={attachment}
                   classNames="modal-lg">

                <form className="form-horizontal">

                    <FormGroup label="Name">
                        <input type="text"
                               className="form-control"
                               value={attachment.filename || ''}
                               onChange={e => this.updateState('filename', e.target.value)}/>
                    </FormGroup>

                    <FormGroup label="Label">
                        <select className="form-control"
                                value={attachment.label || 'none'}
                                onChange={e => this.updateState('label', e.target.value)}>
                            {labels.map(label =>
                                <option key={`attachment-label-option-${label.value}`} value={label.value}>{label.label}</option>
                            )}
                        </select>
                    </FormGroup>

                </form>

            </Modal>
        );
    }
}

const FormGroup = ({label, children}) => (
    <div className="form-group">
        <label className="col-md-2 control-label">{label}</label>
        <div className="col-sm-10">
            {children}
        </div>
    </div>
);