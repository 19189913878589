import React, {Component} from "react";
import PropTypes from "prop-types";
import * as _ from "underscore";
import {groupRowsByKey} from "../../common/utils";
import PropertyTile from "./PropertyTile";

export default class PropertyList extends Component {

    static propTypes = {
        properties: PropTypes.array.isRequired,
        onPropertySelected: PropTypes.func.isRequired
    };

    render() {

        const id = p => p.fields.city ? p.fields.city + p.fields.streetAndNumber : 'Unbekannter Ort' + p.id;

        const properties = _.sortBy(this.props.properties, id);

        const propertiesByAddress = groupRowsByKey([], properties, id);

        return (
            <div className="row">
                <div className="col-md-12">
                    <ul className="property-list">
                        {propertiesByAddress.map(pair =>
                            <PropertyTile key={`pt-${pair[1][0].id}`}
                                          properties={pair[1]}
                                          onPropertySelected={this.props.onPropertySelected}/>
                        )}
                    </ul>
                </div>
            </div>
        );
    }

}