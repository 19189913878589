import React from 'react';
import {render} from 'react-dom';
import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from "./reducers";
import RescoreApp from "./RescoreApp";
import {BrowserRouter} from "react-router-dom";
import Provider from "react-redux/es/components/Provider";
import './styles/rescore.css';
import thunk from "redux-thunk";
import './i18n';
import {httpClient} from "./modules/common/request";
import {logout} from "./modules/user/actions";
import 'rsuite/dist/styles/rsuite-default.css';
import {config} from "./modules/common/config";
import LoginForm from "./LoginForm";

const store = createStore(
    rootReducer,
    compose(applyMiddleware(thunk))
);

httpClient.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response.status === 401) {
            store.dispatch(logout())
        }
        return Promise.reject(error);
    }
);

function showApp(signedIn) {
    if (signedIn) {
        render(
            <Provider store={store}>
                <BrowserRouter>
                    <RescoreApp signedIn={signedIn}/>
                </BrowserRouter>
            </Provider>,
            document.getElementById('root')
        );
    } else {
        render(
            <LoginForm/>,
            document.getElementById('root')
        );
        window.auth2.attachClickHandler(document.querySelector('#loginButton'), {}, console.log);
    }
}

window.gapi.load('auth2', () => {
    window.auth2 = window.gapi.auth2.init({
        client_id: config.GOOGLE_CLIENT_ID,
    });
    window.auth2.isSignedIn.listen(showApp);
    window.auth2.then(auth2 => showApp(auth2.isSignedIn.get()));
});


