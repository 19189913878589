import React, {Component} from "react";
import PropTypes from "prop-types";
import {formatAmount, formatDate} from "../../common/utils";

export default class LedgerEntryList extends Component {

    static propTypes = {
        account: PropTypes.object.isRequired,
        entriesByDate: PropTypes.array.isRequired,
        debitAmount: PropTypes.number.isRequired,
        creditAmount: PropTypes.number.isRequired,
        onEntrySelected: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            account: props.account,
            entriesByDate: props.entriesByDate,
            debitAmount: props.debitAmount,
            creditAmount: props.creditAmount,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.entriesByDate !== this.props.entriesByDate) {
            this.setState({
                entriesByDate: this.props.entriesByDate,
                debitAmount: this.props.debitAmount,
                creditAmount: this.props.creditAmount
            });
        }
        if (prevProps.account !== this.props.account) {
            this.setState({
                account: this.props.account
            });
        }
    }

    renderDateRow = (date) => {
        return (
            <tr className="transaction-date-header">
                <th className="text-left text-primary" style={{width: "50px"}}>{formatDate(parseInt(date), 'DD.MM.YYYY')}</th>
                <th className="ruler" colSpan="3" >
                    <div/>
                </th>
            </tr>
        )
    };

    isAsset = () => {
        const { account } = this.state;
        return !account || account.routingKey === 'CASH'
    };

    renderRow = (entry) => {
        const asset = this.isAsset();
        return (
            <tr key={`bt-${entry.id}`} className="transaction-row" style={{fontSize: "12px"}}>
                <td className="text-right">
                    {entry.id}
                </td>
                <td className="text-left text-muted" style={{cursor: "pointer"}} onClick={() => this.props.onEntrySelected(entry)}>
                    {entry.description || 'Keine Angabe'}
                </td>
                <td className="text-right" style={{width: "70px"}}>
                    {((asset && entry.amount >= 0) || (!asset && entry.amount <  0)) && <span>{formatAmount(entry.amount)}</span>}
                </td>
                <td className="text-right text-red" style={{width: "70px"}}>
                    {((asset && entry.amount <  0) || (!asset && entry.amount >= 0)) && <span>{formatAmount(entry.amount)}</span>}
                </td>
            </tr>
        );
    };

    render() {

        const { entriesByDate, debitAmount, creditAmount } = this.state;

        const asset = this.isAsset();

        const balance = debitAmount + creditAmount;

        return (
            <table className="transaction-table table table-hover" style={{marginBottom: "5px"}}>
                <thead style={{fontSize: "12px"}}>
                    <tr>
                        <th className="text-left">Bilanz</th>
                        <th/>
                        <th className="text-right" style={{width: "70px"}}>
                            {((asset && balance >= 0) || (!asset && balance <  0)) && formatAmount(balance)}
                        </th>
                        <th className="text-right text-red" style={{width: "70px"}}>
                            {((asset && balance <  0) || (!asset && balance >= 0)) && formatAmount(balance)}
                        </th>
                    </tr>
                    <tr>
                        <th className="text-left">Umsatz</th>
                        <th/>
                        <th className="text-right" style={{width: "70px"}}>{formatAmount(asset ? creditAmount : debitAmount)}</th>
                        <th className="text-right text-red" style={{width: "70px"}}>{formatAmount(asset ? debitAmount : creditAmount)}</th>
                    </tr>
                </thead>
                {entriesByDate.map((dateAndEntries, idx) =>
                    <tbody key={`dabt-${idx}`} style={{fontSize: "12px"}}>
                        {this.renderDateRow(dateAndEntries[0])}
                        {dateAndEntries[1].map(this.renderRow)}
                    </tbody>
                )}
            </table>
        );
    }


}