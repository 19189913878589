import * as API from "../common/api";
import {action} from "../common/utils";

export const ACCOUNTING = "ACCOUNTING";

export const TOGGLE_BANK_TRANSACTION_UPLOAD_MODAL = 'TOGGLE_BANK_TRANSACTION_UPLOAD_MODAL';

export const BANK_ACCOUNTS_RECEIVED = 'BANK_ACCOUNTS_RECEIVED';
export const BANK_ACCOUNT_UPDATED = 'BANK_ACCOUNT_UPDATED';

export const BANK_TRANSACTIONS_RECEIVED = 'BANK_TRANSACTIONS_RECEIVED';
export const BANK_TRANSACTIONS_UPDATED = 'BANK_TRANSACTIONS_UPDATED';

export const ACCOUNTS_RECEIVED = 'ACCOUNTS_RECEIVED';
export const ACCOUNT_UPDATED = 'ACCOUNT_UPDATED';

export const FLOWS_RECEIVED = 'FLOWS_RECEIVED';

export const LEDGER_ENTRIES_RECEIVED = 'LEDGER_ENTRIES_RECEIVED';

export const UNACCOUNTED_LEDGER_ENTRIES_RECEIVED = 'UNACCOUNTED_LEDGER_ENTRIES_RECEIVED';
export const UNACCOUNTED_LEDGER_ENTRIES_UPDATED = 'UNACCOUNTED_LEDGER_ENTRIES_UPDATED';

export const BALANCES_RECEIVED = 'BALANCES_RECEIVED';

export const TRANSACTION_RECEIVED = 'TRANSACTION_RECEIVED';

export const TRANSACTION_TEMPLATE_RECEIVED = 'TRANSACTION_TEMPLATE_RECEIVED';

export function toggleBankTransactionUploadModal(show) {
    return function(dispatch) {
        dispatch(action({ scope: ACCOUNTING, type: TOGGLE_BANK_TRANSACTION_UPLOAD_MODAL, show: show }));
    }
}

export function fetchBankAccounts() {
    return function(dispatch) {
        API.fetchBankAccounts()
            .then(response => dispatch(action({ scope: ACCOUNTING, type: BANK_ACCOUNTS_RECEIVED, accounts: response.data })));
    }
}

export function createBankAccount(account) {
    return function(dispatch) {
        API.createBankAccount(account)
            .then(() => {
                dispatch(action({ scope: ACCOUNTING, type: BANK_ACCOUNT_UPDATED }));
            })
            .catch(console.log);
    }
}

export function updateBankAccount(account) {
    return function(dispatch) {
        API.updateBankAccount(account)
            .then(() => {
                dispatch(action({ scope: ACCOUNTING, type: BANK_ACCOUNT_UPDATED }));
            })
            .catch(console.log);
    }
}

export function deleteBankAccount(account) {
    return function(dispatch) {
        API.deleteBankAccount(account.id)
            .then(() => {
                dispatch(action({ scope: ACCOUNTING, type: BANK_ACCOUNT_UPDATED }));
            })
            .catch(console.log);
    }
}

export function fetchBankTransactionsByValueDate(bankAccountId, fromDate, toDate, pageIndex, pageSize) {
    return function(dispatch) {
        API.fetchBankTransactionsByValueDate(bankAccountId, fromDate, toDate, pageIndex, pageSize)
            .then(response => dispatch(action({scope: ACCOUNTING, type: BANK_TRANSACTIONS_RECEIVED, page: response.data})));
    }
}

export function uploadBankStatement(statementType, bankAccountId, file, onUploadProgress, onSuccess, onError) {
    return function(dispatch) {
        API.uploadBankStatement(statementType, bankAccountId, file, onUploadProgress)
            .then(() => {
                dispatch(action({ scope: ACCOUNTING, type: BANK_TRANSACTIONS_UPDATED }));
                onSuccess();
            })
            .catch(onError);
    }
}

export function fetchAccounts() {
    return function(dispatch) {
        API.fetchAccounts()
            .then(response => dispatch(action({ scope: ACCOUNTING, type: ACCOUNTS_RECEIVED, accounts: response.data })));
    }
}

export function createAccount(account) {
    return function(dispatch) {
        API.createAccount(account)
            .then(() => {
                dispatch(action({ scope: ACCOUNTING, type: ACCOUNT_UPDATED }));
            })
            .catch(console.log);
    }
}

export function updateAccount(account) {
    return function(dispatch) {
        API.updateAccount(account)
            .then(() => {
                dispatch(action({ scope: ACCOUNTING, type: ACCOUNT_UPDATED }));
            })
            .catch(console.log);
    }
}

export function deleteAccount(account) {
    return function(dispatch) {
        API.deleteAccount(account.id)
            .then(() => {
                dispatch(action({ scope: ACCOUNTING, type: ACCOUNT_UPDATED }));
            })
            .catch(console.log);
    }
}

export function fetchFlows() {
    return function(dispatch) {
        API.fetchFlows()
            .then(response => dispatch(action({ scope: ACCOUNTING, type: FLOWS_RECEIVED, flowsByType: response.data })));
    }
}

export function fetchLedgerEntries(accountId, flowType, flowId, subFlowType, subFlowId, fromDate, toDate, pageIndex, pageSize) {
    return function(dispatch) {
        API.fetchLedgerEntries(accountId, flowType, flowId, subFlowType, subFlowId, fromDate, toDate, pageIndex, pageSize)
            .then(response => dispatch(action({scope: ACCOUNTING, type: LEDGER_ENTRIES_RECEIVED, page: response.data})));
    }
}

export function fetchUnaccountedLedgerEntries() {
    return function(dispatch) {
        API.fetchUnaccountedLedgerEntries()
            .then(response => dispatch(action({scope: ACCOUNTING, type: UNACCOUNTED_LEDGER_ENTRIES_RECEIVED, page: response.data})));
    }
}

export function fetchBalances(flowType, flowId, subFlowType, subFlowId, fromDate, toDate) {
    return function(dispatch) {
        API.fetchBalances(flowType, flowId, subFlowType, subFlowId, fromDate, toDate)
            .then(response => dispatch(action({scope: ACCOUNTING, type: BALANCES_RECEIVED, balances: response.data})));
    }
}

export function fetchTransaction(id) {
    return function(dispatch) {
        API.fetchTransaction(id)
            .then(response => dispatch(action({scope: ACCOUNTING, type: TRANSACTION_RECEIVED, transactionEntries: response.data})));
    }
}

export function createTransaction(transaction, onSuccess, onError) {
    return function(dispatch) {
        API.createTransaction(transaction)
            .then(response => {
                dispatch(action({ scope: ACCOUNTING, type: UNACCOUNTED_LEDGER_ENTRIES_UPDATED }));
                onSuccess(response.data);
            })
            .catch(onError);
    }
}

export function updateTransaction(id, transaction, onSuccess, onError) {
    return function(dispatch) {
        API.updateTransaction(id, transaction)
            .then(response => {
                dispatch(action({ scope: ACCOUNTING, type: UNACCOUNTED_LEDGER_ENTRIES_UPDATED }));
                onSuccess(response.data);
            })
            .catch(onError);
    }
}

export function revertTransaction(id, onSuccess, onError) {
    return function(dispatch) {
        API.revertTransaction(id)
            .then(response => {
                dispatch(action({ scope: ACCOUNTING, type: UNACCOUNTED_LEDGER_ENTRIES_UPDATED }));
                onSuccess(response.data);
            })
            .catch(onError);
    }
}

export function fetchTransactionTemplate(name, transactionId, flowType, flowId, subFlowType, subFlowI) {
    return function(dispatch) {
        API.fetchTransactionTemplate(name, transactionId, flowType, flowId, subFlowType, subFlowI)
            .then(response => dispatch(action({scope: ACCOUNTING, type: TRANSACTION_TEMPLATE_RECEIVED, template: response.data})));
    }
}
