import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Modal from "../../common/components/Modal";
import AttachmentEditModal from "./AttachmentEditModal";
import withUser from "../../user/components/withUser";
import Dropzone from "react-dropzone";
import {groupBy, sortBy} from "underscore";
import {downloadFile} from "../../common/request";

class AttachmentPanel extends Component {

    static propTypes = {
        attachments: PropTypes.array.isRequired,
        onUploadAttachment: PropTypes.func.isRequired,
        onUpdateAttachment: PropTypes.func.isRequired,
        onDeleteAttachment: PropTypes.func.isRequired
    };

    labels = [
        {value: "Verträge", label: "Verträge"},
        {value: "Dokumente", label: "Dokumente"},
        {value: "Abrechnungen", label: "Abrechnungen"},
        {value: "Abrechnung WEG", label: "Abrechnung WEG"},
        {value: "Abrechnung Mieter", label: "Abrechnung Mieter"},
        {value: "Andere", label: "Andere"},
        {value: "none", label: "Ohne Label"}
    ];

    constructor(props) {
        super(props);
        this.state = {
            attachments:  sortBy(props.attachments, 'filename'),
            showEditModal: false,
            showDeleteModal: false,
            selectedAttachment: null,
            currentFileName: '',
            showProgress: false,
            progress: 0
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.attachments !== this.props.attachments) {
            this.setState({ attachments: sortBy(this.props.attachments, 'filename') });
        }
    }

    setAttachmentInput = (element) => {
        this.attachmentInput = element;
    };

    onOpenFileSelector = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.attachmentInput.click()
    };

    onUploadAttachments = (files) => {
        files.forEach(file => {
            this.setState({
                currentFileName: file.name,
                showProgress: true,
                progress: 0
            }, () => this.props.onUploadAttachment(file, this.onUploadProgress));
        });
    };

    onUploadProgress = (event) => {
        this.setState({progress: (event.loaded / event.total) * 100});
        setTimeout(() => {
            this.setState(prevState => {
                return Object.assign({}, prevState, { showProgress: prevState.progress < 100 });
            });
        }, 1000);
    };

    onShowEditModal = (event, attachment) => {
        event.preventDefault();
        this.setState({
            showEditModal: true,
            selectedAttachment: attachment
        })
    };

    onShowDeleteModal = (event, attachment) => {
        event.preventDefault();
        this.setState({
            showDeleteModal: true,
            selectedAttachment: attachment
        })
    };

    onCloseModals = () => {
        this.setState({
            showEditModal: false,
            showDeleteModal: false,
            selectedAttachment: null
        });
    };

    downloadAttachment = (attachment) => {
        downloadFile(`/api/attachments/${attachment.id}`)
    };


    renderRow = (attachment) => {
        const { permissions } = this.props;
        return (
            <tr key={`attachment-row-${attachment.id}`}>
                <td className="text-left" width="85%">
                    <a href="" onClick={e => {e.preventDefault(); this.downloadAttachment(attachment);}}>{attachment.filename}</a>
                </td>
                {permissions.editor &&
                    <td className="text-center" width="15%">
                        <a href="" onClick={e => this.onShowEditModal(e, attachment)}>
                            <i className="glyphicon glyphicon-edit mr-5"/>
                        </a>
                        <a href="" onClick={e => this.onShowDeleteModal(e, attachment)}>
                            <i className="glyphicon glyphicon-trash ml-5"/>
                        </a>
                    </td>
                }
            </tr>
        );
    };

    renderTable = (label, attachments) => {
        return (
            <table key={`attachment-table-${label.value}`} className="table table-condensed" style={{marginBottom: "5px"}}>
                <thead>
                <tr>
                    <td colSpan="2">{label.label} ({attachments.length})</td>
                </tr>
                </thead>
                <tbody>
                    {attachments.map(this.renderRow)}
                </tbody>
            </table>
        );
    };

    render() {

        const { permissions } = this.props;

        const {
            attachments,
            showEditModal,
            showDeleteModal,
            selectedAttachment,
            currentFileName,
            showProgress,
            progress
        } = this.state;

        const attachmentsByLabel = groupBy(attachments, a => a.label ? a.label : 'none');

        return (
            <Fragment>
                <Dropzone onDrop={this.onUploadAttachments} noClick={true}>
                    {({getRootProps, getInputProps}) => (
                        <div className="panel panel-default" {...getRootProps()}>
                            <div className="panel-heading">
                                <span className="glyphicon glyphicon-paperclip"/> Anhänge ({attachments.length})
                                <div className="pull-right">
                                    {permissions.editor &&
                                    <a href="" onClick={this.onOpenFileSelector}>
                                        <i className="glyphicon glyphicon-plus"/> Anhang hinzufügen
                                    </a>
                                    }
                                </div>
                            </div>
                            <div className="panel-body">

                                {showProgress &&
                                <div className="progress" style={{marginBottom: "0px", marginTtop: "10px"}}>
                                    <div className="progress-bar" role="progressbar" style={{ width : `${progress}%`}}>
                                        {currentFileName + ' - ' + progress + '%'}
                                    </div>
                                </div>
                                }

                                {permissions.editor &&
                                <input type="file" {...getInputProps()} ref={this.setAttachmentInput}/>
                                }
                                {permissions.editor &&
                                <p className="text-center text-muted"><small>Tipp: Anhänge zum Hochladen hier ablegen</small></p>
                                }
                                {this.labels.filter(label => attachmentsByLabel[label.value] && attachmentsByLabel[label.value].length > 0).map(label =>
                                    this.renderTable(label, attachmentsByLabel[label.value])
                                )}
                            </div>
                        </div>
                    )}
                </Dropzone>


                {showEditModal &&
                    <AttachmentEditModal attachment={selectedAttachment}
                                         labels={this.labels}
                                         onSubmit={this.props.onUpdateAttachment}
                                         onClose={this.onCloseModals}/>
                }

                {showDeleteModal &&
                    <Modal title="Anhang löschen"
                           closeLabel="Abbrechen"
                           onClose={this.onCloseModals}
                           submitLabel="Löschen"
                           onSubmit={this.props.onDeleteAttachment}
                           data={selectedAttachment}
                           classNames="modal-md">
                        Anhang '{selectedAttachment.filename}' wirklich löschen?
                    </Modal>
                }
            </Fragment>
        )
    }
}

export default withUser(AttachmentPanel)
