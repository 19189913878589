import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import withUser from "../../user/components/withUser";
import ImageCollection from "../components/ImageCollection";
import {
    createImageCollection,
    deleteImage,
    deleteImageCollection,
    fetchImageCollections,
    fetchImageDescriptors,
    fetchProperties,
    updateImageCollection,
    uploadImage
} from "../actions";
import ImageFilter from "../components/ImageFilter";
import CollectionEditModal from "../components/CollectionEditModal";
import {SORT_ORDER_AZ, SORT_ORDER_UNDEFINED, SORT_ORDER_ZA, sortAndFilterImageCollections} from "../../common/image";
import PropTypes from "prop-types";

const ContextMenu = ({sortOrder, onSortOrderChanged, onCreateCollection, permissions}) => (
    <div className="context-menu hidden-print">
        <ol className="breadcrumb display-inline">
            <li>Bilder</li>
        </ol>
        <ul className="nav nav-pills pull-right">
            <li>
                <a href="" onClick={onSortOrderChanged}>
                    {sortOrder === SORT_ORDER_AZ ? (
                        <i className="glyphicon glyphicon-sort-by-alphabet-alt"/>
                    ) : (
                        <i className="glyphicon glyphicon-sort-by-alphabet"/>
                    )}
                    <label>Sortieren</label>
                </a>
            </li>
            {permissions.editor &&
            <li>
                <a href="" onClick={onCreateCollection}>
                    <i className="glyphicon glyphicon-plus"/><label>Neues Album</label>
                </a>
            </li>
            }
        </ul>
    </div>
);


class ImageContainer extends Component {

    static propTypes = {
        collectionId: PropTypes.number
    };

    constructor(props) {
        super(props);
        this.state = {
            collections: [],
            filteredCollections: [],
            filterText: '',
            sortOrder: SORT_ORDER_UNDEFINED,
            collectionsDirty: false,
            descriptorsByCollectionId: {},
            propertiesById: {},
            showCollectionCreateModal: false
        };
    }

    componentDidMount() {
        this.fetchCollections();
        this.fetchDescriptors();
        this.fetchProperties();
    }

    componentDidUpdate(prevProps) {

        const newState = {};

        if (prevProps.collections !== this.props.collections) {
            let filterText = this.state.filterText;
            if (filterText === '' && this.props.collectionId) {
                const col = this.props.collections.find(col => col.id === this.props.collectionId);
                if (col ) {
                    filterText = col.name;
                    newState['filterText'] = filterText;
                }
            }
            newState['collections'] = this.props.collections;
            newState['filteredCollections'] = sortAndFilterImageCollections(this.props.collections, this.state.sortOrder, filterText)
        }

        if (prevProps.descriptorsByCollectionId !== this.props.descriptorsByCollectionId) {
            newState['descriptorsByCollectionId'] = this.props.descriptorsByCollectionId;
        }

        if (prevProps.propertiesById !== this.props.propertiesById) {
            newState['propertiesById'] = this.props.propertiesById;
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }

        if (!prevProps.collectionsDirty && this.props.collectionsDirty) {
            this.componentDidMount();
        }
    }

    fetchCollections = () => {
        this.props.fetchImageCollections();
    };

    fetchDescriptors = () => {
        this.props.fetchImageDescriptors();
    };

    fetchProperties = () => {
        this.props.fetchProperties();
    };

    onFilterChanged = (filterText) => {
        this.setState(prevState => {
            return {
                filteredCollections: sortAndFilterImageCollections(prevState.collections, prevState.sortOrder, filterText),
                filterText: filterText
            }
        });
    };

    onSortOrderChanged = (event) => {
        event.preventDefault();
        this.setState(prevState => {
            const sortOrder = prevState.sortOrder === SORT_ORDER_AZ ? SORT_ORDER_ZA : SORT_ORDER_AZ;
            return {
                filteredCollections: sortAndFilterImageCollections(prevState.collections, sortOrder, prevState.filterText),
                sortOrder: sortOrder
            }
        });
    };

    onCreateCollection = (collection, onSuccess, onError) => {
        this.props.createImageCollection(collection, onSuccess, onError);
    };

    onUpdateCollection = (collection, onSuccess, onError) => {
        this.props.updateImageCollection(collection, onSuccess, onError);
    };

    onDeleteCollection = (collection, onSuccess, onError) => {
        this.props.deleteImageCollection(collection.id, onSuccess, onError);
    };

    onUploadImage = (collection, file, onUploadProgress, onSuccess, onError) => {
        this.props.uploadImage(collection.id, file, onUploadProgress, onSuccess, onError);
    };

    onDeleteImage = (collection, descriptor, onSuccess, onError) => {
        this.props.deleteImage(collection.id, descriptor.id, onSuccess, onError);
    };

    onShowCollectionCreateModal = (event) => {
        event.preventDefault();
        this.setState({ showCollectionCreateModal: true });
    };

    onCloseModals = () => {
        this.setState({
            showCollectionCreateModal: false
        });
    };

    render() {

        const { permissions } = this.props;

        const {
            filteredCollections,
            filterText,
            reverse,
            descriptorsByCollectionId,
            propertiesById,
            showCollectionCreateModal
        } = this.state;

        return (
            <div>

                <ContextMenu reverse={reverse}
                             permissions={permissions}
                             onCreateCollection={this.onShowCollectionCreateModal}
                             onSortOrderChanged={this.onSortOrderChanged}/>

                <ImageFilter value={filterText}
                             onFilterChanged={this.onFilterChanged}/>

                {filteredCollections && descriptorsByCollectionId && propertiesById && filteredCollections.map(col =>
                    <ImageCollection key={`image-collection-${col.id}`}
                                     collection={col}
                                     descriptors={descriptorsByCollectionId[col.id] || []}
                                     propertiesById={propertiesById}
                                     onUploadImage={this.onUploadImage}
                                     onDeleteImage={this.onDeleteImage}
                                     onUpdateCollection={this.onUpdateCollection}
                                     onDeleteCollection={this.onDeleteCollection}/>
                )}

                {showCollectionCreateModal &&
                    <CollectionEditModal title="Neues Album anlegen"
                                         collection={{
                                             name : "",
                                             description : "",
                                             propertyIds : []
                                         }}
                                         propertiesById={propertiesById}
                                         onClose={this.onCloseModals}
                                         onSubmit={this.onCreateCollection}/>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    collections: state.imageReducer.collections,
    collectionsDirty: state.imageReducer.collectionsDirty,
    descriptorsByCollectionId: state.imageReducer.descriptorsByCollectionId,
    propertiesById: state.imageReducer.propertiesById
});

const mapDispatchToProps = {
    fetchProperties: fetchProperties,
    fetchImageDescriptors: fetchImageDescriptors,
    fetchImageCollections: fetchImageCollections,
    createImageCollection: createImageCollection,
    updateImageCollection: updateImageCollection,
    deleteImageCollection: deleteImageCollection,
    uploadImage: uploadImage,
    deleteImage: deleteImage,

};

export default withUser(connect(mapStateToProps, mapDispatchToProps)(ImageContainer));

