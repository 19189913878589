import * as API from "../common/api";
import {action} from "../common/utils";

export const USER = "USER";

export const CURRENT_USER_RECEIVED = 'CURRENT_USER_RECEIVED';
export const CURRENT_USER_REMOVED = 'CURRENT_USER_REMOVED';

export const USERS_RECEIVED = 'USERS_RECEIVED';
export const USER_UPDATED = 'USER_UPDATED';

export function fetchCurrentUser() {
    return function(dispatch) {
        API.fetchCurrentUser()
            .then(response => dispatch(action({ scope: USER, type: CURRENT_USER_RECEIVED, user: response.data })));
    }
}

export function logout() {
    return function(dispatch) {
        dispatch(action({ scope: USER, type: CURRENT_USER_REMOVED }));
    }
}

export function fetchUsers() {
    return function(dispatch) {
        API.fetchUsers()
            .then(response => dispatch(action({ scope: USER, type: USERS_RECEIVED, users: response.data })));
    }
}

export function createUser(user) {
    return function(dispatch) {
        API.createUser(user)
            .then(() => {
                dispatch(action({ scope: USER, type: USER_UPDATED }));
            })
            .catch(console.log);
    }
}

export function updateUser(user) {
    return function(dispatch) {
        API.updateUser(user)
            .then(() => {
                dispatch(action({ scope: USER, type: USER_UPDATED }));
            })
            .catch(console.log);
    }
}

export function deleteUser(user) {
    return function(dispatch) {
        API.deleteUser(user.id)
            .then(() => {
                dispatch(action({ scope: USER, type: USER_UPDATED }));
            })
            .catch(console.log);
    }
}
