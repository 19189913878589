import React from 'react';
import PropTypes from 'prop-types';

const ErrorComponent = ({ error, ...props }) => {
  console.error(props);
  console.error('Error Component Log:', error);
  return (
    <div className="row">
      <div className="col-xs-12 text-xs-center m-t-5">
        <h2>Aww :(</h2>
        <pre className="text-danger" style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
          {JSON.stringify(
            error,
            Object.getOwnPropertyNames(error),
            2,
          )}
        </pre>
        <p className="text-muted">We are royally screwed.</p>
      </div>
    </div>
  );
};

ErrorComponent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Error),
    PropTypes.object,
  ]).isRequired,
};

export default ErrorComponent;
