import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {createProperty, exportProperties, fetchProperties, fetchPropertyGroups} from "../actions";
import Modal from "../../common/components/Modal";
import {withRouter} from "react-router";
import PropertyList from "../components/PropertyList";
import {googleUser} from "../../common/utils";
import NavLink from "../../common/components/NavLink";

const ContextMenu = ({properties, propertyGroup, onCreateProperty, onExport}) => (
    <div className="context-menu hidden-print">
        <ol className="breadcrumb display-inline">
            <li><NavLink path={`/property-groups`}>Objektgruppen</NavLink></li>
            <li>{propertyGroup.name} ({properties.length} {(properties.length === 1 ? 'Objekt' : 'Objekte')})</li>
        </ol>
        <ul className="nav nav-pills pull-right">
            <li>
                <a href="" onClick={onCreateProperty}><i className="glyphicon glyphicon-plus"/><label>Neues Objekt</label></a>
            </li>
            <li>
                <a href="" onClick={onExport}><i className="glyphicon glyphicon-export"/><label>Google Sheets</label></a>
            </li>
        </ul>
    </div>
);

class PropertyListContainer extends Component {

    static propTypes = {
        groupId: PropTypes.number.isRequired
    };

    state = {
        properties: [],
        showConfirmCreatePropertyModal: false,
        propertyGroups: []
    };

    componentDidMount() {
        this.props.fetchProperties(this.props.groupId);
        this.props.fetchPropertyGroups();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.properties !== this.props.properties) {
            this.setState({ properties: this.props.properties })
        }
        if (prevProps.groupId !== this.props.groupId) {
            this.componentDidMount();
        }
        if (prevProps.propertyGroups !== this.props.propertyGroups) {
            this.setState({ propertyGroups: this.props.propertyGroups })
        }
    }

    onExport = (event) => {
        event.preventDefault();
        const user = googleUser();
        user.reloadAuthResponse();
        user.grant({scope: 'https://www.googleapis.com/auth/drive'});
        this.props.exportProperties(user.getAuthResponse().access_token, this.props.groupId);
    };

    onShowConfirmCreatePropertyModal = (event) => {
        event.preventDefault();
        this.setState({ showConfirmCreatePropertyModal: true })
    };

    onCloseConfirmCreatePropertyModal = () => {
        this.setState({ showConfirmCreatePropertyModal: false });
    };

    onCreateProperty = () => {

        const property = {
            groupId: this.props.groupId,
            published: false
        };

        this.props.createProperty(property, dto => {
            this.onCloseConfirmCreatePropertyModal();
            this.props.history.push(`/properties/${dto.id}`)
        });
    };


    onPropertySelected = (property) => {
        this.props.history.push(`/properties/${property.id}`)
    };

    render() {

        const { groupId } = this.props;

        const { properties, showConfirmCreatePropertyModal, propertyGroups } = this.state;

        const propertyGroup = (propertyGroups && propertyGroups.find(g => g.id === groupId)) || { name: 'Unbekannter Ordner' };

        return (
            <Fragment>

                <ContextMenu properties={properties}
                             propertyGroup={propertyGroup}
                             onCreateProperty={this.onShowConfirmCreatePropertyModal}
                             onExport={this.onExport}/>


                <PropertyList properties={properties}
                              onPropertySelected={this.onPropertySelected}/>


                {showConfirmCreatePropertyModal &&
                    <Modal title="Bestätigung"
                           closeLabel="Abbrechen"
                           onClose={this.onCloseConfirmCreatePropertyModal}
                           submitLabel="Erstellen"
                           onSubmit={this.onCreateProperty}
                           data={{}}
                           classNames="modal-md">
                        Neues Objekt erstellen?
                    </Modal>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    properties: state.propertyGroupReducer.properties,
    propertyGroups: state.propertyGroupReducer.propertyGroups
});

const mapDispatchToProps = {
    fetchProperties: fetchProperties,
    exportProperties: exportProperties,
    createProperty: createProperty,
    fetchPropertyGroups: fetchPropertyGroups
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PropertyListContainer));

