import React, {Component} from "react";
import PropTypes from "prop-types";

export default class CommentForm extends Component {

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        value: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : ''
        }
    }

    onChange = (event) => {
        this.setState({value: event.target.value});
    };

    onSubmit = (event) => {
        event.preventDefault();
        this.setState({value: ''});
        this.props.onSubmit(this.state.value);
    };

    render() {
        return (
            <form className="hidden-print" style={this.props.style}>
                <div className="form-group">
                <textarea className="form-control" rows="3" placeholder="Kommentar hinterlassen"
                          style={{fontSize: "12px"}} value={this.state.value} onChange={this.onChange}/>
                </div>
                <div style={{textAlign: "right"}}>
                    <button type="submit" className="btn btn-default btn-sm" onClick={this.onSubmit}>Abschicken</button>
                </div>
            </form>
        );
    }
}