import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {createUser, deleteUser, fetchUsers, updateUser} from "../actions";
import withUser from "../components/withUser";
import CrudTable from "../../common/components/CrudTable";

class UserContainer extends Component {

    state = {
        rows: []
    };

    columns = [];

    componentDidMount() {

        const { permissions } = this.props;

        this.columns = [
            { key: 'id',         label: '#',             type: 'number', editable: false },
            { key: 'firstName',  label: 'Vorname',       type: 'text',   editable: permissions.admin },
            { key: 'lastName',   label: 'Nachname',      type: 'text',   editable: permissions.admin },
            { key: 'email',      label: 'E-Mail',        type: 'text',   editable: permissions.admin },
            { key: 'admin',      label: 'Administrator', type: 'bool',   editable: permissions.admin },
            { key: 'editor',     label: 'Ändern',        type: 'bool',   editable: permissions.admin },
            { key: 'voter',      label: 'Abstimmen',     type: 'bool',   editable: permissions.admin },
            { key: 'accountant', label: 'Buchhaltung',   type: 'bool',   editable: permissions.admin },
            { key: 'locked',     label: 'Gesperrt',      type: 'bool',   editable: permissions.admin }
        ];

        this.props.fetchRows();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.rows !== this.props.rows) {
            this.setState({ rows: this.props.rows });
        }
        if (!prevProps.dirty && this.props.dirty) {
            this.props.fetchRows();
        }
    }

    render() {
        return (
            <CrudTable rows={this.state.rows}
                       columns={this.columns}
                       onRowCreated={this.props.createRow}
                       onRowUpdated={this.props.updateRow}
                       onRowDeleted={this.props.deleteRow}/>
        );
    }
}

const mapStateToProps = state => ({
    rows: state.userReducer.users,
    dirty: state.userReducer.usersDirty
});

const mapDispatchToProps = {
    fetchRows: fetchUsers,
    createRow: createUser,
    updateRow: updateUser,
    deleteRow: deleteUser
};

export default withUser(connect(mapStateToProps, mapDispatchToProps)(UserContainer));

