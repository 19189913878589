import {
    ACTIVITIES_RECEIVED,
    ATTACHMENT_UPDATED,
    ATTACHMENTS_RECEIVED,
    COMMENT_UPDATED,
    IMAGE_COLLECTIONS_RECEIVED,
    IMAGE_DESCRIPTORS_RECEIVED,
    LIKE_UPDATED,
    LIKES_RECEIVED,
    PROPERTY,
    PROPERTY_FIELD_UPDATED,
    PROPERTY_RECEIVED,
    PROPERTY_UPDATED,
    RENTAL_AGREEMENT_UPDATED,
    RENTAL_AGREEMENTS_RECEIVED
} from "./actions";

const initialState = {

    property: null,
    propertyDirty: false,

    updatedFields: {},

    likes: [],
    likesDirty: false,

    activities: [],
    activitiesDirty: false,

    attachments: [],
    attachmentsDirty: false,

    rentalAgreements: [],
    rentalAgreementsDirty: false,

    collections: [],

    imageDescriptors: [],

    dirty: false,
};

function propertyReducer(state = initialState, action) {

    if (action.scope !== PROPERTY) {
        return state;
    }

    switch (action.type) {

        case PROPERTY_RECEIVED:
            return Object.assign({}, state, {
                property: action.property,
                propertyDirty: false,
                updatedFields: {},
                dirty: false
            });

        case PROPERTY_FIELD_UPDATED:
            const newState = Object.assign({}, state);
            if (newState.property && newState.property.fields[action.field] !== action.value) {
                newState.updatedFields[action.field] = action.value;
            } else {
                delete newState.updatedFields[action.field];
            }
            newState.dirty = Object.keys(newState.updatedFields).length > 0;
            return newState;

        case PROPERTY_UPDATED:
            return Object.assign({}, state, {
                propertyDirty: true,
                activitiesDirty: true
            });

        case LIKES_RECEIVED:
            return Object.assign({}, state, {
                likes: action.likes,
                likesDirty: false
            });

        case LIKE_UPDATED:
            return Object.assign({}, state, {
                likesDirty: true,
                activitiesDirty: true
            });

        case ACTIVITIES_RECEIVED:
            return Object.assign({}, state, {
                activities: action.activities,
                activitiesDirty: false
            });

        case COMMENT_UPDATED:
            return Object.assign({}, state, {
                activitiesDirty: true
            });

        case ATTACHMENTS_RECEIVED:
            return Object.assign({}, state, {
                attachments: action.attachments,
                attachmentsDirty: false
            });

        case ATTACHMENT_UPDATED:
            return Object.assign({}, state, {
                attachmentsDirty: true,
                activitiesDirty: true
            });

        case RENTAL_AGREEMENTS_RECEIVED:
            return Object.assign({}, state, {
                rentalAgreements: action.rentalAgreements,
                rentalAgreementsDirty: false
            });

        case RENTAL_AGREEMENT_UPDATED:
            return Object.assign({}, state, {
                rentalAgreementsDirty: true,
                activitiesDirty: true
            });

        case IMAGE_COLLECTIONS_RECEIVED:
            return Object.assign({}, state, {
                collections: action.collections
            });

        case IMAGE_DESCRIPTORS_RECEIVED:
            return Object.assign({}, state, {
                imageDescriptors: action.imageDescriptors
            });

        default:
            return state;
    }
}

export default propertyReducer;
