import React, {Component} from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import SelectBox from "../../common/components/SelectBox";
import * as _ from "underscore";

export default class BankStatementUploadModal extends Component {

    static propTypes = {
        accounts: PropTypes.array.isRequired,
        onClose: PropTypes.func.isRequired,
        onUploadStatement: PropTypes.func.isRequired
    };

    mounted = false;

    state = {
        error: false,
        errorMessage: '',
        selectedStatementType: null,
        selectedAccount: null,
        currentFileName: '',
        showProgress: false,
        progress: 0,
    };

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onUploadStatement = (files) => {
        files.forEach(file => {
            this.setState({
                currentFileName: file.name,
                showProgress: true,
                progress: 0
            }, () => this.uploadStatement(file));
        });
    };

    uploadStatement = (file) => {
        const { selectedStatementType, selectedAccount } = this.state;
        this.props.onUploadStatement(
            selectedStatementType.type,
            selectedStatementType.accountInformationAvailable ? 0 : selectedAccount.id,
            file,
            this.onUploadProgress,
            this.onSuccess,
            this.onError);
    };

    onSelectAccount = (account) => {
        if (account !== this.state.selectedAccount) {
            this.setState({selectedAccount: account})
        }
    };

    onSelectStatementType = (type) => {
        if (type !== this.state.selectedStatementType) {
            this.setState({selectedStatementType: type});
        }
    };

    onClose = (event) => {
        event.preventDefault();
        this.props.onClose()
    };

    onUploadProgress = (event) => {
        this.setState({progress: (event.loaded / event.total) * 100});
    };

    onSuccess = () => {
        setTimeout(this.props.onClose, 250);
    };

    onError = (error) => {
        if (this.mounted) {
            let errorMessage = error.message;
            if (error.response && error.response.data) {
                errorMessage += " - " + error.response.data.message;
            }
            this.setState({
                error: true,
                errorMessage: errorMessage
            })
        }
    };

    clearError = () => {
        if (this.mounted) {
            this.setState({
                error: false,
                errorMessage: '',
                selectedStatementType: null,
                selectedAccount: null,
                currentFileName: '',
                showProgress: false,
                progress: 0,
            });
        }
    };

    onOpenFileSelector = (event, dropzoneRef) => {
        event.preventDefault();
        event.stopPropagation();
        dropzoneRef.open();
    };

    render() {

        const { accounts } = this.props;

        const { error, errorMessage, selectedStatementType, selectedAccount, currentFileName, showProgress, progress } = this.state;

        const accountsByStatementType = _.groupBy(accounts, 'statementType');

        const statementTypes = _.filter(
            _.map(
                _.uniq(accounts, false, a => a.statementType)
                , a => ({type: a.statementType, accountInformationAvailable: a.accountInformationAvailable}))
            , t => t.type !== 'NONE');

        const disabled = !selectedStatementType || (selectedStatementType && !selectedStatementType.accountInformationAvailable && !selectedAccount) || error;

        let dropzoneRef;

        return (

            <div className="modal show fade-in">
                <div className={`modal-dialog modal-md`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={this.onClose}>&times;</button>
                            <h4 className="modal-title">Kontoauszug laden</h4>
                        </div>
                        <div className="modal-body file-upload-modal-body">
                            {error &&
                                <div className="alert alert-danger alert-dismissable">
                                    <button type="button" className="close" onClick={this.clearError}>&times;</button>
                                    Oops: {errorMessage}
                                </div>
                            }

                            <div className="statement-upload-selector">

                                <SelectBox placeholder="Typ..."
                                           clearable={true}
                                           values={statementTypes}
                                           selectedValue={selectedStatementType}
                                           valueMapper={t => ({value: t, label: t.type})}
                                           size="sm"
                                           disabled={error}
                                           onChange={this.onSelectStatementType}/>

                                {selectedStatementType && !selectedStatementType.accountInformationAvailable &&
                                <SelectBox placeholder="Konto..."
                                           clearable={true}
                                           values={accountsByStatementType[selectedStatementType.type]}
                                           selectedValue={selectedAccount}
                                           valueMapper={a => ({value: a, label: a.name})}
                                           size="sm"
                                           disabled={error}
                                           onChange={this.onSelectAccount}/>
                                }
                            </div>

                            <Dropzone multiple={false}
                                      onDrop={this.onUploadStatement}
                                      disabled={disabled}
                                      noClick={true}
                                      ref={node => dropzoneRef = node}>
                                {({getRootProps, getInputProps}) => (
                                    <div className="file-upload-container" {...getRootProps()}>
                                        <input type="file" {...getInputProps()}/>
                                        <div className="file-upload-content">
                                            <div className="text-center text-muted">
                                                <i className="glyphicon glyphicon-cloud-upload" style={{fontSize: "100px"}}/>
                                            </div>
                                            <h1 className="text-center text-muted">Kontoauszug hierher ziehen</h1>
                                            <h3 className="text-center text-muted">- oder -</h3>
                                            <div style={{paddingTop: "15px", marginBottom: "30px", textAlign: "center"}}>
                                                <button type="button"
                                                        className="btn btn-primary"
                                                        disabled={disabled}
                                                        onClick={e => this.onOpenFileSelector(e, dropzoneRef)}>Dateibrowser öffnen
                                                </button>
                                            </div>

                                            {showProgress &&
                                                <div className="progress" style={{margin: "auto", width: "70"}}>
                                                    <div className="progress-bar" role="progressbar" style={{ width : `${progress}%`}}>
                                                        {currentFileName + ' - ' + progress + '%'}
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" onClick={this.onClose}>Schliessen</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
