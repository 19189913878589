import {
    SEARCH,
    SEARCH_INDEX_REBUILD_COMPLETED,
    SEARCH_INDEX_REBUILD_REQUESTED,
    SEARCH_RESULTS_RECEIVED,
    SEARCH_STARTED
} from "./actions";

const initialState = {
    results: [],
    latency: 0,
    searching: false,
    searchIndexIsBuilding: false
};

function searchReducer(state = initialState, action) {

    if (action.scope !== SEARCH) {
        return state;
    }

    switch (action.type) {

        case SEARCH_STARTED:
            return Object.assign({}, state, {
                searching: true
            });

        case SEARCH_RESULTS_RECEIVED:
            return Object.assign({}, state, {
                results: action.results,
                latency: action.latency,
                searching: false
            });

        case SEARCH_INDEX_REBUILD_REQUESTED:
            return Object.assign({}, state, {
                searchIndexIsBuilding: true
            });

        case SEARCH_INDEX_REBUILD_COMPLETED:
            return Object.assign({}, state, {
                searchIndexIsBuilding: false
            });

        default:
            return state;
    }
}

export default searchReducer;
