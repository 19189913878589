import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {
    fetchBankAccounts,
    fetchBankTransactionsByValueDate,
    toggleBankTransactionUploadModal,
    uploadBankStatement
} from "../actions";
import InfiniteScroll from 'react-infinite-scroller';
import BankTransactionList from "../components/BankTransactionList";
import BankAccountAndDateRangeSelector from "../components/BankAccountAndDateRangeSelector";
import {formatDateAsIso} from "../../common/utils";
import withUser from "../../user/components/withUser";
import BankStatementUploadModal from "../components/BankStatementUploadModal";
import BankTransactionDetailsModal from "../components/BankTransactionDetailsModal";

class BankTransactionContainer extends Component {

    state = {

        bankAccounts: [],

        bankTransactions: {
            transactionsByDate: [],
            loadedSize: 0,
            totalSize: 0,
            debitAmount: 0,
            creditAmount: 0,
        },

        showBankTransactionUploadModal: false,
        showBankTransactionDetailsModal: false,
        selectedBankTransaction: null,

        filter: { },
        version: 0,
    };

    componentDidMount() {
        this.props.fetchBankAccounts();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showBankTransactionUploadModal !== this.props.showBankTransactionUploadModal) {
            this.setState({showBankTransactionUploadModal: this.props.showBankTransactionUploadModal});
        }
        if (prevProps.bankAccounts !== this.props.bankAccounts) {
            this.setState({bankAccounts: this.props.bankAccounts});
        }
        if (prevProps.bankTransactions !== this.props.bankTransactions) {
            this.setState({bankTransactions: this.props.bankTransactions});
        }
        if (!prevProps.bankTransactionsDirty && this.props.bankTransactionsDirty) {
            this.onNextPage(0);
        }
    }

    onNextPage = (pageIndex) => {
        const { bankTransactions, filter } = this.state;
        const { account, fromDate, toDate } = filter;
        const pageSize = 50;
        if (account && (pageIndex === 0 || (pageIndex * pageSize) < bankTransactions.totalSize)) {
            this.props.fetchBankTransactionsByValueDate(
                account.id,
                fromDate ? formatDateAsIso(fromDate) : null,
                toDate ? formatDateAsIso(toDate) : null,
                pageIndex,
                pageSize);
        }
    };

    onFilterChanged = (filter) => {
        this.setState(prevState => {
            return Object.assign({}, prevState, {
                filter: filter,
                version: prevState.version + 1
            })
        }, () => this.onNextPage(0));
    };

    onShowBankTransactionDetailsModal = (bankTransaction) => {
        this.setState({
            showBankTransactionDetailsModal: true,
            selectedBankTransaction: bankTransaction
        })
    };

    onCloseBankTransactionUploadModal = () => {
        this.props.toggleBankTransactionUploadModal(false);
    };

    onCloseBankTransactionDetailsModal = () => {
        this.setState({
            showBankTransactionDetailsModal: false,
            selectedBankTransaction: null
        });
    };

    render() {

        const {
            showUploadModal
        } = this.props;

        const {
            bankAccounts,
            bankTransactions,
            showBankTransactionUploadModal,
            showBankTransactionDetailsModal,
            selectedBankTransaction,
            version
        } = this.state;

        const {
            transactionsByDate,
            loadedSize,
            totalSize,
            debitAmount,
            creditAmount
        } = bankTransactions;

        return (
            <div>

                <BankAccountAndDateRangeSelector accounts={bankAccounts} onFilterChanged={this.onFilterChanged}/>

                <InfiniteScroll
                    key={`bt-scroller-${version}`}
                    pageStart={0}
                    loadMore={this.onNextPage}
                    hasMore={loadedSize < totalSize}
                    loader={<div className="loader" key={0}>Loading ...</div>}>
                    <BankTransactionList bankTransactionsByDate={transactionsByDate}
                                         debitAmount={debitAmount}
                                         creditAmount={creditAmount}
                                         onTransactionSelected={this.onShowBankTransactionDetailsModal}/>
                </InfiniteScroll>

                {showBankTransactionUploadModal &&
                    <BankStatementUploadModal accounts={bankAccounts}
                                              onClose={this.onCloseBankTransactionUploadModal}
                                              onUploadStatement={this.props.uploadBankStatement}/>
                }

                {showBankTransactionDetailsModal && selectedBankTransaction &&
                    <BankTransactionDetailsModal bankTransaction={selectedBankTransaction}
                                                 onClose={this.onCloseBankTransactionDetailsModal}/>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    showBankTransactionUploadModal: state.accountingReducer.showBankTransactionUploadModal,
    bankAccounts: state.accountingReducer.bankAccounts,
    bankTransactions: state.accountingReducer.bankTransactions,
    bankTransactionsDirty: state.accountingReducer.bankTransactionsDirty,
});

const mapDispatchToProps = {
    toggleBankTransactionUploadModal: toggleBankTransactionUploadModal,
    fetchBankAccounts: fetchBankAccounts,
    uploadBankStatement: uploadBankStatement,
    fetchBankTransactionsByValueDate: fetchBankTransactionsByValueDate,
};

export default withUser(connect(mapStateToProps, mapDispatchToProps)(BankTransactionContainer));

