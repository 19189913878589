import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import withUser from "../../user/components/withUser";
import {rebuildSearchIndex} from "../actions";

class SearchSettingsContainer extends Component {

    state = {
        searchIndexIsBuilding: false
    };

    componentDidUpdate(prevProps) {
        if (prevProps.searchIndexIsBuilding !== this.props.searchIndexIsBuilding) {
            this.setState({ searchIndexIsBuilding: this.props.searchIndexIsBuilding });
        }
    }

    render() {
        const { searchIndexIsBuilding } = this.state;
        return (
            <div className="row">
                <div className="col-md-12">
                {searchIndexIsBuilding ?
                    (
                        <button type="button"
                                className='btn btn-primary btn-large center-on-page'
                                style={{marginTop: "100px"}}
                                disabled={true}>
                            <span className="glyphicon glyphicon-refresh glyphicon-refresh-animate mr-5"/>Suchindex wird erstellt
                        </button>
                    ) : (
                        <button type="button"
                                className='btn btn-primary btn-large center-on-page'
                                style={{marginTop: "100px"}}
                                onClick={e => this.props.rebuildSearchIndex()}>
                            Suchindex neu erstellen
                        </button>
                    )
                }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    searchIndexIsBuilding: state.searchReducer.searchIndexIsBuilding
});

const mapDispatchToProps = {
    rebuildSearchIndex: rebuildSearchIndex
};

export default withUser(connect(mapStateToProps, mapDispatchToProps)(SearchSettingsContainer));

