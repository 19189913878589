import React, {Component} from "react";
import PropTypes from "prop-types";
import {deleteAttachment, fetchAttachments, updateAttachment, uploadAttachment} from "../actions";
import connect from "react-redux/es/connect/connect";
import AttachmentPanel from "../components/AttachmentPanel";

class AttachmentContainer extends Component {

    static propTypes = {
        propertyId: PropTypes.number.isRequired,
    };

    state = {
        attachments: [],
    };

    componentDidMount() {
        this.fetchAttachments();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.propertyId !== this.props.propertyId) {
            this.componentDidMount();
        }
        if (prevProps.attachments !== this.props.attachments) {
            this.setState({ attachments: this.props.attachments });
        }
        if (!prevProps.attachmentsDirty && this.props.attachmentsDirty) {
            this.fetchAttachments();
        }
    }

    fetchAttachments = () => {
        this.props.fetchAttachments(this.props.propertyId);
    };


    onUploadAttachment = (file, onUploadProgress) => {
        this.props.uploadAttachment(this.props.propertyId, file, onUploadProgress);
    };

    onUpdateAttachment = (attachment, onSuccess, onError) => {
        this.props.updateAttachment(attachment, onSuccess, onError);
    };

    onDeleteAttachment = (attachment, onSuccess, onError) => {
        this.props.deleteAttachment(attachment.id, onSuccess, onError);
    };


    render() {

        const { attachments } = this.state;

        return (
            <AttachmentPanel attachments={attachments}
                             onUploadAttachment={this.onUploadAttachment}
                             onUpdateAttachment={this.onUpdateAttachment}
                             onDeleteAttachment={this.onDeleteAttachment}/>
        );
    }
}

const mapStateToProps = state => ({
    attachments: state.propertyReducer.attachments,
    attachmentsDirty: state.propertyReducer.attachmentsDirty,
});

const mapDispatchToProps = {
    fetchAttachments: fetchAttachments,
    uploadAttachment: uploadAttachment,
    updateAttachment: updateAttachment,
    deleteAttachment: deleteAttachment
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentContainer);
