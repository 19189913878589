import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {formatAmount, formatDate} from "../../common/utils";
import BankTransactionDetailsModal from "./BankTransactionDetailsModal";

export default class BankTransactionList extends Component {

    static propTypes = {
        bankTransactionsByDate: PropTypes.array.isRequired,
        debitAmount: PropTypes.number.isRequired,
        creditAmount: PropTypes.number.isRequired,
        onTransactionSelected: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            bankTransactionsByDate: props.bankTransactionsByDate,
            debitAmount: props.debitAmount,
            creditAmount: props.creditAmount
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.bankTransactionsByDate !== this.props.bankTransactionsByDate) {
            this.setState({
                bankTransactionsByDate: this.props.bankTransactionsByDate,
                debitAmount: this.props.debitAmount,
                creditAmount: this.props.creditAmount
            });
        }
    }

    renderDateRow = (date) => {
        return (
            <tr className="transaction-date-header">
                <th className="text-left text-primary" style={{width: "50px"}}>{formatDate(parseInt(date), 'DD.MM.YYYY')}</th>
                <th className="ruler" colSpan="4" >
                    <div/>
                </th>
            </tr>
        )
    };

    renderRow = (bankTransaction) => {
        return (
            <tr key={`bt-${bankTransaction.id}`} className="transaction-row" style={{fontSize: "12px"}}>
                <td className="text-right">
                    {bankTransaction.id}
                </td>
                <td className="text-left text-muted" style={{cursor: "pointer"}} onClick={() => this.props.onTransactionSelected(bankTransaction)}>
                    {bankTransaction.reference}
                </td>
                <td className="text-right" style={{width: "70px"}}>
                    {bankTransaction.amount >= 0 && <span>{formatAmount(bankTransaction.amount)}</span>}
                </td>
                <td className="text-right text-red" style={{width: "70px"}}>
                    {bankTransaction.amount < 0 && <span>{formatAmount(bankTransaction.amount)}</span>}
                </td>
            </tr>
        );
    };

    render() {

        const { bankTransactionsByDate, debitAmount, creditAmount } = this.state;

        const balance = debitAmount + creditAmount;

        return (
            <table className="transaction-table table table-hover" style={{marginBottom: "5px"}}>
                <thead style={{fontSize: "12px"}}>
                    <tr>
                        <th className="text-left">Bilanz</th>
                        <th/>
                        <th className="text-right" style={{width: "70px"}}>
                            {balance >= 0 && formatAmount(balance)}
                        </th>
                        <th className="text-right text-red" style={{width: "70px"}}>
                            {balance < 0 && formatAmount(balance)}
                        </th>
                    </tr>
                    <tr>
                        <th className="text-left">Umsatz</th>
                        <th className="text-left"/>
                        <th className="text-right" style={{width: "70px"}}>{formatAmount(creditAmount)}</th>
                        <th className="text-right text-red" style={{width: "70px"}}>{formatAmount(debitAmount)}</th>
                    </tr>
                </thead>
                {bankTransactionsByDate.map((dateAndBankTransactions, idx) =>
                    <tbody key={`dabt-${idx}`} style={{fontSize: "12px"}}>
                        {this.renderDateRow(dateAndBankTransactions[0])}
                        {dateAndBankTransactions[1].map(bankTransaction => (
                            this.renderRow(bankTransaction)
                        ))}
                    </tbody>
                )}
            </table>
        );
    }


}