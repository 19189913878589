import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {Tab, Tabs} from "react-bootstrap";
import AnnualsReport from "../components/AnnualsReport";
import PropertyReport from "../components/PropertyReport";
import PropTypes from "prop-types";
import LoanReport from "../components/LoanReport";
import RentingReport from "../components/RentingReport";
import {withRouter} from "react-router";
import {exportReport, fetchReport} from "../actions";
import withUser from "../../user/components/withUser";
import {googleUser} from "../../common/utils";

class ReportContainer extends Component {

    static propTypes = {
        name: PropTypes.string.isRequired,
    };

    state = {
        rows: []
    };

    componentDidMount() {
        this.fetchReport(this.props.name);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.name !== this.props.name) {
            this.setState({rows: []});
            this.fetchReport(this.props.name);
        }
        if (prevProps.rows !== this.props.rows) {
            this.setState({rows: this.props.rows});
        }
    }

    fetchReport = (report) => {
        this.props.fetchReport(report);
    };

    onSelectReport = (key) => {
        this.props.history.push(`/reports/${key}`)
    };

    onExport = (event) => {
        event.preventDefault();
        const user = googleUser();
        user.reloadAuthResponse();
        user.grant({scope: 'https://www.googleapis.com/auth/drive'});
        this.props.exportReport(this.props.name, user.getAuthResponse().access_token);
    };

    render() {

        const { name, permissions } = this.props;

        const { rows } = this.state;

        return (
            <div>
                <div className="context-menu hidden-print">
                    <ol className="breadcrumb display-inline">
                        <li>Berichte</li>
                    </ol>
                    <ul className="nav nav-pills pull-right">
                        <li>
                            <a href="" onClick={this.onExport}><i className="glyphicon glyphicon-export"/><label>Google Sheets</label></a>
                        </li>
                    </ul>
                </div>

                <div className="report-container">
                    <Tabs id="reports" activeKey={name} onSelect={this.onSelectReport}>
                        <Tab eventKey="annuals" title="Jahreskennzahlen">
                            {name === 'annuals' &&
                                <AnnualsReport rows={rows}/>
                            }
                        </Tab>
                        <Tab eventKey="Bestand" title="Bestand">
                            {name === 'Bestand' &&
                                <PropertyReport rows={rows}/>
                            }
                        </Tab>
                        <Tab eventKey="renting" title="Vermietung">
                            {name === 'renting' &&
                            <RentingReport rows={rows}/>
                            }
                        </Tab>
                        {permissions && permissions.accountant &&
                            <Tab eventKey="loan" title="Darlehen">
                                {name === 'loan' &&
                                <LoanReport rows={rows}/>
                                }
                            </Tab>
                        }
                    </Tabs>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    rows: state.reportingReducer.rows
});

const mapDispatchToProps = {
    fetchReport,
    exportReport
};

export default withRouter(withUser(connect(mapStateToProps, mapDispatchToProps)(ReportContainer)));
