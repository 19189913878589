import {appendParams, doDelete, doGetJson, doPost, doPostFile, doPostJson, doPutJson, doPutText} from "./request";

export function fetchCurrentUser() {
    return doGetJson(`/api/users/current`);
}

export function fetchUsers() {
    return doGetJson(`/api/users`);
}

export function createUser(user) {
    return doPostJson(`/api/users`, user);
}

export function updateUser(user) {
    return doPutJson(`/api/users/${user.id}`, user);
}

export function deleteUser(id) {
    return doDelete(`/api/users/${id}`);
}

export function fetchProperties(groupId, fields) {
    const params = [];
    if (groupId) {
        params.push(`groupId=${groupId}`);
    }
    if (fields) {
        params.push(`fields=${fields}`);
    }
    return doGetJson(appendParams('/api/properties', params));
}

export function exportProperties(accessToken, groupId) {
    const params = [];
    params.push(`accessToken=${accessToken}`);
    if (groupId) {
        params.push(`groupId=${groupId}`);
    }
    return doPost(appendParams('/api/properties/export', params));
}

export function fetchProperty(propertyId) {
    return doGetJson(`/api/properties/${propertyId}`);
}

export function updatePropertyFields(propertyId, fields) {
    return doPutJson(`/api/properties/${propertyId}`, fields);
}

export function createProperty(fields) {
    return doPostJson(`/api/properties`, fields);
}

export function copyProperty(propertyId) {
    return doPostJson(`/api/properties?fromId=${propertyId}`, {});
}

export function fetchLikes(propertyId) {
    return doGetJson(`/api/likes/${propertyId}`);
}

export function updateLike(propertyId, value) {
    return doPostJson(`/api/likes/${propertyId}`, { value: value });
}

export function fetchActivitiesByPropertyId(propertyId) {
    return doGetJson(`/api/activities?propertyId=${propertyId}`);
}

export function fetchActivities(pageIndex, pageSize) {
    return doGetJson(`/api/activities?pageIndex=${pageIndex}&pageSize=${pageSize}`);
}

export function createComment(propertyId, comment) {
    return doPostJson('/api/comments', { propertyId: propertyId, text: comment });
}

export function updateComment(id, comment) {
    return doPutText(`/api/comments/${id}`, comment);
}

export function deleteComment(id) {
    return doDelete(`/api/comments/${id}`);
}

export function fetchRentalAgreements(propertyId) {
    return doGetJson(`/api/rental-agreements?propertyId=${propertyId}`);
}

export function createRentalAgreement(rentalAgreement) {
    return doPostJson(`/api/rental-agreements`, rentalAgreement);
}

export function updateRentalAgreement(rentalAgreement) {
    return doPutJson(`/api/rental-agreements/${rentalAgreement.id}`, rentalAgreement);
}

export function deleteRentalAgreement(id) {
    return doDelete(`/api/rental-agreements/${id}`);
}

export function fetchAttachments(propertyId) {
    return doGetJson(`/api/attachments?propertyId=${propertyId}`);
}

export function uploadAttachment(propertyId, file, onUploadProgress) {
    return doPostFile(`/api/attachments`, file, onUploadProgress, { propertyId: propertyId });
}

export function updateAttachment(attachment) {
    return doPutJson(`/api/attachments/${attachment.id}`, attachment);
}

export function deleteAttachment(id) {
    return doDelete(`/api/attachments/${id}`);
}

export function fetchImageDescriptors(propertyId = null) {
    const params = [];
    if (propertyId) {
        params.push(`id=${propertyId}`);
        params.push(`type=property`);
    }
    return doGetJson(appendParams('/api/image-descriptors', params));
}

export function fetchImageCollections(propertyId = null) {
    const params = [];
    if (propertyId) {
        params.push(`propertyId=${propertyId}`);
    }
    return doGetJson(appendParams('/api/image-collections', params));
}

export function createImageCollection(collection) {
    return doPostJson(`/api/image-collections`, collection);
}

export function updateImageCollection(collection) {
    return doPutJson(`/api/image-collections/${collection.id}`, collection);
}

export function deleteImageCollection(id) {
    return doDelete(`/api/image-collections/${id}`);
}

export function uploadImage(collectionId, file, onUploadProgress) {
    return doPostFile(`/api/images/${collectionId}`, file, onUploadProgress);
}

export function deleteImage(collectionId, imageId) {
    return doDelete(`/api/images/${collectionId}/${imageId}`);
}

export function search(phrase) {
    return doGetJson(`/api/search?phrase=${phrase}`);
}

export function buildSearchIndex() {
    return doPost(`/api/search/buildIndex`);
}

export function fetchBankAccounts() {
    return doGetJson(`/api/bank-accounts`);
}

export function createBankAccount(account) {
    return doPostJson(`/api/bank-accounts`, account);
}

export function updateBankAccount(account) {
    return doPutJson(`/api/bank-accounts/${account.id}`, account);
}

export function deleteBankAccount(id) {
    return doDelete(`/api/bank-accounts/${id}`);
}

export function fetchAccounts() {
    return doGetJson(`/api/accounts`);
}

export function createAccount(account) {
    return doPostJson(`/api/accounts`, account);
}

export function updateAccount(account) {
    return doPutJson(`/api/accounts/${account.id}`, account);
}

export function deleteAccount(id) {
    return doDelete(`/api/accounts/${id}`);
}

export function fetchLoans() {
    return doGetJson(`/api/loans`);
}

export function createLoan(loan) {
    return doPostJson(`/api/loans`, loan);
}

export function updateLoan(loan) {
    return doPutJson(`/api/loans/${loan.id}`, loan);
}

export function deleteLoan(id) {
    return doDelete(`/api/loans/${id}`);
}

export function fetchPropertyGroups() {
    return doGetJson(`/api/property-groups`);
}

export function createPropertyGroup(group) {
    return doPostJson(`/api/property-groups`, group);
}

export function updatePropertyGroup(group) {
    return doPutJson(`/api/property-groups/${group.id}`, group);
}

export function deletePropertyGroup(id) {
    return doDelete(`/api/property-groups/${id}`);
}

export function fetchBankTransactionsByValueDate(bankAccountId, fromDate, toDate, pageIndex, pageSize) {
    const params = [`bankAccountId=${bankAccountId}`, `pageIndex=${pageIndex}`, `pageSize=${pageSize}`];
    if (fromDate) {
        params.push(`fromDate=${fromDate}`);
    }
    if (toDate) {
        params.push(`toDate=${toDate}`);
    }
    return doGetJson(appendParams('/api/bank-transactions', params));
}

export function uploadBankStatement(statementType, bankAccountId, file, onUploadProgress) {
    return doPostFile(`/api/bank-transactions`, file, onUploadProgress, { statementType: statementType, bankAccountId: bankAccountId });
}

export function fetchLedgerEntries(accountId, flowType, flowId, subFlowType, subFlowId, fromDate, toDate, pageIndex, pageSize) {
    const params = [`accountId=${accountId}`, `pageIndex=${pageIndex}`, `pageSize=${pageSize}`];
    if (fromDate) {
        params.push(`fromDate=${fromDate}`);
    }
    if (toDate) {
        params.push(`toDate=${toDate}`);
    }
    if (flowType) {
        params.push(`flowType=${flowType}`);
    }
    if (flowId !== null && flowId !== undefined) {
        params.push(`flowId=${flowId}`);
    }
    if (subFlowType) {
        params.push(`subFlowType=${subFlowType}`);
    }
    if (subFlowId !== null && subFlowId !== undefined) {
        params.push(`subFlowId=${subFlowId}`);
    }
    return doGetJson(appendParams('/api/ledger/entries', params));
}

export function fetchUnaccountedLedgerEntries() {
    return doGetJson(`/api/ledger/unaccounted-entries`);
}

export function fetchBalances(flowType, flowId, subFlowType, subFlowId, fromDate, toDate) {
    const params = [];
    if (fromDate) {
        params.push(`fromDate=${fromDate}`);
    }
    if (toDate) {
        params.push(`toDate=${toDate}`);
    }
    if (flowType) {
        params.push(`flowType=${flowType}`);
    }
    if (flowId !== null && flowId !== undefined) {
        params.push(`flowId=${flowId}`);
    }
    if (subFlowType) {
        params.push(`subFlowType=${subFlowType}`);
    }
    if (subFlowId !== null && subFlowId !== undefined) {
        params.push(`subFlowId=${subFlowId}`);
    }
    return doGetJson(appendParams('/api/ledger/balances', params));
}

export function fetchTransaction(id) {
    return doGetJson(`/api/ledger/transactions/${id}`);
}

export function createTransaction(transaction) {
    return doPostJson(`/api/ledger/transactions`, transaction);
}

export function updateTransaction(id, transaction) {
    return doPutJson(`/api/ledger/transactions/${id}`, transaction);
}

export function revertTransaction(id) {
    return doDelete(`/api/ledger/transactions/${id}`);
}

export function fetchTransactionTemplate(name, transactionId, flowType, flowId, subFlowType, subFlowId) {
    const params = [`transactionId=${transactionId}`, `flowType=${flowType}`];
    if (flowId) {
        params.push(`flowId=${flowId}`);
    }
    if (subFlowType) {
        params.push(`subFlowType=${subFlowType}`);
    }
    if (subFlowId !== null && subFlowId !== undefined) {
        params.push(`subFlowId=${subFlowId}`);
    }
    return doGetJson(appendParams(`/api/ledger/transaction-templates/${name}`, params));
}

export function fetchFlows() {
    return doGetJson(`/api/flows`);
}

export function fetchReport(report) {
    return doGetJson(`/api/reports/${report}`);
}

export function exportReport(report, accessToken) {
    return doPost(`/api/reports/${report}/export?accessToken=${accessToken}`);
}