import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import * as _ from "underscore";
import {
    flowTypeLabel,
    formatAmount,
    formatDate,
    formatDateAsIso,
    parseFloatNumber,
    roundAmount
} from "../../common/utils";
import SelectBox from "../../common/components/SelectBox";

export default class EditTransactionTable extends Component {

    static propTypes = {
        accounts: PropTypes.array.isRequired,
        flowsByType: PropTypes.object.isRequired,
        existingEntries: PropTypes.array,
        description: PropTypes.string,
        valueDate: PropTypes.object.isRequired,
        legs: PropTypes.array,
        edit: PropTypes.bool.isRequired,
        onDescriptionUpdated: PropTypes.func.isRequired,
        onValueDateUpdated: PropTypes.func.isRequired,
        onLegsUpdated: PropTypes.func.isRequired
    };

    flowTypes = [
        'PROPERTY',
        'BANK_ACCOUNT',
        'LOAN',
        'NONE',
    ];

    createLeg = () => {
        return {
            sourceAccountId: this.props.accounts[0].id,
            sourceFlowType: 'NONE',
            sourceFlowId: 0,
            targetAccountId: this.props.accounts[0].id,
            targetFlowType: 'NONE',
            targetFlowId: 0,
            amount: 0,
            locked: false
        };
    };

    state = {
        accountsById: {},
        description: '',
        valueDate: new Date(),
        newLegs: []
    };

    componentDidMount() {

        const newLegs = this.props.legs || [];

        if (newLegs.length === 0) {
            newLegs.push(this.createLeg());
        }

        this.setState({
            accountsById: _.object(_.map(this.props.accounts, a => [a.id, a])),
            description: this.props.description || '',
            valueDate: this.props.valueDate,
            newLegs: newLegs
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.description !== this.props.description) {
            this.setState({description: this.props.description});
        }
        if (prevProps.valueDate !== this.props.valueDate) {
            this.setState({valueDate: this.props.valueDate});
        }
        if (prevProps.legs !== this.props.legs) {
            this.setState({newLegs: this.props.legs});
        }
    }

    addLeg = () => {
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            newState.newLegs.push(this.createLeg());
            return newState;
        }, this.notifyLegsUpdated);
    };

    duplicateLeg = (leg) => {
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            newState.newLegs.push({
                ...leg,
                amount: 0
            });
            return newState;
        }, this.notifyLegsUpdated);
    };

    removeLeg = (idx) => {
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            newState.newLegs = prevState.newLegs;
            newState.newLegs.splice(idx, 1);
            return newState;
        }, this.notifyLegsUpdated);
    };

    updateLeg = (idx, key, update) => {
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            newState.newLegs[idx][key] = update;
            return newState;
        }, this.notifyLegsUpdated);
    };

    distributeAmount = () => {
        this.setState(prevState => {

            const newState = Object.assign({}, prevState);

            let totalAmount = 0;
            let checkedCount = 0;
            for (const leg of newState.newLegs) {
                if (leg.checked) {
                    totalAmount += leg.amount;
                    checkedCount++;
                }
            }

            const amount = roundAmount(totalAmount / checkedCount);

            for (const leg of newState.newLegs) {
                if (leg.checked) {
                    leg.amount = amount;
                }
            }



            return newState;
        });
    };

    updateSourceFlowType = (idx, flowType) => {
        this.updateLeg(idx, 'sourceFlowType', flowType);
        if (flowType === 'BANK_ACCOUNT') {
            const bankTransactionId = this.findBankTransactionId();
            if (bankTransactionId >= 0) {
                this.updateLeg(idx, 'sourceFlowId', this.findBankAccountId());
                this.updateLeg(idx, 'sourceSubFlowType', 'BANK_TRANSACTION');
                this.updateLeg(idx, 'sourceSubFlowId', bankTransactionId);
            }
        }
    };

    updateSourceSubFlowId = (idx, flowId) => {
        this.updateLeg(idx, 'sourceSubFlowType', flowId ? 'RENTAL_AGREEMENT' : null);
        this.updateLeg(idx, 'sourceSubFlowId', flowId);
    };

    updateTargetFlowType = (idx, flowType) => {
        this.updateLeg(idx, 'targetFlowType', flowType);
        if (flowType === 'BANK_ACCOUNT') {
            const bankTransactionId = this.findBankTransactionId();
            if (bankTransactionId >= 0) {
                this.updateLeg(idx, 'targetFlowId', this.findBankAccountId());
                this.updateLeg(idx, 'targetSubFlowType', 'BANK_TRANSACTION');
                this.updateLeg(idx, 'targetSubFlowId', bankTransactionId);
            }
        }
    };

    updateTargetSubFlowId = (idx, flowId) => {
        this.updateLeg(idx, 'targetSubFlowType', flowId ? 'RENTAL_AGREEMENT' : null);
        this.updateLeg(idx, 'targetSubFlowId', flowId);
    };

    notifyDescriptionUpdated = () => {
        this.props.onDescriptionUpdated(this.state.description);
    };

    notifyValueDateUpdated = () => {
        this.props.onValueDateUpdated(this.state.valueDate);
    };

    notifyLegsUpdated = () => {
        this.props.onLegsUpdated(this.state.newLegs);
    };

    mapAccount = (account) => {
        return {
            value: account.id,
            label: account.name
        }
    };

    mapFlowType = (flowType) => {
        return {
            value: flowType,
            label: flowTypeLabel(flowType)
        };
    };

    mapFlow = (flow) => {
        return {
            value: flow.id,
            label: `${flow.id} - ${flow.label}`
        };
    };

    findSubFlows = (flows, flowId, subFlowType) => {
        const flow = _.find(flows, flow => flow.id === flowId);
        if (!flow || !flow.subFlows) {
            return null;
        }
        return flow.subFlows[subFlowType];
    };

    findBankAccountId = () => {
        const { existingEntries } = this.props;
        if (existingEntries) {
            for (const entry of existingEntries) {
                if (entry.subFlowType === 'BANK_TRANSACTION') {
                    return entry.flowId;
                }
            }
        }
        return -1;
    };

    findBankTransactionId = () => {
        const { existingEntries } = this.props;
        if (existingEntries) {
            for (const entry of existingEntries) {
                if (entry.subFlowType === 'BANK_TRANSACTION') {
                    return entry.subFlowId;
                }
            }
        }
        return -1;
    };

    renderRow = (leg, idx, disabled) => {

        const { accounts, flowsByType } = this.props;

        const sourceFlows = flowsByType[leg.sourceFlowType] || [];
        const targetFlows = flowsByType[leg.targetFlowType] || [];

        const sourceSubFlows = this.findSubFlows(sourceFlows, leg.sourceFlowId, 'RENTAL_AGREEMENT');
        const targetSubFlows = this.findSubFlows(targetFlows, leg.targetFlowId, 'RENTAL_AGREEMENT');

        const valueDate = leg.valueDate || this.state.valueDate;

        disabled = leg.locked || disabled;

        return (
            [
            <tr key={`ett-ls-${idx}`} className="left-side">
                <td className="text-center" rowSpan={2} style={{verticalAlign: "middle"}}>
                    <div className="leg-selector">
                        <input type="checkbox"
                               disabled={disabled}
                               checked={leg.checked || false}
                               onChange={e => this.updateLeg(idx, 'checked', e.target.checked)}/>
                    </div>
                </td>
                <td className="text-center">L</td>
                <td>
                    <SelectBox values={accounts}
                               valueMapper={this.mapAccount}
                               selectedValue={leg.sourceAccountId}
                               size="sm"
                               disabled={disabled}
                               onChange={accountId => this.updateLeg(idx, 'sourceAccountId', accountId)}/>
                </td>
                <td>
                    <SelectBox values={this.flowTypes}
                               valueMapper={this.mapFlowType}
                               selectedValue={leg.sourceFlowType}
                               size="sm"
                               disabled={disabled}
                               onChange={flowType => this.updateSourceFlowType(idx, flowType)}/>
                </td>
                <td>
                    <div className="flow-selector">
                        {leg.sourceFlowType !== 'NONE' &&
                            <SelectBox values={sourceFlows}
                                       valueMapper={this.mapFlow}
                                       selectedValue={leg.sourceFlowId}
                                       size="sm"
                                       disabled={disabled}
                                       onChange={flowId => this.updateLeg(idx, 'sourceFlowId', flowId)}/>
                        }
                    </div>
                </td>
                <td>
                    <div className="flow-selector">
                        {leg.sourceFlowType === 'PROPERTY' && sourceSubFlows &&
                            <SelectBox values={sourceSubFlows}
                                       valueMapper={this.mapFlow}
                                       selectedValue={leg.sourceSubFlowId}
                                       size="sm"
                                       disabled={disabled}
                                       onChange={flowId => this.updateSourceSubFlowId(idx, flowId)}/>
                        }
                        {leg.sourceSubFlowType === 'BANK_TRANSACTION' &&
                            <div className="bank-transaction-id ">
                                Transaktion: {leg.sourceSubFlowId}
                            </div>
                        }
                    </div>

                </td>
                <td>
                    <input type="number"
                           className="input-sm form-control"
                           value={leg.amount}
                           disabled={disabled}
                           onChange={e => this.updateLeg(idx, 'amount', parseFloatNumber(e.target.value))}/>
                </td>
                <td className="text-center">
                    {formatDate(valueDate, 'DD.MM.YYYY')}
                </td>
                <td className="text-center" style={{verticalAlign: "middle"}}>
                    <button type="button" className="btn btn-link" disabled={disabled} onClick={e => this.removeLeg(idx)}>
                        <i className="halflings halflings-remove"/>
                    </button>
                </td>
            </tr>,
            <tr key={`ett-rs-${idx}`} className="right-side">
                <td className="text-center">R</td>
                <td>
                    <SelectBox values={accounts}
                               valueMapper={this.mapAccount}
                               selectedValue={leg.targetAccountId}
                               size="sm"
                               disabled={disabled}
                               onChange={accountId => this.updateLeg(idx, 'targetAccountId', accountId)}/>
                </td>
                <td>
                    <SelectBox values={this.flowTypes}
                               valueMapper={this.mapFlowType}
                               selectedValue={leg.targetFlowType}
                               size="sm"
                               disabled={disabled}
                               onChange={flowType => this.updateTargetFlowType(idx, flowType)}/>
                </td>
                <td>
                    <div className="flow-selector">
                        {leg.targetFlowType !== 'NONE' &&
                            <SelectBox values={targetFlows}
                                       valueMapper={this.mapFlow}
                                       selectedValue={leg.targetFlowId}
                                       size="sm"
                                       disabled={disabled}
                                       onChange={flowId => this.updateLeg(idx, 'targetFlowId', flowId)}/>
                        }
                    </div>
                </td>
                <td>
                    <div className="flow-selector">
                        {leg.targetFlowType === 'PROPERTY' && targetSubFlows &&
                            <SelectBox values={targetSubFlows}
                                       valueMapper={this.mapFlow}
                                       selectedValue={leg.targetSubFlowId}
                                       size="sm"
                                       disabled={disabled}
                                       onChange={flowId => this.updateTargetSubFlowId(idx, flowId)}/>
                        }
                        {leg.targetFlowType === 'BANK_TRANSACTION' &&
                            <div className="bank-transaction-id ">
                                Transaktion: {leg.targetSubFlowId}
                            </div>
                        }
                    </div>

                </td>
                <td>
                    <input type="number"
                           className="input-sm form-control"
                           value={-leg.amount}
                           disabled={true}/>
                </td>
                <td className="text-center">
                    {formatDate(valueDate, 'DD.MM.YYYY')}
                </td>
                <td className="text-center" style={{verticalAlign: "middle"}}>
                    <button type="button" className="btn btn-link" disabled={disabled} onClick={() => this.duplicateLeg(leg)}>
                        <i className="halflings halflings-duplicate"/>
                    </button>
                </td>
            </tr>
            ]
        );
    };

    renderBalanceAccount = (accountId, count) => {
        const account = this.state.accountsById[accountId];
        const width = 100 / count;
        return (
            <th key={`tmbac-${accountId}`} className="text-center" style={{width: `${width}%`}}>{account ? account.name : accountId}</th>
        );
    };

    renderBalanceAmount = (accountId, amount) => {
        const account = this.state.accountsById[accountId];
        const cashClearingAlert = account && account.routingKey === 'CASH_CLEARING' && amount !== 0;
        if (cashClearingAlert) {
            return (
                <td key={`tmbam-${accountId}`} className="text-center"><strong className="text-danger">{formatAmount(amount)}</strong></td>
            );
        } else {
            return (
                <td key={`tmbam-${accountId}`} className="text-center">{formatAmount(amount)}</td>
            );
        }
    };

    convertEntriesToLegs = (entries) => {
        const legs = [];
        if (!entries) {
            return legs;
        }
        for (let i = 0; i < entries.length; i += 2) {
            const entry1 = entries[i], entry2 = entries[i+1];
            legs.push({
                sourceAccountId: entry1.accountId,
                sourceFlowType: entry1.flowType,
                sourceFlowId: entry1.flowId,
                sourceSubFlowType: entry1.subFlowType,
                sourceSubFlowId: entry1.subFlowId,

                targetAccountId: entry2.accountId,
                targetFlowType: entry2.flowType,
                targetFlowId: entry2.flowId,
                targetSubFlowType: entry2.subFlowType,
                targetSubFlowId: entry2.subFlowId,

                amount: entry1.amount,

                valueDate: entry1.valueDate,

                locked: true
            })
        }
        return legs;
    };
    
    aggregateBalances = (leg, balancesByAccountId) => {
        if (!balancesByAccountId[leg.sourceAccountId]) {
            balancesByAccountId[leg.sourceAccountId] = leg.amount;
        } else {
            balancesByAccountId[leg.sourceAccountId] = roundAmount(balancesByAccountId[leg.sourceAccountId] + leg.amount);
        }
        if (!balancesByAccountId[leg.targetAccountId]) {
            balancesByAccountId[leg.targetAccountId] = -leg.amount;
        } else {
            balancesByAccountId[leg.targetAccountId] = roundAmount(balancesByAccountId[leg.targetAccountId] - leg.amount);
        }
    };

    render() {

        const { existingEntries, edit } = this.props;

        const { description, valueDate, newLegs } = this.state;

        const oldLegs = this.convertEntriesToLegs(existingEntries);

        const balancesByAccountId = {};

        oldLegs.forEach(leg => this.aggregateBalances(leg, balancesByAccountId));
        newLegs.forEach(leg => this.aggregateBalances(leg, balancesByAccountId));

        const balancePairs = _.pairs(balancesByAccountId);

        const disabled = !edit;

        const checkedCount = _.reduce(newLegs, (memo, leg) => { return leg.checked ? memo + 1 : memo}, 0);

        return (
            <Fragment>

                <div className="form-group">
                    <label>Beschreibung</label>
                    <textarea className="form-control"
                              disabled={disabled}
                              rows={2}
                              value={description}
                              onChange={e => this.setState({description: e.target.value}, this.notifyDescriptionUpdated)}/>
                </div>

                <div className="form-group">
                    <label>Wertstellungsdatum</label>
                    <input className="form-control"
                           style={{maxWidth: "200px"}}
                           type="date"
                           disabled={disabled}
                           value={formatDateAsIso(valueDate)}
                           onChange={e => this.setState({valueDate: new Date(e.target.value)}, this.notifyValueDateUpdated)}/>
                </div>

                <label>Transaktionszeilen</label>

                <table className="table table-bordered table-condensed create-transaction-table">
                    <thead>
                    <tr>
                        <th/>
                        <th className="text-center">Seite</th>
                        <th className="text-center">Konto</th>
                        <th className="text-center">Bezug</th>
                        <th className="text-center">Objekt 1</th>
                        <th className="text-center" style={{width: "auto"}}>Objekt 2</th>
                        <th className="text-center" style={{width: "100px", minWidth: "100px"}}>Betrag</th>
                        <th className="text-center">Wertstellung</th>
                        <th className="text-center">
                            <button type="button" className="btn btn-link" disabled={disabled} onClick={this.addLeg}>
                                <i className="halflings halflings-plus"/>
                            </button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {oldLegs.map((leg, idx) => this.renderRow(leg, idx, disabled))}
                    {!disabled && newLegs.map((leg, idx) => this.renderRow(leg, idx, disabled))}
                    </tbody>
                </table>


                <div className="form-group">
                    <button type="button" className="btn btn-primary btn-sm btn-outline" disabled={checkedCount === 0} onClick={this.distributeAmount}>
                        Betrag aufteilen
                    </button>
                </div>


                <label>Bilanzen</label>

                <table className="table table-bordered table-condensed">
                    <thead>
                    <tr>
                        {balancePairs.map(pair => this.renderBalanceAccount(pair[0], balancePairs.length))}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {balancePairs.map(pair => this.renderBalanceAmount(pair[0], pair[1]))}
                    </tr>
                    </tbody>
                </table>

            </Fragment>
        );
    }


}
