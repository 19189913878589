import React, {Component} from "react";
import ActivityContainer from "./PropertyActivityContainer";
import connect from "react-redux/es/connect/connect";
import ContextMenu from "../components/ContextMenu";
import FieldPanel from "../components/FieldPanel";
import RentalAgreementContainer from "./RentalAgreementContainer";
import AttachmentContainer from "./AttachmentContainer";
import ImageContainer from "./PropertyImageContainer";
import Modal from "../../common/components/Modal";
import {
    copyProperty,
    fetchLikes,
    fetchProperty,
    updateLike,
    updatePropertyField,
    updatePropertyFields
} from "../actions";
import withUser from "../../user/components/withUser";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {fetchPropertyGroups} from "../../property-group/actions";


class PropertyContainer extends Component {

    static propTypes = {
        propertyId: PropTypes.number.isRequired
    };

    state = {
        property: null,
        updatedFields: {},
        likes: [],
        dirty: false,
        showConfirmCopyModal: false
    };

    componentDidMount() {
        this.fetchPropertyGroups();
        this.fetchProperty();
        this.fetchLikes();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.propertyId !== this.props.propertyId) {

            this.componentDidMount();

        } else {

            const newState = {};

            if (prevProps.propertyGroups !== this.props.propertyGroups) {
                newState['propertyGroups'] = this.props.propertyGroups;
            }

            if (prevProps.property !== this.props.property) {
                newState['property'] = this.props.property;
            }

            if (prevProps.likes !== this.props.likes) {
                newState['likes'] = this.props.likes;
            }

            if (prevProps.updatedFields !== this.props.updatedFields) {
                newState['updatedFields'] = this.props.updatedFields;
            }

            if (prevProps.dirty !== this.props.dirty) {
                newState['dirty'] = this.props.dirty;
            }

            if (Object.keys(newState).length > 0) {
                this.setState(newState);
            }

            if (!prevProps.propertyDirty && this.props.propertyDirty) {
                this.fetchProperty();
            }

            if (!prevProps.likesDirty && this.props.likesDirty) {
                this.fetchLikes();
            }
        }
    }

    fetchPropertyGroups = () => {
        this.props.fetchPropertyGroups();
    };

    fetchProperty = () => {
        this.props.fetchProperty(this.props.propertyId);
    };

    fetchLikes = () => {
        this.props.fetchLikes(this.props.propertyId);
    };

    onSaveUpdates = () => {
        this.props.updatePropertyFields(this.props.propertyId, this.props.updatedFields);
    };

    onCancelUpdates = () => {
        this.componentDidMount();
    };

    onExportOpenImmo = () => {

    };

    onUpdateField = (field, value) => {
        this.props.updatePropertyField(field, value);
    };

    onUpdateLike = (value) => {
        this.props.updateLike(this.props.propertyId, value);
    };

    onShowConfirmCopyModal = () => {
        this.setState({ showConfirmCopyModal: true })
    };

    onCloseConfirmCopyModal = () => {
        this.setState({ showConfirmCopyModal: false });
    };

    onCopyProperty = (property) => {
        this.props.copyProperty(property.id, dto => {
            this.onCloseConfirmCopyModal();
            this.props.history.push(`/properties/${dto.id}`)
        });
    };

    render() {

        const { permissions } = this.props;

        const { propertyGroups, property, likes, dirty, showConfirmCopyModal } = this.state;

        if (!(property && likes && propertyGroups)) {
            return (
                <p>Loading...</p>
            )
        }

        const propertyGroup = propertyGroups.find(g => g.id === property.fields.groupId) || { id: 0, name: 'Unbekannter Ordner' } ;

        return (
            <div>

                <ContextMenu property={property}
                             propertyGroup={propertyGroup}
                             dirty={dirty}
                             onSaveUpdates={this.onSaveUpdates}
                             onCancelUpdates={this.onCancelUpdates}
                             onCopy={this.onShowConfirmCopyModal}
                             onExportOpenImmo={this.onExportOpenImmo}/>

                <div className="row property-container">

                    <div className="col-md-4">
                        <FieldPanel property={property}
                                    propertyGroups={propertyGroups}
                                    likes={likes}
                                    onUpdateField={this.onUpdateField}
                                    onUpdateLike={this.onUpdateLike}/>
                    </div>

                    <div className="col-md-4">
                        <ActivityContainer propertyId={property.id}/>
                    </div>

                    <div className="col-md-4">
                        <ImageContainer property={property}/>
                        {permissions && (permissions.admin || permissions.editor) &&
                            <AttachmentContainer propertyId={property.id}/>
                        }
                        <RentalAgreementContainer propertyId={property.id}/>
                        {/*<div ng-include src="'partials/property/property-map.html'"></div>*/}
                    </div>

                </div>

                {showConfirmCopyModal &&
                    <Modal title="Bestätigung"
                           closeLabel="Abbrechen"
                           onClose={this.onCloseConfirmCopyModal}
                           submitLabel="Kopieren"
                           onSubmit={this.onCopyProperty}
                           data={property}
                           classNames="modal-md">
                        Objekt #{property.id} kopieren?
                    </Modal>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.userReducer.currentUser,
    propertyGroups: state.propertyGroupReducer.propertyGroups,
    property: state.propertyReducer.property,
    propertyReceiveTime: state.propertyReducer.propertyReceiveTime,
    propertyDirty: state.propertyReducer.propertyDirty,
    updatedFields: state.propertyReducer.updatedFields,
    likes:  state.propertyReducer.likes,
    likesDirty:  state.propertyReducer.likesDirty,
    dirty: state.propertyReducer.dirty,
});

const mapDispatchToProps = {
    fetchPropertyGroups: fetchPropertyGroups,
    fetchProperty: fetchProperty,
    updatePropertyField: updatePropertyField,
    updatePropertyFields: updatePropertyFields,
    copyProperty: copyProperty,
    fetchLikes: fetchLikes,
    updateLike: updateLike
};

export default withRouter(withUser(connect(mapStateToProps, mapDispatchToProps)(PropertyContainer)));


