import React, {Component} from "react";
import PropTypes from "prop-types";
import {createRentalAgreement, deleteRentalAgreement, fetchRentalAgreements, updateRentalAgreement} from "../actions";
import connect from "react-redux/es/connect/connect";
import RentalAgreementPanel from "../components/RentalAgreementPanel";

class RentalAgreementContainer extends Component {

    static propTypes = {
        propertyId: PropTypes.number.isRequired
    };

    state = {
        rentalAgreements: [],
        showCreateModal: false
    };

    componentDidMount() {
        this.fetchRentalAgreements();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.propertyId !== this.props.propertyId) {
            this.componentDidMount();
        }
        if (prevProps.rentalAgreements !== this.props.rentalAgreements) {
            this.setState({ rentalAgreements: this.props.rentalAgreements });
        }
        if (!prevProps.rentalAgreementsDirty && this.props.rentalAgreementsDirty) {
            this.fetchRentalAgreements();
        }
    }

    fetchRentalAgreements = () => {
        this.props.fetchRentalAgreements(this.props.propertyId);
    };

    onCreateRentalAgreement = (rentalAgreement, onSuccess, onError) => {
        this.props.createRentalAgreement(rentalAgreement, onSuccess, onError);
    };

    onUpdateRentalAgreement = (rentalAgreement, onSuccess, onError) =>  {
        this.props.updateRentalAgreement(rentalAgreement, onSuccess, onError);
    };

    onDeleteRentalAgreement = (rentalAgreement, onSuccess, onError) => {
        this.props.deleteRentalAgreement(rentalAgreement.id, onSuccess, onError);
    };

    render() {
        return (
           <RentalAgreementPanel propertyId={this.props.propertyId}
                                 rentalAgreements={this.state.rentalAgreements}
                                 onCreateRentalAgreement={this.onCreateRentalAgreement}
                                 onUpdateRentalAgreement={this.onUpdateRentalAgreement}
                                 onDeleteRentalAgreement={this.onDeleteRentalAgreement}/>
        );
    }
}

const mapStateToProps = state => ({
    rentalAgreements: state.propertyReducer.rentalAgreements,
    rentalAgreementsDirty: state.propertyReducer.rentalAgreementsDirty,
});

const mapDispatchToProps = {
    fetchRentalAgreements: fetchRentalAgreements,
    createRentalAgreement: createRentalAgreement,
    updateRentalAgreement: updateRentalAgreement,
    deleteRentalAgreement: deleteRentalAgreement
};

export default connect(mapStateToProps, mapDispatchToProps)(RentalAgreementContainer);

