import React, {Component} from "react";
import Activity from "./Activity";
import PropTypes from "prop-types";
import PropertyChangesModal from "../../common/components/PropertyChangesModal";


export default class ActivityPanel extends Component {

    static propTypes = {
        activities: PropTypes.array.isRequired,
        onUpdateComment: PropTypes.func.isRequired,
        onDeleteComment: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            activities: props.activities,
            showChangesModal: false,
            selectedActivity: null
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activities !== this.props.activities) {
            this.setState({activities: this.props.activities});
        }
    }

    onShowChangesModal = (selectedActivity) => {
        this.setState({
            showChangesModal: true,
            selectedActivity: selectedActivity
        })
    };

    onCloseChangesModal = () => {
        this.setState({
            showChangesModal: false,
            selectedActivity: null
        })
    };

    render() {
        const { activities, showChangesModal, selectedActivity } = this.state;
        return (
            <div className="panel panel-default" style={{marginTop: "25px"}}>
                {activities.length > 0 &&
                activities.map((a, idx) =>
                    <Activity
                        key={a.id}
                        activity={a}
                        first={idx === 0}
                        onUpdateComment={this.props.onUpdateComment}
                        onDeleteComment={this.props.onDeleteComment}
                        onShowChanges={this.onShowChangesModal}/>)
                }
                {showChangesModal && selectedActivity &&
                    <PropertyChangesModal activity={selectedActivity}
                                          onClose={this.onCloseChangesModal}/>
                }
            </div>
        );
    }

}


