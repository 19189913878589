import React, {Component} from "react";
import PropTypes from "prop-types";

export default class Modal extends Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        closeLabel: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        submitLabel: PropTypes.string,
        onSubmit: PropTypes.func,
    };

    mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            error: false,
            errorMessage: ''
        };
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({data: this.props.data})
        }
    }

    onClose = (event) => {
        event.preventDefault();
        this.props.onClose()
    };

    onSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit(this.state.data, this.onSuccess, this.onError);
    };

    onSuccess = () => {
        this.props.onClose();
    };

    onError = (error) => {
        if (this.mounted) {
            this.setState({
                error: true,
                errorMessage: error.message
            })
        }
    };

    clearError = () => {
        if (this.mounted) {
            this.setState({
                error: false,
                errorMessage: ''
            });
        }
    };

    render() {

        const { title, submitLabel, closeLabel, children, classNames, bodyClassNames } = this.props;

        const { error, errorMessage } = this.state;

        return (
            <div className="modal show fade-in">
                <div className={`modal-dialog ${classNames}`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={this.onClose}>&times;</button>
                            <h4 className="modal-title">{title}</h4>
                        </div>
                        <div className={`modal-body ${bodyClassNames}`}>
                            {error &&
                                <div className="alert alert-danger alert-dismissable">
                                    <button type="button" className="close" onClick={this.clearError}>&times;</button>
                                    Oops: {errorMessage}
                                </div>
                            }
                            {children}
                        </div>
                        <div className="modal-footer">
                            {submitLabel &&
                            <button type="button" className="btn btn-primary" onClick={this.onSubmit}>{submitLabel}</button>
                            }
                            <button type="button" className="btn btn-default" onClick={this.onClose}>{closeLabel}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}