import {LOAN, LOAN_UPDATED, LOANS_RECEIVED,} from "./actions";

const initialState = {
    loans: [],
    loansDirty: false,
};

function loanReducer(state = initialState, action) {

    if (action.scope !== LOAN) {
        return state;
    }

    switch (action.type) {

        case LOANS_RECEIVED:
            return Object.assign({}, state, {
                loans: action.loans,
                loansDirty: false
            });

        case LOAN_UPDATED:
            return Object.assign({}, state, {
                loansDirty: true
            });
        default:
            return state;
    }
}

export default loanReducer;
