import React, {Component} from "react";
import PropTypes from "prop-types";
import {formatAmount} from "../../common/utils";

export default class LoanReport extends Component {

    static propTypes = {
        rows: PropTypes.array.isRequired,
    };

    renderRow = (row) => {
        return (
            <tr key={`lr-${row.id}`}>
                <td className="text-center">{row.id}</td>
                <td className="text-left">{row.name}</td>
                <td className="text-right">{formatAmount(row.annualInterestRate * 100, '0,0')} %</td>
                <td className="text-right">{formatAmount(row.credit, '0,0')}</td>
                <td className="text-right">{formatAmount(row.debit, '0,0')}</td>
                <td className="text-right">{formatAmount(row.accruedInterest, '0,0')}</td>
                <td className="text-right">{formatAmount(row.compoundedCredit, '0,0')}</td>
            </tr>
        );
    };

    render() {
        return (
            <table className="table table-hover loan-report">
                <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th className="text-left">Bezeichnung</th>
                    <th className="text-right">Zinssatz</th>
                    <th className="text-right">Eingezahlt</th>
                    <th className="text-right">Ausgezahlt</th>
                    <th className="text-right">Zinsen</th>
                    <th className="text-right">Bilanz</th>
                </tr>
                </thead>
                <tbody>
                    {this.props.rows.map(this.renderRow)}
                </tbody>
            </table>
        );
    }
}

