import React, {Component} from "react";
import withUser from "../../user/components/withUser";
import ThumbnailGallery from "../../common/components/ThumbnailGallery";
import {formatDate} from "../../common/utils";
import PropTypes from "prop-types";
import Modal from "../../common/components/Modal";
import CollectionEditModal from "./CollectionEditModal";
import ImageUploadModal from "./ImageUploadModal";
import {propertyName} from "../../common/image";
import NavLink from "../../common/components/NavLink";

class ImageCollection extends Component {

    static propTypes = {
        collection: PropTypes.object.isRequired,
        descriptors: PropTypes.array.isRequired,
        propertiesById: PropTypes.object.isRequired,
        onUploadImage: PropTypes.func.isRequired,
        onDeleteImage: PropTypes.func.isRequired,
        onUpdateCollection: PropTypes.func.isRequired,
        onDeleteCollection: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            collection: props.collection,
            descriptors: props.descriptors,
            propertiesById: props.propertiesById,
            showImageUploadModal: false,
            showImageDeleteModal: false,
            showCollectionEditModal: false,
            showCollectionDeleteModal: false,
            imageDescriptorToDelete: null
        };
    }

    componentDidUpdate(prevProps) {
        const newState = {};

        if (prevProps.collection !== this.props.collection) {
            newState['collection'] = this.props.collection;
        }

        if (prevProps.descriptors !== this.props.descriptors) {
            newState['descriptors'] = this.props.descriptors;
        }

        if (prevProps.propertiesById !== this.props.propertiesById) {
            newState['propertiesById'] = this.props.propertiesById;
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    onShowImageUploadModal = (event) => {
        event.preventDefault();
        this.setState({ showImageUploadModal: true });
    };

    onShowImageDeleteModal = (descriptor) => {
        this.setState({
            showImageDeleteModal: true,
            imageDescriptorToDelete: descriptor
        });
    };

    onShowCollectionEditModal = (event) => {
        event.preventDefault();
        this.setState({ showCollectionEditModal: true });
    };

    onShowCollectionDeleteModal = (event) => {
        event.preventDefault();
        this.setState({ showCollectionDeleteModal: true });
    };

    onCloseModals = () => {
        this.setState({
            showImageUploadModal: false,
            showImageDeleteModal: false,
            showCollectionEditModal: false,
            showCollectionDeleteModal: false,
            imageDescriptorToDelete: null
        });
    };

    onDeleteImage = (descriptor, onSuccess, onError) => {
        this.props.onDeleteImage(this.state.collection, descriptor, onSuccess, onError);
    };

    propertyExists = (id) => {
        return this.state.propertiesById[id];
    };

    propertyName = (id) => {
        return propertyName(this.state.propertiesById[id]);
    };

    render() {

        const { permissions } = this.props;

        const {
            collection,
            descriptors,
            propertiesById,
            showImageUploadModal,
            showImageDeleteModal,
            showCollectionEditModal,
            showCollectionDeleteModal,
            imageDescriptorToDelete
        } = this.state;

        if (!(collection && descriptors && propertiesById)) {
            return <div className="row"/>
        }

        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="imgcol">

                        <div className="imgcol-header">
                            <div className="pull-left"><h3 id="col-{{col.id}}">{collection.name}</h3></div>
                            <div className="pull-right imgcol-actions">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown">
                                        Aktionen&nbsp;<span className="caret"/>
                                    </button>
                                    <ul className="dropdown-menu pull-right" role="menu">
                                        {permissions.editor &&
                                        <li><a href="" onClick={this.onShowImageUploadModal}><i className="glyphicon glyphicon-plus"/> Bilder hinzufügen</a></li>
                                        }
                                        {permissions.editor &&
                                        <li className="divider"/>
                                        }
                                        {permissions.editor &&
                                        <li><a href="" onClick={this.onShowCollectionEditModal}><i className="glyphicon glyphicon-edit"/> Album bearbeiten</a></li>
                                        }
                                        {permissions.editor &&
                                        <li><a href="" onClick={this.onShowCollectionDeleteModal}><i className="glyphicon glyphicon-trash"/> Album löschen</a>
                                        </li>
                                        }
                                        {permissions.editor &&
                                        <li className="divider"/>
                                        }
                                        <li><a href={`/api/images/${collection.id}`} download={`${collection.id}.zip`}><i className="glyphicon glyphicon-download-alt"/> Album herunterladen</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {collection.description &&
                            <div className="imgcol-description">
                                <small>
                                    <strong>Beschreibung:</strong>&nbsp;<span className="text-muted">{collection.description}</span>
                                </small>
                            </div>
                        }

                        <ThumbnailGallery descriptors={descriptors}
                                          collection={collection}
                                          editable={permissions.editor || false}
                                          downloadEnabled={true}
                                          scalingFactor={1}
                                          onUpdateCollection={collection => this.props.onUpdateCollection(collection, () => {}, () => {})}
                                          onDeleteImage={this.onShowImageDeleteModal}/>

                        <div className="imgcol-footer">
                            <small className="text-muted">
                                {descriptors.length} {descriptors.length === 1 ? 'Bild' : 'Bilder'} - Erstellt am {formatDate(collection.createTime)}, letzte Änderung am {formatDate(collection.updateTime)}
                                {collection.propertyIds.length > 0 && collection.propertyIds.filter(this.propertyExists).map(id => (
                                    <span key={`img-col-prop-${collection.id}-${id}`}>
                                        <span>&nbsp;|&nbsp;</span>
                                        <NavLink path={`/properties/${id}`}>{this.propertyName(id)}</NavLink>
                                    </span>
                                ))}
                            </small>
                        </div>

                    </div>
                </div>

                {showImageUploadModal &&
                    <ImageUploadModal collection={collection}
                                      onClose={this.onCloseModals}
                                      onUploadImage={this.props.onUploadImage}/>
                }

                {showImageDeleteModal &&
                    <Modal title="Bestätigung"
                           closeLabel="Abbrechen"
                           onClose={this.onCloseModals}
                           submitLabel="Löschen"
                           onSubmit={this.onDeleteImage}
                           data={imageDescriptorToDelete}
                           classNames="modal-md">
                        Bild '{ imageDescriptorToDelete.id + '.' + imageDescriptorToDelete.extension }' wirklich löschen?
                    </Modal>
                }

                {showCollectionEditModal &&
                    <CollectionEditModal title="Album bearbeiten"
                                         collection={collection}
                                         propertiesById={propertiesById}
                                         onClose={this.onCloseModals}
                                         onSubmit={this.props.onUpdateCollection}/>
                }

                {showCollectionDeleteModal &&
                    <Modal title="Bestätigung"
                           closeLabel="Abbrechen"
                           onClose={this.onCloseModals}
                           submitLabel="Löschen"
                           onSubmit={this.props.onDeleteCollection}
                           data={collection}
                           classNames="modal-md">
                        Album '{ collection.name }' wirklich  löschen?
                    </Modal>
                }
            </div>
        );
    }
}

export default withUser(ImageCollection);

