import * as API from "../common/api";
import {action} from "../common/utils";

export const SEARCH = "SEARCH";

export const SEARCH_STARTED = 'SEARCH_STARTED';

export const SEARCH_RESULTS_RECEIVED = 'SEARCH_RESULTS_RECEIVED';

export const SEARCH_INDEX_REBUILD_REQUESTED = 'SEARCH_INDEX_REBUILD_REQUESTED';
export const SEARCH_INDEX_REBUILD_COMPLETED = 'SEARCH_INDEX_REBUILD_COMPLETED';

export function search(phrase, onSuccess) {
    return function(dispatch) {
        const start = new Date().getTime();
        dispatch(action({scope: SEARCH, type: SEARCH_STARTED}));
        API.search(phrase)
            .then(response => {
                dispatch(action({
                    scope: SEARCH,
                    type: SEARCH_RESULTS_RECEIVED,
                    results: response.data,
                    latency: new Date().getTime() - start
                }));
                onSuccess();
            });
    }
}

export function rebuildSearchIndex() {
    return function(dispatch) {
        dispatch(action({ scope: SEARCH, type: SEARCH_INDEX_REBUILD_REQUESTED }));
        API.buildSearchIndex()
            .then(() => {
                dispatch(action({ scope: SEARCH, type: SEARCH_INDEX_REBUILD_COMPLETED }));
            })
            .catch(console.log);
    }
}