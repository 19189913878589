import React, {Component} from "react";
import PropTypes from "prop-types";

export default class PropertyTile extends Component {

    static propTypes = {
        properties: PropTypes.array.isRequired,
        onPropertySelected: PropTypes.func.isRequired
    };

    state = {
        index: 0
    };

    renderImage = (property) => {
        return (
            <img src={`/api/images/${property.fields.imageCollectionId}/cover?image-set=GALLERY`} onClick={() => this.props.onPropertySelected(property)}/>
        );
    };

    renderPlaceholder = (property) => {
        return (
            <div className="property-tile-image-placeholder" onClick={() => this.props.onPropertySelected(property)}>
                <i className="glyphicon glyphicon-picture"/>
            </div>
        );
    };

    onPrevProperty = () => {
        this.setState({index: this.state.index - 1});
    };

    onNextProperty = () => {
        this.setState({index: this.state.index + 1});
    };

    render() {

        const { properties } = this.props;

        const { index } = this.state;

        const length = properties.length;
        const showLeftArrow = index > 0;
        const showRightArrow = index < length - 1;

        const property = properties[index];

        const { city, streetAndNumber, indoorLocation, seId } = property.fields;

        let subFooter = indoorLocation;
        if (subFooter && subFooter.length > 0) {
            subFooter += ", ";
        }
        subFooter += seId;

        return (
            <li className="property-tile-panel">

                <div className="property-tile-id">
                    {property.id}
                </div>

                <div className="property-tile-image">
                    {property.fields.imageCollectionId > 0 ? this.renderImage(property) : this.renderPlaceholder(property)}
                </div>

                <div className="property-tile-footer">
                    <div>
                        <div className="cover-footer-address">
                            {city}, {streetAndNumber} {length > 1 && (`- ${index + 1}/${length}`)}
                            <div style={{fontSize: "8px"}}>{subFooter || 'Keine Angabe'}</div>
                        </div>
                    </div>
                </div>

                {showLeftArrow &&
                    <div className="property-tile-arrow property-tile-arrow-left" onClick={this.onPrevProperty}>
                        <i className="halflings halflings-menu-left"/>
                    </div>
                }
                {showRightArrow &&
                    <div className="property-tile-arrow property-tile-arrow-right" onClick={this.onNextProperty}>
                        <i className="halflings halflings-menu-right"/>
                    </div>
                }
            </li>
        );

    }

}