import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import InfiniteScroll from 'react-infinite-scroller';
import LedgerEntryList from "../components/LedgerEntryList";
import withUser from "../../user/components/withUser";
import {fetchUnaccountedLedgerEntries} from "../actions";
import PropTypes from "prop-types";
import {noOp} from "../../common/utils";
import {withRouter} from "react-router";

class UnaccountedLedgerEntryContainer extends Component {

    state = {
        unaccountedLedgerEntries: {
            entriesByDate: [],
            loadedSize: 0,
            totalSize: 0,
            debitAmount: 0,
            creditAmount: 0,
        },
        version: 0
    };

    componentDidMount() {
        this.props.fetchUnaccountedLedgerEntries();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.unaccountedLedgerEntries !== this.props.unaccountedLedgerEntries) {
            this.setState({unaccountedLedgerEntries: this.props.unaccountedLedgerEntries});
        }
        if (!prevProps.unaccountedLedgerEntriesDirty && this.props.unaccountedLedgerEntriesDirty) {
            this.props.fetchUnaccountedLedgerEntries();
        }
    }

    onEntrySelected = (entry) => {
        this.props.history.push(`/accounting/unaccounted-entries/${entry.transactionId}`);
    };

    render() {

        const { unaccountedLedgerEntries, version } = this.state;
        
        const { entriesByDate, debitAmount, creditAmount } = unaccountedLedgerEntries;

        const account = {routingKey: 'CASH'};

        return (
            <InfiniteScroll
                key={`bt-scroller-${version}`}
                pageStart={0}
                hasMore={false}
                loadMore={noOp}
                loader={<div className="loader" key={0}>Loading ...</div>}>
                <LedgerEntryList account={account}
                                 entriesByDate={entriesByDate}
                                 debitAmount={debitAmount}
                                 creditAmount={creditAmount}
                                 onEntrySelected={this.onEntrySelected}/>
            </InfiniteScroll>
        );
    }
}

const mapStateToProps = state => ({
    unaccountedLedgerEntries: state.accountingReducer.unaccountedLedgerEntries,
    unaccountedLedgerEntriesDirty: state.accountingReducer.unaccountedLedgerEntriesDirty,
});

const mapDispatchToProps = {
    fetchUnaccountedLedgerEntries: fetchUnaccountedLedgerEntries,
};

export default withRouter(withUser(connect(mapStateToProps, mapDispatchToProps)(UnaccountedLedgerEntryContainer)));
