import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import SearchResults from "../components/SearchResults";

const ContextMenu = ({searching, latency, count}) => (
    <div className="context-menu hidden-print">
        {searching ? (
            <ol className="breadcrumb display-inline" ng-if="busy">
                <li>Suche läuft...</li>
            </ol>
        ) : (
            <ol className="breadcrumb display-inline" ng-if="!busy">
                <li>
                    {count === 1 ? "1 Ergebnis" : `${count} Ergebnisse`} ({latency / 1000} Sekunden)
                </li>
            </ol>
        )}
    </div>
);

class SearchContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            results: this.props.results,
            latency: this.props.latency,
            searching: this.props.searching
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.results !== this.props.results) {
            this.setState({
                results: this.props.results,
                latency: this.props.latency,
                searching: this.props.searching
            });
        }
    }

    render() {
        const { results, latency, searching } = this.state;
        return (
            <div>
                <ContextMenu searching={searching}
                             latency={latency}
                             count={results.length}/>
                <SearchResults results={results}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    results: state.searchReducer.results,
    latency: state.searchReducer.latency,
    searching: state.searchReducer.searching,
});

export default connect(mapStateToProps, {})(SearchContainer);

