import {CURRENT_USER_RECEIVED, CURRENT_USER_REMOVED, USER, USER_UPDATED, USERS_RECEIVED} from "./actions";

const initialState = {
    currentUser: null,
    users: [],
    usersDirty: false,
};

function userReducer(state = initialState, action) {

    if (action.scope !== USER) {
        return state;
    }

    switch (action.type) {

        case CURRENT_USER_RECEIVED:
            return Object.assign({}, state, {
                currentUser: action.user
            });

        case CURRENT_USER_REMOVED:
            return Object.assign({}, state, {
                currentUser: null
            });

        case USERS_RECEIVED:
            return Object.assign({}, state, {
                users: action.users,
                usersDirty: false
            });

        case USER_UPDATED:
            return Object.assign({}, state, {
                usersDirty: true
            });

        default:
            return state;
    }
}

export default userReducer;
