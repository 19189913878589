import * as API from "../common/api";
import {action} from "../common/utils";

export const REPORTING = "REPORTING";

export const REPORT_RECEIVED = 'REPORT_RECEIVED';

export function fetchReport(name) {
    return function(dispatch) {
        API.fetchReport(name)
            .then(response => dispatch(action({ scope: REPORTING, type: REPORT_RECEIVED, rows: response.data })));
    }
}

export function exportReport(name, accessToken) {
    return function(dispatch) {
        API.exportReport(name, accessToken)
            .then(response => window.open(response.data, '_blank'))
    }
}
