import React, {Component} from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";

export default class ImageUploadModal extends Component {

    static propTypes = {
        collection: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
        onUploadImage: PropTypes.func.isRequired
    };

    mounted = false;

    state = {
        error: false,
        errorMessage: '',
        currentFileName: '',
        showProgress: false,
        progress: 0
    };

    onUploadImages = (files) => {
        files.forEach(file => {
            this.setState({
                currentFileName: file.name,
                showProgress: true,
                progress: 0
            }, () => this.props.onUploadImage(this.props.collection, file, this.onUploadProgress, this.onSuccess, this.onError));
        });
    };

    onClose = (event) => {
        event.preventDefault();
        this.props.onClose()
    };

    onUploadProgress = (event) => {
        this.setState({progress: (event.loaded / event.total) * 100});
    };

    onSuccess = () => {
        setTimeout(this.props.onClose, 250);
    };

    onError = (error) => {
        if (this.mounted) {
            this.setState({
                error: true,
                errorMessage: error.message
            })
        }
    };

    clearError = () => {
        if (this.mounted) {
            this.setState({
                error: false,
                errorMessage: ''
            });
        }
    };

    onOpenFileSelector = (event, dropzoneRef) => {
        event.preventDefault();
        event.stopPropagation();
        dropzoneRef.open();
    };

    render() {

        const { error, errorMessage, currentFileName, showProgress, progress } = this.state;

        let dropzoneRef;

        return (

            <div className="modal show fade-in">
                <div className={`modal-dialog modal-md`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={this.onClose}>&times;</button>
                            <h4 className="modal-title">Bilder hinzufügen</h4>
                        </div>
                        <div className={`modal-body file-upload-modal-body`}>
                            {error &&
                                <div className="alert alert-danger alert-dismissable">
                                    <button type="button" className="close" data-dismiss="alert" onClick={this.clearError}>&times;</button>
                                    Oops: {errorMessage}
                                </div>
                            }
                            <Dropzone onDrop={this.onUploadImages}
                                      noClick={true}
                                      ref={node => dropzoneRef = node}>
                                {({getRootProps, getInputProps}) => (

                                    <div className="file-upload-container" {...getRootProps()}>
                                        <input type="file" {...getInputProps()}/>
                                        <div className="file-upload-content">
                                            <div className="text-center text-muted">
                                                <i className="glyphicon glyphicon-picture" style={{fontSize: "100px"}}/>
                                            </div>
                                            <h1 className="text-center text-muted">Bilder hierher ziehen</h1>
                                            <h3 className="text-center text-muted">- oder -</h3>
                                            <div style={{paddingTop: "15px", marginBottom: "30px", textAlign: "center"}}>
                                                <button type="button" className="btn btn-primary" onClick={e => this.onOpenFileSelector(e, dropzoneRef)}>Dateibrowser öffnen</button>
                                            </div>

                                            {showProgress &&
                                                <div className="progress" style={{margin: "auto", width: "70"}}>
                                                    <div className="progress-bar" role="progressbar" style={{ width : `${progress}%`}}>
                                                        {currentFileName + ' - ' + progress + '%'}
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" onClick={this.onClose}>Schliessen</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
