import * as API from "../common/api";
import {action} from "../common/utils";

export const PROPERTY = "PROPERTY";

export const PROPERTY_RECEIVED = 'PROPERTY_RECEIVED';
export const PROPERTY_FIELD_UPDATED = 'PROPERTY_FIELD_UPDATED';
export const PROPERTY_UPDATED = 'PROPERTY_UPDATED';

export const LIKES_RECEIVED = 'LIKES_RECEIVED';
export const LIKE_UPDATED = 'LIKE_UPDATED';

export const ACTIVITIES_RECEIVED = 'ACTIVITIES_RECEIVED';

export const COMMENT_UPDATED = 'ACTIVITIES_UPDATED';

export const ATTACHMENTS_RECEIVED = 'ATTACHMENTS_RECEIVED';
export const ATTACHMENT_UPDATED = 'ATTACHMENT_UPDATED';

export const RENTAL_AGREEMENTS_RECEIVED = 'RENTAL_AGREEMENTS_RECEIVED';
export const RENTAL_AGREEMENT_UPDATED = 'RENTAL_AGREEMENT_UPDATED';

export const IMAGE_COLLECTIONS_RECEIVED = 'IMAGE_COLLECTIONS_RECEIVED';
export const IMAGE_DESCRIPTORS_RECEIVED = 'IMAGE_DESCRIPTORS_RECEIVED';

export function fetchProperty(propertyId) {
    return function(dispatch) {
        API.fetchProperty(propertyId)
            .then(response => dispatch(action({ scope: PROPERTY, type: PROPERTY_RECEIVED, property: response.data })));
    }
}

export function updatePropertyField(field, value) {
    return function(dispatch) {
        dispatch(action({ scope: PROPERTY, type: PROPERTY_FIELD_UPDATED, field: field, value: value }));
    }
}

export function updatePropertyFields(propertyId, fields) {
    return function(dispatch) {
        API.updatePropertyFields(propertyId, fields)
            .then(() => dispatch(action({ scope: PROPERTY, type: PROPERTY_UPDATED })));
    }
}

export function copyProperty(propertyId, onSuccess) {
    return function(dispatch) {
        API.copyProperty(propertyId)
            .then(response => onSuccess(response.data));
    }
}

export function fetchLikes(propertyId) {
    return function(dispatch) {
        API.fetchLikes(propertyId)
            .then(response => dispatch(action({ scope: PROPERTY, type: LIKES_RECEIVED, likes: response.data })));
    }
}

export function updateLike(propertyId, value) {
    return function(dispatch) {
        API.updateLike(propertyId, value)
            .then(() => dispatch(action({ scope: PROPERTY, type: LIKE_UPDATED })));
    }
}

export function fetchActivities(propertyId) {
    return function(dispatch) {
        API.fetchActivitiesByPropertyId(propertyId)
            .then(response => dispatch(action({ scope: PROPERTY, type: ACTIVITIES_RECEIVED, activities: response.data.rows })));
    }
}

export function createComment(propertyId, comment) {
    return function(dispatch) {
        API.createComment(propertyId, comment)
            .then(() => dispatch(action({ scope: PROPERTY, type: COMMENT_UPDATED })));
    }
}

export function updateComment(id, comment) {
    return function(dispatch) {
        API.updateComment(id, comment)
            .then(() => dispatch(action({ scope: PROPERTY, type: COMMENT_UPDATED })));
    }
}

export function deleteComment(id) {
    return function(dispatch) {
        API.deleteComment(id)
            .then(() => dispatch(action({ scope: PROPERTY, type: COMMENT_UPDATED })));
    }
}

export function fetchAttachments(propertyId) {
    return function(dispatch) {
        API.fetchAttachments(propertyId)
            .then(response => dispatch(action({ scope: PROPERTY, type: ATTACHMENTS_RECEIVED, attachments: response.data })));
    }
}

export function uploadAttachment(propertyId, file, onUploadProgress) {
    return function(dispatch) {
        API.uploadAttachment(propertyId, file, onUploadProgress)
            .then(() => dispatch(action({ scope: PROPERTY, type: ATTACHMENT_UPDATED })));
    }
}

export function updateAttachment(attachment, onSuccess, onError) {
    return function(dispatch) {
        API.updateAttachment(attachment)
            .then(() => {
                dispatch(action({ scope: PROPERTY, type: ATTACHMENT_UPDATED }));
                onSuccess();
            })
            .catch(onError);
    }
}

export function deleteAttachment(id, onSuccess, onError) {
    return function(dispatch) {
        API.deleteAttachment(id)
            .then(() => {
                dispatch(action({ scope: PROPERTY, type: ATTACHMENT_UPDATED }));
                onSuccess();
            })
            .catch(onError);
    }
}

export function fetchRentalAgreements(propertyId) {
    return function(dispatch) {
        API.fetchRentalAgreements(propertyId)
            .then(response => dispatch(action({ scope: PROPERTY, type: RENTAL_AGREEMENTS_RECEIVED, rentalAgreements: response.data })));
    }
}

export function createRentalAgreement(rentalAgreement, onSuccess, onError) {
    return function(dispatch) {
        API.createRentalAgreement(rentalAgreement)
            .then(() => {
                dispatch(action({ scope: PROPERTY, type: RENTAL_AGREEMENT_UPDATED }));
                onSuccess();
            })
            .catch(onError);
    }
}

export function updateRentalAgreement(rentalAgreement, onSuccess, onError) {
    return function(dispatch) {
        API.updateRentalAgreement(rentalAgreement)
            .then(() => {
                dispatch(action({ scope: PROPERTY, type: RENTAL_AGREEMENT_UPDATED }));
                onSuccess();
            })
            .catch(onError);
    }
}

export function deleteRentalAgreement(id, onSuccess, onError) {
    return function(dispatch) {
        API.deleteRentalAgreement(id)
            .then(() => {
                dispatch(action({ scope: PROPERTY, type: RENTAL_AGREEMENT_UPDATED }));
                onSuccess();
            })
            .catch(onError);
    }
}

export function fetchImageCollections(propertyId) {
    return function(dispatch) {
        API.fetchImageCollections(propertyId)
            .then(response => dispatch(action({ scope: PROPERTY, type: IMAGE_COLLECTIONS_RECEIVED, collections: response.data })));
    }
}

export function fetchImageDescriptors(propertyId) {
    return function(dispatch) {
        API.fetchImageDescriptors(propertyId)
            .then(response => dispatch(action({ scope: PROPERTY, type: IMAGE_DESCRIPTORS_RECEIVED, imageDescriptors: response.data })));
    }
}