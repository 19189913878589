import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {formatAmount, formatDateAsIso} from "../../common/utils";
import withUser from "../../user/components/withUser";
import {fetchBalances, fetchFlows} from "../actions";
import {withRouter} from "react-router";
import FlowAndDateRangeSelector from "../components/FlowAndDateRangeSelector";

class BalanceContainer extends Component {

    state = {
        balances: []
    };

    componentDidMount() {
        this.props.fetchFlows();
        this.fetchBalances();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.flowsByType !== this.props.flowsByType) {
            this.setState({flowsByType: this.props.flowsByType});
        }
        if (prevProps.balances !== this.props.balances) {
            this.setState({balances: this.props.balances});
        }
    }

    fetchBalances = (filter = {}) => {
        const { flowType, flowId, subFlowType, subFlowId, fromDate, toDate } = filter;
        this.props.fetchBalances(
            flowId ? flowType : null,
            flowId,
            subFlowId ? subFlowType : null,
            subFlowId,
            fromDate ? formatDateAsIso(fromDate) : null,
            toDate ? formatDateAsIso(toDate) : null
        );
    };

    renderRow = (balance) => {




        return (
            <tr key={`balance-${balance.accountId}`}>
                <td className="text-left">{balance.accountName}</td>

                {balance.asset &&
                    <td className="text-right text-monospace">{formatAmount(balance.creditAmount)}</td>
                }
                {balance.asset &&
                     <td className="text-right text-monospace text-red">{formatAmount(balance.debitAmount)}</td>
                }
                {balance.asset && balance.balance >= 0 &&
                    <td className="text-right text-monospace">{formatAmount(balance.balance)}</td>
                }
                {balance.asset && balance.balance < 0 &&
                    <td className="text-right text-monospace text-red">{formatAmount(balance.balance)}</td>
                }

                {!balance.asset &&
                    <td className="text-right text-monospace text-red">{formatAmount(balance.creditAmount)}</td>
                }
                {!balance.asset &&
                    <td className="text-right text-monospace">{formatAmount(balance.debitAmount)}</td>
                }
                {!balance.asset && balance.balance <= 0 &&
                    <td className="text-right text-monospace">{formatAmount(balance.balance)}</td>
                }
                {!balance.asset && balance.balance > 0 &&
                    <td className="text-right text-monospace text-red">{formatAmount(balance.balance)}</td>
                }
            </tr>
        );
    };

    render() {

        const { flowsByType, balances } = this.state;

        if (!flowsByType) {
            return null;
        }

        return (

            <div>
                <FlowAndDateRangeSelector flowsByType={flowsByType} onFilterChanged={this.fetchBalances}/>

                <div className="row">


                    <div className="col-md-6">
                        {balances && balances.length > 0 &&
                        <table className="table table-hover" style={{margin: "0 10px 5px 10px"}}>
                            <thead>
                            <tr>
                                <th className="text-left">Konto</th>
                                <th className="text-right">Haben</th>
                                <th className="text-right">Soll</th>
                                <th className="text-right">Bilanz</th>
                            </tr>
                            </thead>
                            <tbody>
                            {balances.map(this.renderRow)}
                            </tbody>
                        </table>
                        }
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    flowsByType: state.accountingReducer.flowsByType,
    balances: state.accountingReducer.balances,
});

const mapDispatchToProps = {
    fetchFlows: fetchFlows,
    fetchBalances: fetchBalances,
};

export default withRouter(withUser(connect(mapStateToProps, mapDispatchToProps)(BalanceContainer)));

