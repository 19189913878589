import React, {Component} from "react";
import BankAccountContainer from "../../accounting/containers/BankAccountContainer";
import {Tab, Tabs} from "react-bootstrap";
import SearchSettingsContainer from "../../search/containers/SearchSettingsContainer";
import AccountContainer from "../../accounting/containers/AccountContainer";
import PropertyGroupContainer from "../../property-group/containers/PropertyGroupContainer";
import UserContainer from "../../user/containers/UserContainer";
import LoanContainer from "../../loan/containers/LoanContainer";

const ContextMenu = () => (
    <div className="context-menu hidden-print">
        <ol className="breadcrumb display-inline">
            <li>Einstellungen</li>
        </ol>
    </div>
);

export default class SettingsContainer extends Component {

    render() {
        return (
            <div>
                <ContextMenu/>
                <Tabs defaultActiveKey="users" id="uncontrolled-tab-example">
                    <Tab eventKey="users" title="Benutzer">
                        <UserContainer/>
                    </Tab>
                    <Tab eventKey="propertyGroups" title="Objektordner">
                        <PropertyGroupContainer/>
                    </Tab>
                    <Tab eventKey="bankAccounts" title="Bankkonten">
                        <BankAccountContainer/>
                    </Tab>
                    <Tab eventKey="accounts" title="Buchungskonten">
                        <AccountContainer/>
                    </Tab>
                    <Tab eventKey="loans" title="Darlehen">
                        <LoanContainer/>
                    </Tab>
                    <Tab eventKey="search" title="Suche">
                        <SearchSettingsContainer/>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}


