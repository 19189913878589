import React, {Component} from "react";
import PropTypes from "prop-types";
import withUser from "../../user/components/withUser";
import NavLink from "../../common/components/NavLink";

class ContextMenu extends Component {

    static propTypes = {
        property: PropTypes.object.isRequired,
        propertyGroup: PropTypes.object.isRequired,
        dirty: PropTypes.bool.isRequired,
        onSaveUpdates: PropTypes.func.isRequired,
        onCancelUpdates: PropTypes.func.isRequired,
        onCopy: PropTypes.func.isRequired,
        onExportOpenImmo: PropTypes.func.isRequired
    };

    onSaveUpdates = (event) => {
        event.preventDefault();
        this.props.onSaveUpdates();
    };

    onCancelUpdates = (event) => {
        event.preventDefault();
        this.props.onCancelUpdates();
    };

    onCopy = (event) => {
        event.preventDefault();
        this.props.onCopy();
    };

    onExportOpenImmo = (event) => {
        event.preventDefault();
        this.props.onExportOpenImmo();
    };

    render() {
        const { property, propertyGroup, permissions, dirty } = this.props;

        return (
            <div className="context-menu hidden-print">
                <ol className="breadcrumb display-inline">
                    <li><NavLink path={`/property-groups`}>Objektgruppen</NavLink></li>
                    <li><NavLink path={`/property-groups/${propertyGroup.id}`}>{propertyGroup.name}</NavLink></li>
                    <li>#{property.id}</li>
                </ol>
                <ul className="nav nav-pills pull-right">
                    {permissions.editor && dirty &&
                    <li>
                        <a onClick={this.onSaveUpdates}><i className="glyphicon glyphicon-ok"/><label>Speichern</label></a>
                    </li>
                    }
                    {permissions.editor && dirty &&
                    <li>
                        <a onClick={this.onCancelUpdates}><i className="glyphicon glyphicon-remove"/><label>Verwerfen</label></a>
                    </li>
                    }
                    {permissions.editor &&
                    <li>
                        <a onClick={this.onCopy}><i className="halflings halflings-duplicate"/><label>Kopieren</label></a>
                    </li>
                    }
                    <li>
                        <a href={`https://maps.google.de/maps?q=${property.fields.latitude},${property.fields.longitude}`} target="_blank" rel="noopener noreferrer">
                            <i className="glyphicon glyphicon-map-marker"/><label>Karte</label>
                        </a>
                    </li>

                </ul>
            </div>
        );
    }
}

export default withUser(ContextMenu)
