import * as API from "../common/api";
import { action } from "../common/utils";

export const LOAN = "LOAN";

export const LOANS_RECEIVED = 'LOANS_RECEIVED';
export const LOAN_UPDATED = 'LOAN_UPDATED';

export function fetchLoans() {
    return function(dispatch) {
        API.fetchLoans()
            .then(response => dispatch(action({ scope: LOAN, type: LOANS_RECEIVED, loans: response.data })));
    }
}

export function createLoan(loan) {
    return function(dispatch) {
        API.createLoan(loan)
            .then(() => {
                dispatch(action({ scope: LOAN, type: LOAN_UPDATED }));
            })
            .catch(console.log);
    }
}

export function updateLoan(loan) {
    return function(dispatch) {
        API.updateLoan(loan)
            .then(() => {
                dispatch(action({ scope: LOAN, type: LOAN_UPDATED }));
            })
            .catch(console.log);
    }
}

export function deleteLoan(loan) {
    return function(dispatch) {
        API.deleteLoan(loan.id)
            .then(() => {
                dispatch(action({ scope: LOAN, type: LOAN_UPDATED }));
            })
            .catch(console.log);
    }
}
