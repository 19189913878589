import React, {Component} from "react";
import 'bootstrap-social/bootstrap-social.css'
import PropTypes from "prop-types";
import moment from "moment";
import {DateRangePicker} from "rsuite";

export default class TransactionDateRangePicker extends Component {

    static propTypes = {
        value: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    dateRanges = () => {
        const now = new Date();
        return [
            {
                label: '30 Tage',
                value: [moment(now).add(-30, 'days').toDate(), now]
            }, {
                label: '60 Tage',
                value: [moment(now).add(-60, 'days').toDate(), now]
            }, {
                label: '90 Tage',
                value: [moment(now).add(-90, 'days').toDate(), now]
            }, {
                label: 'Aktuelles Jahr',
                value: [moment([now.getFullYear(), 0, 1]).toDate(), now]
            }, {
                label: 'Heute',
                value: [now, now]
            }
        ]
    };

    render() {
        return (
            <DateRangePicker ranges={this.dateRanges()}
                             value={this.props.value}
                             onChange={this.props.onChange}/>
        );
    }
}

