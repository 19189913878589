import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {fetchActivities} from "../actions";
import InfiniteScroll from 'react-infinite-scroller';
import ActivityList from "../components/ActivityList";
import PropertyChangesModal from "../../common/components/PropertyChangesModal";

const ContextMenu = ({loadedSize, totalSize}) => (
    <div className="context-menu hidden-print">
        <ol className="breadcrumb display-inline">
            <li>Aktivitäten ({loadedSize} / {totalSize})</li>
        </ol>
    </div>
);

class ActivityContainer extends Component {

    state = {
        activitiesByDate: [],
        loadedSize: 0,
        totalSize: 0,
        showChangesModal: false,
        selectedActivity: null
    };

    componentDidMount() {
        this.onNextPage(0);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activitiesByDate !== this.props.activitiesByDate) {
            this.setState({
                activitiesByDate: this.props.activitiesByDate,
                loadedSize: this.props.loadedSize,
                totalSize: this.props.totalSize,
            });
        }
    }

    onNextPage = (pageIndex) => {
        const { totalSize } = this.state;
        const pageSize = 100;
        if (pageIndex === 0 || (pageIndex * pageSize) < totalSize) {
            this.props.fetchActivities(pageIndex, pageSize);
        }
    };

    onShowChangesModal = (activity) => {
        this.setState({
            showChangesModal: true,
            selectedActivity: activity
        })
    };

    onCloseChangesModal = () => {
        this.setState({
            showChangesModal: false,
            selectedActivity: null
        })
    };

    render() {

        const { activitiesByDate, loadedSize, totalSize, showChangesModal, selectedActivity } = this.state;

        return (
            <div>
                <ContextMenu loadedSize={loadedSize}
                             totalSize={totalSize}/>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.onNextPage}
                    hasMore={loadedSize < totalSize}
                    loader={<div className="loader" key={0}>Loading ...</div>}>
                    <ActivityList activitiesByDate={activitiesByDate} onShowChanges={this.onShowChangesModal}/>
                </InfiniteScroll>

                {showChangesModal && selectedActivity &&
                    <PropertyChangesModal activity={selectedActivity}
                                          onClose={this.onCloseChangesModal}/>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    activitiesByDate: state.activityReducer.activitiesByDate,
    loadedSize: state.activityReducer.loadedSize,
    totalSize: state.activityReducer.totalSize,
});

const mapDispatchToProps = {
    fetchActivities: fetchActivities
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityContainer);

