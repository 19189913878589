import {connect} from 'react-redux';
import React, {Component, Fragment} from "react";
import {Redirect, Route, Switch, withRouter} from "react-router";
import NavBar from "./modules/shell/components/NavBar";
import ErrorBoundary from "react-error-boundary";
import Error from "./modules/shell/components/Error";
import PropertyContainer from "./modules/property/containers/PropertyContainer";
import PropertyListContainer from "./modules/property-group/containers/PropertyListContainer";
import {search} from "./modules/search/actions";
import withUser from "./modules/user/components/withUser";
import ImageContainer from "./modules/image/containers/ImageContainer";
import SearchContainer from "./modules/search/containers/SearchContainer";
import ActivityContainer from "./modules/activity/containers/ActivityContainer";
import SettingsContainer from "./modules/settings/containers/SettingsContainer";
import {logout} from "./modules/user/actions";
import PropertyGroupListContainer from "./modules/property-group/containers/PropertyGroupListContainer";
import ReportContainer from "./modules/reporting/containers/ReportContainer";
import AccountingContainer from "./modules/accounting/containers/AccountingContainer";
import TransactionContainer from "./modules/accounting/containers/TransactionContainer";

class RescoreApp extends Component {

    onSearch = (phrase) => {
        this.props.search(phrase, () => {
            this.props.history.push(`/search`)
        });
    };

    onLogout = () => {
        window.location.href = '/';
        window.auth2.signOut();
    };

    render() {

        const { permissions } = this.props;

        return (

            <Fragment>

                <NavBar onCreateProperty={this.onShowConfirmCreatePropertyModal}
                        onSearch={this.onSearch}
                        onLogout={this.onLogout}/>

                <ErrorBoundary FallbackComponent={Error}>

                    <div className="content-container">
                    <Switch>

                        <Route exact path="/"
                               render={() => <Redirect to="/property-groups"/>}/>

                        <Route exact path="/property-groups"
                               render={(props) => <PropertyGroupListContainer groupId={parseInt(props.match.params.groupId)}/>}/>

                        <Route path="/property-groups/:groupId"
                               render={(props) => <PropertyListContainer groupId={parseInt(props.match.params.groupId)}/>}/>

                        <Route exact path="/images"
                               render={() => <ImageContainer/>}/>

                        <Route path="/images/:collectionId"
                               render={(props) => <ImageContainer collectionId={parseInt(props.match.params.collectionId)} />}/>

                        <Route exact path="/search"
                               render={() => <SearchContainer/>}/>

                        <Route exact path="/activity"
                               render={() => <ActivityContainer />}/>

                        <Route exact path="/accounting"
                        render={() => <Redirect to="/accounting/unaccounted-entries"/>}/>

                        {permissions && permissions.accountant &&
                        <Route exact path="/accounting/:tab"
                               render={(props) => <AccountingContainer tab={props.match.params.tab}/>}/>
                        }

                        {permissions && permissions.accountant &&
                        <Route exact path="/accounting/unaccounted-entries/:transactionId"
                               render={(props) => <TransactionContainer transactionId={props.match.params.transactionId} parent={"unaccounted-entries"} edit={true}/>}/>
                        }

                        {permissions && permissions.accountant &&
                        <Route exact path="/accounting/ledger-entries/:transactionId"
                               render={(props) => <TransactionContainer transactionId={props.match.params.transactionId} parent={"ledger-entries"} edit={false}/>}/>
                        }

                        <Route exact path="/reports"
                               render={() => <Redirect to="/reports/annuals"/>}/>

                        <Route exact path="/reports/:name"
                               render={(props) => <ReportContainer name={props.match.params.name}/>}/>

                        {permissions && permissions.admin &&
                        <Route exact path="/settings"
                               render={() => <SettingsContainer/>}/>
                        }

                        <Route path="/properties/:propertyId"
                               render={(props) => <PropertyContainer propertyId={parseInt(props.match.params.propertyId)}/>}/>

                    </Switch>

                    </div>
                </ErrorBoundary>

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {
    search: search,
    logout: logout
};

export default withRouter(withUser(connect(mapStateToProps, mapDispatchToProps)(RescoreApp)));
