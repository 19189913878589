import React, {Component} from 'react';
import withUser from "../../user/components/withUser";
import PropTypes from "prop-types";
import NavLink from "../../common/components/NavLink";

const NavItem = props => (
    <li>
        <NavLink path={props.path} children={props.children}/>
    </li>
);

const NavDropdown = props => (
    <li className="dropdown">
        <a className="dropdown-toggle" data-toggle="dropdown">{props.name}<span className="caret"></span></a>
        <ul className="dropdown-menu" role="menu">
            {props.children}
        </ul>
    </li>
);

class NavBar extends Component {

    static propTypes = {
        onSearch: PropTypes.func.isRequired,
        onLogout: PropTypes.func.isRequired
    };

    state = {
        searchPhrase: '',
    };

    updateSearchPhrase = (event) => {
        this.setState({ searchPhrase: event.target.value });
    };

    onSearch = (event) => {
        event.preventDefault();
        this.props.onSearch(this.state.searchPhrase);
    };

    render() {

        const { permissions, currentUser } = this.props;

        const { searchPhrase } = this.state;

        return (
            <nav className="navbar navbar-default navbar-fixed-top">

                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span className="icon-bar"/>
                        <span className="icon-bar"/>
                        <span className="icon-bar"/>
                    </button>
                    <a className="navbar-brand" href="/">rescore</a>
                </div>

                <div className="navbar-collapse collapse navbar-right">

                    <form className="navbar-form navbar-left" role="search" style={{border: "none"}}>
                        <div className="input-group">
                            <input type="text"
                                   className="form-control"
                                   placeholder="Suche"
                                   value={searchPhrase}
                                   onChange={this.updateSearchPhrase}/>
                            <span className="input-group-btn">
                                <button type="submit" className="btn btn-default" onClick={this.onSearch}>
                                    <span className="glyphicon glyphicon-search"/>
                                </button>
                            </span>
                        </div>
                    </form>

                    <ul className="nav navbar-nav">

                        <NavItem path="/property-groups">Objekte</NavItem>
                        <NavItem path="/reports">Berichte</NavItem>
                        <NavItem path="/images">Bilder</NavItem>
                        <NavItem path="/activity">Aktivitäten</NavItem>
                        {permissions.accountant &&
                        <NavItem path="/accounting/unaccounted-entries">Buchhaltung</NavItem>
                        }
                        {permissions.admin &&
                        <NavItem path="/settings">Einstellungen</NavItem>
                        }
                        {currentUser &&
                        <li className="profile-picture">
                            <img className="img-circle img-profile" src={currentUser.imageUrl} alt={`${currentUser.firstName} ${currentUser.lastName}`} />
                            <i className="glyphicon glyphicon-log-out" style={{lineHeight: "1.1", fontSize: "18px"}}  onClick={this.props.onLogout}/>
                        </li>
                        }

                    </ul>

                </div>
            </nav>
        );
    }
}

export default withUser(NavBar, true);
