import React, {Component} from "react";
import 'bootstrap-social/bootstrap-social.css'

export default class LoginForm extends Component {

    render() {
        return (
            <div className="login-form">
                <h3 className="text-center">Rescore</h3>
                <button id="loginButton" className="btn btn-block btn-social btn-google">
                    <i className="fa fa-google"/><span style={{paddingLeft: "30px"}}>Anmelden mit Google</span>
                </button>
            </div>
        );
    }
}

