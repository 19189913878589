import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {formatDate} from "../../common/utils";
import NavLink from "../../common/components/NavLink";

import {withTranslation} from 'react-i18next';

class ActivityList extends Component {

    static propTypes = {
        activitiesByDate: PropTypes.array.isRequired,
        onShowChanges: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            activitiesByDate: props.activitiesByDate,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activitiesByDate !== this.props.activitiesByDate) {
            this.setState({ activitiesByDate: this.props.activitiesByDate });
        }
    }

    onShowChangesModal = (event, selectedActivity) => {
        event.preventDefault();
        this.props.onShowChanges(selectedActivity);
    };

    renderPropertyCreated = (activity) => {
        const propId = activity.propertyId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat <NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink> erstellt</span>
            </Fragment>
        );
    };

    renderPropertyUpdated = (activity) => {
        const propId = activity.propertyId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat <NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink> bearbeitet</span>
                { this.renderChanges(activity) }
            </Fragment>
        );
    };

    renderPropertyDeleted = (activity) => {
        const propId = activity.propertyId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat <NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink> gelöscht</span>
            </Fragment>
        );
    };

    renderRentalAgreementCreated = (activity) => {
        const propId = activity.propertyId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat Mietvertrag #{ activity.rentalAgreementId } ({ activity.text }) angelegt (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>)</span>
            </Fragment>
        );
    };

    renderRentalAgreementUpdated = (activity) => {
        const propId = activity.propertyId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat Mietvertrag #{ activity.rentalAgreementId } ({ activity.text }) bearbeitet (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>)</span>
                { this.renderChanges(activity) }
            </Fragment>
        );
    };

    renderRentalAgreementDeleted = (activity) => {
        const propId = activity.propertyId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat Mietvertrag #{ activity.rentalAgreementId } ({ activity.text }) gelöscht (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>)</span>
            </Fragment>
        );
    };

    renderCommentCreated = (activity) => {
        const propId = activity.propertyId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat ein Kommentar hinzugefügt (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>): </span>
                { activity.text }
            </Fragment>
        );
    };

    renderCommentUpdated = (activity) => {
        const propId = activity.propertyId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat ein Kommentar bearbeitet (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>): </span>
                { activity.text }
            </Fragment>
        );
    };

    renderCommentDeleted = (activity) => {
        const propId = activity.propertyId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat ein Kommentar gelöscht (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>): </span>
                { activity.text }
            </Fragment>
        );
    };

    renderLikeUpdated = (activity) => {
        const propId = activity.propertyId;
        const imageClassNames = activity.text === 'true' ? 'glyphicon glyphicon-thumbs-up' : 'glyphicon glyphicon-thumbs-down';
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat über <NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink> abgestimmt: <i className={imageClassNames}/></span>
            </Fragment>
        );
    };

    renderAttachmentCreated = (activity) => {
        const propId = activity.propertyId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat einen Anhang hochgeladen (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>): </span>
                { activity.text }
            </Fragment>
        );
    };

    renderAttachmentUpdated = (activity) => {
        const propId = activity.propertyId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat einen Anhang geändert (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>): </span>
                { activity.text }
                { this.renderChanges(activity) }
            </Fragment>
        );
    };

    renderAttachmentDeleted = (activity) => {
        const propId = activity.propertyId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat einen Anhang gelöscht (<NavLink path={`/properties/${propId}`}>Objekt #{propId}</NavLink>): </span>
                { activity.text }
            </Fragment>
        );
    };

    renderImageCollectionCreated = (activity) => {
        const colId = activity.imageCollectionId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat das Album <NavLink path={`/image?collection=${colId}`}>'{activity.text}'</NavLink> erstellt</span>
            </Fragment>
        );
    };

    renderImageCollectionUpdated = (activity) => {
        const colId = activity.imageCollectionId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat das Album <NavLink path={`/image?collection=${colId}`}>'{activity.text}'</NavLink> bearbeitet</span>
            </Fragment>
        );
    };

    renderImageCollectionDeleted = (activity) => {
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat das Album </span>
                '{activity.text}'
                <span className="text-muted"> gelöscht</span>
            </Fragment>
        );
    };

    renderImageCreated = (activity) => {
        const colId = activity.imageCollectionId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat ein <NavLink path={`/api/images/${colId}/${activity.imageId}?image-set=MASTER`}>Bild</NavLink> hochgeladen (Album <NavLink path={`/image?collection=${colId}`}>'{activity.text}'</NavLink>)</span>
            </Fragment>
        );
    };

    renderImageDeleted = (activity) => {
        const colId = activity.imageCollectionId;
        return (
            <Fragment>
                { activity.userName }
                <span className="text-muted"> hat ein Bild gelöscht (Album <NavLink path={`/image?collection=${colId}`}>'{activity.text}'</NavLink>)</span>
            </Fragment>
        );
    };

    renderChanges(activity) {
        const { t } = this.props;
        return (
            <div>
                <div className="spacer5"/>
                <ul>
                    {activity.changes.length > 0 && activity.changes.map(c =>
                        <li key={activity.id + '-' + c.name}><strong>{t(`changes:${activity.type}.${c.name}`)}</strong></li>
                    )}
                </ul>
                <a href="" onClick={e => this.onShowChangesModal(e, activity)}>Details</a>
            </div>
        );
    };

    types = {
        PROPERTY_CREATED         : { iconClassNames: "glyphicon glyphicon-file",      render: this.renderPropertyCreated },
        PROPERTY_UPDATED         : { iconClassNames: "glyphicon glyphicon-file",      render: this.renderPropertyUpdated },
        PROPERTY_DELETED         : { iconClassNames: "glyphicon glyphicon-file",      render: this.renderPropertyDeleted },
        RENTAL_AGREEMENT_CREATED : { iconClassNames: "glyphicon glyphicon-briefcase", render: this.renderRentalAgreementCreated },
        RENTAL_AGREEMENT_UPDATED : { iconClassNames: "glyphicon glyphicon-briefcase", render: this.renderRentalAgreementUpdated },
        RENTAL_AGREEMENT_DELETED : { iconClassNames: "glyphicon glyphicon-briefcase", render: this.renderRentalAgreementDeleted },
        COMMENT_CREATED          : { iconClassNames: "glyphicon glyphicon-comment",   render: this.renderCommentCreated },
        COMMENT_UPDATED          : { iconClassNames: "glyphicon glyphicon-comment",   render: this.renderCommentUpdated },
        COMMENT_DELETED          : { iconClassNames: "glyphicon glyphicon-comment",   render: this.renderCommentDeleted },
        LIKE_UPDATED             : { iconClassNames: "glyphicon glyphicon-thumbs-up", render: this.renderLikeUpdated },
        ATTACHMENT_CREATED       : { iconClassNames: "glyphicon glyphicon-paperclip", render: this.renderAttachmentCreated },
        ATTACHMENT_UPDATED       : { iconClassNames: "glyphicon glyphicon-paperclip", render: this.renderAttachmentUpdated },
        ATTACHMENT_DELETED       : { iconClassNames: "glyphicon glyphicon-paperclip", render: this.renderAttachmentDeleted },
        IMG_COL_CREATED          : { iconClassNames: "glyphicon glyphicon-picture",   render: this.renderImageCollectionCreated },
        IMG_COL_UPDATED          : { iconClassNames: "glyphicon glyphicon-picture",   render: this.renderImageCollectionUpdated },
        IMG_COL_DELETED          : { iconClassNames: "glyphicon glyphicon-picture",   render: this.renderImageCollectionDeleted },
        IMG_CREATED              : { iconClassNames: "glyphicon glyphicon-picture",   render: this.renderImageCreated },
        IMG_DELETED              : { iconClassNames: "glyphicon glyphicon-picture",   render: this.renderImageDeleted },
    };

    render() {
        const { activitiesByDate } = this.state;
        return (
            <div>
                {activitiesByDate.map((dateAndActivities, idx) => (
                    <table key={`activity-group-${idx}`} className="activity-table table table-hover" style={{marginBottom: "5px", fontSize: "12px"}}>
                        <thead>
                            <tr>
                                <th className="text-left text-primary" style={{width: "50px"}}>{formatDate(parseInt(dateAndActivities[0]), 'DD.MM.YYYY')}</th>
                                <th className="ruler">
                                    <div/>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {dateAndActivities[1].map(activity => (
                            <tr key={`activity-${activity.id}`} style={{fontSize: "12px"}}>
                                <td className="text-left text-nowrap">
                                    <span style={{marginRight: "10px"}}><strong>{formatDate(activity.createTime, 'HH:mm')}</strong></span>
                                    <i className={this.types[activity.type].iconClassNames} style={{marginRight: "10px"}}/>
                                </td>
                                <td className="text-left">
                                    {this.types[activity.type].render(activity)}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ))}

            </div>
        );
    }
}

export default withTranslation('changes')(ActivityList);
